.tabs {
  //overflow-x: scroll;
  //margin: 0 calc(var(--grid-spacer) * -1);

  &::-webkit-scrollbar {
    display: none;
  }

  &__text {
    white-space: nowrap;
    color: var(--grey-text);
  }

  &__list {
    display: flex;
    overflow: scroll hidden;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    &::-webkit-scrollbar {
      display: none;
    }

    section::-webkit-scrollbar {
      width: 0 !important
    }

    overflow: -moz-scrollbars-none; /* Firefox */
  }

  &__item,
  &__text {
    transition: var(--default-transition);
  }

  &__item {
    &:before {
      transition: var(--default-transition);
      opacity: 0;
    }

    &._active {
      &:before {
        opacity: 1;
      }
    }

    &_round {
      border-radius: 43px;
      background: #EBECEE;

      &._active {
        background: var(--primary-black);

        .tabs {
          &__text {
            color: white;
          }
        }
      }
    }

    &_underline {
      position: relative;

      &:before {
        position: absolute;
        content: '';
        bottom: 0;
        border-bottom: 2px solid var(--primary-black);
      }

      &._active {
        .tabs {
          &__text {
            color: var(--primary-black);
          }
        }
      }
    }

    &_white {
      background: white;
      border-right: 1px solid var(--border-color);
      border-bottom: 1px solid var(--border-color);
      position: relative;

      &:before {
        position: absolute;
        content: '';
        top: 0;
        left: 0;
        border-top: 2px solid var(--primary-red);
        width: 100%;
      }

      &._active {
        border-bottom: 1px solid transparent;
        .tabs {
          &__text {
            color: var(--primary-red);
          }
        }
      }

      .tabs {
        &__text {
          font-style: normal;
          font-weight: 600;
          line-height: 140%;
        }
      }
    }

    &_mini {
      border-radius: 19px;
      border: 1px solid var(--border-color);

      &:not(:first-child) {
        margin-left: 8px;
      }

      &._active {
        background: var(--primary-black);
        border-color: var(--primary-black);

        .tabs {
          &__text {
            color: white;
          }
        }
      }

      .tabs {
        &__text {
          font-size: 14px;
        }
      }
    }
  }
}

@include respond-up('s-large') {
  .tabs {
    &__item {
      &_round {
        &:hover {
          background: var(--primary-black);

          .tabs {
            &__text {
              color: white;
            }
          }
        }
      }

      &_underline {
        &:hover {
          &:before {
            opacity: 1;
          }
          .tabs {
            &__text {
              color: var(--primary-black);
            }
          }
        }
      }

      &_white {
        &:hover {
          .tabs {
            &__text {
              color: var(--primary-black);
            }
          }
        }
      }

      &_mini {
        &:hover {
          border-color: var(--primary-black);
        }
      }
    }
  }
}

@include respond-up('medium') {
  .tabs {
    &__list {
      &_underline-item {
        margin: -14px -15px;
      }
    }

    &__item {
      &_round {
        &:not(:first-child) {
          margin-left: 14px;
        }

        .tabs {
          &__link {
            padding: 10px 18px;
          }
        }
      }

      &_underline {
        &:before {
          left: 14px;
          width: calc(100% - 28px);
        }

        .tabs {
          &__link {
            padding: 14px 15px;
          }
        }
      }

      &_white {
        .tabs {
          &__link {
            padding: 16px 32px;
          }

          &__text {
            font-size: 16px;
          }
        }
      }

      &_mini {
        .tabs {
          &__link {
            padding: 6px 14px;
          }

          &__text {
            font-size: 14px;
          }
        }
      }
    }
  }
}

@include respond-down('small') {
  .tabs {
    &__list {
      &_underline-item {
        margin: -12px -10px;
      }
    }

    &__item {
      &_round {
        &:not(:first-child) {
          margin-left: 8px;
        }

        .tabs {
          &__link {
            padding: 9px 14px;
          }
        }
      }

      &_underline {
        &:before {
          left: 12px;
          width: calc(100% - 24px);
        }

        .tabs {
          &__link {
            padding: 12px 10px;
          }
        }
      }

      &_white {
        .tabs {
          &__link {
            padding: 12px 22px;
          }

          &__text {
            font-size: 15px;
          }
        }
      }

      &_mini {
        .tabs {
          &__link {
            padding: 4px 12px;
          }

          &__text {
            font-size: 13px;
          }
        }
      }
    }
  }
}