.index-page {
  &__main-block,
  &__contacts-block {
    background: var(--grey-background-color-opacity-50);
  }

  &__catalog-block,
  &__producers-block {
    background: #EBECEE;
  }

  &__about-block-wrap {
    background: var(--primary-black);
  }
}

@include respond-up('s-large') {
  .index-page {
    &__main-block {
      padding: 40px 0 100px;
    }

    &__catalog-block {
      padding: 80px 0 100px;
    }

    &__contacts-block {
      padding: 60px 0 40px;
    }
  }
}

@include respond-up('medium') {
  .index-page {

  }
}

@include respond('medium') {
  .index-page {
    &__main-block {
      padding: 30px 0 80px;
    }

    &__catalog-block {
      padding: 60px 0 80px;
    }

    &__contacts-block {
      padding: 40px 0 40px;
    }
  }
}

@include respond-down('medium') {
  .index-page {

  }
}

@include respond-down('small') {
  .index-page {
    &__main-block {
      padding: 20px 0 60px;
    }

    &__catalog-block {
      padding: 40px 0 60px;
    }

    &__contacts-block {
      padding: 30px 0 40px;
    }
  }
}