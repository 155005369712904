.index-partners {
  display: flex;
  border-top: 1px solid var(--border-color);

  &__heading-wrap {
    display: flex;
  }

  &__partners-list {
    display: flex;
    flex-direction: column;
    row-gap: var(--grid-gap);
  }

  &__partner {
    background: #fff;
    border-radius: 6px;
  }

  &__link {
    display: flex;
  }

  &__text-wrap {
    display: flex;
    flex-direction: column;
  }

  &__button {
    display: flex;
    align-items: baseline;
  }

  &__button-icon {
    margin-left: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 19px;
    height: 10px;

    svg {
      width: 100%;
      height: 100%;
      path {
        fill: var(--primary-red);
      }
    }
  }

  &__picture {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: 6px;
  }
}

@include respond-up('s-large') {
  .index-partners {
    margin-top: 100px;
    padding-top: 60px;
    column-gap: var(--grid-gap);

    &__heading-wrap {
      flex: 0 0 var(--grid-column3);
      flex-direction: column;
    }

    &__subtitle {
      margin-top: 10px;
      max-width: 300px;
    }

    &__partners-list {
      flex: auto;
    }

    &__link {
      padding: 40px calc(var(--grid-gap) + var(--grid-column)) 40px 40px;
    }

    &__text-wrap {
      width: var(--grid-column6);
    }

    &__picture {
      width: 160px;
      height: 80px;
    }
  }
}

@include respond-up('medium') {
  .index-partners {
    &__link {
      justify-content: space-between;
      align-items: flex-start;
    }

    &__button-wrap {
      margin-top: 20px;
    }
  }
}

@include respond('medium') {
  .index-partners {
    margin-top: 80px;
    padding-top: 54px;

    &__title {
      width: var(--grid-column2);
    }

    &__subtitle {
      padding-top: 6px;
      width: var(--grid-column4);
      margin-left: var(--grid-gap);
    }

    &__partners-list {
      margin-top: 37px;
    }

    &__link {
      padding: 30px;
      column-gap: 36px;
    }
  }
}

@include respond-down('medium') {
  .index-partners {
    flex-direction: column;

    &__picture {
      width: 119px;
      height: 60px;
    }
  }
}

@include respond-down('small') {
  .index-partners {
    margin-top: 60px;
    padding-top: 40px;

    &__heading-wrap {
      flex-direction: column;
    }

    &__subtitle {
      margin-top: 10px;
    }

    &__partners-list {
      margin-top: 30px;
    }

    &__link {
      padding: 20px 20px 30px;
      flex-direction: column;
    }

    &__text-wrap {
      margin-top: 16px;
    }

    &__button-wrap {
      margin-top: 16px;
    }
  }
}