.index-offices {
  &__offices-tab {
    display: none;
  }

  &__map-items {
    display: flex;
    flex-direction: column;
    background: #fff;
    border-radius: 6px;
  }
}

@include respond-up('s-large') {
  .index-offices {
    &__map-list {
      top: 100px;
      left: 90px;
    }

    &__map-element {
      height: 594px;
    }
  }
}

@include respond-up('medium') {
  .index-offices {
    position: relative;

    &__map-element {
      border-radius: 6px;
      overflow: hidden;
      z-index: 5;
      position: relative;
    }

    &__map-list {
      position: absolute;
      z-index: 10;
      box-shadow: 0 4px 30px 0 #2C2C2F1A;
    }
  }
}

@include respond('medium') {
  .index-offices {
    &__map-list {
      top: 30px;
      left: 30px;
    }

    &__map-element {
      height: 406px;
    }
  }
}

@include respond-down('small') {
  .index-offices {
    &__map-element {
      display: none;
    }
  }
}