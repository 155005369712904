.article-share {
  display: flex;
  border-top: 1px solid var(--border-color);
  align-items: center;
  justify-content: space-between;
  padding-top: 20px;

  &__link {
    display: flex;
    align-items: center;
  }

  &__link-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 6px;
    width: 19px;
    height: 8px;

    svg {
      width: 100%;
      height: 100%;
      transform: rotate(180deg);
      path {
        fill: var(--primary-red);
      }
    }
  }
}

@include respond-up('s-large') {
  .article-share {
    &_hide-link {
      .article-share {
        &__link {
          display: none;
        }
      }
    }
  }
}