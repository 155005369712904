.contacts-offices {
  &__top-wrap {
    display: flex;
  }

  &__recall-wrap {
    display: flex;
    flex-direction: column;
    position: relative;

    &:before {
      position: absolute;
      content: '';
      left: 0;
    }
  }

  &__map {
    border-radius: 6px;
    overflow: hidden;
  }

  &__recall-phone-wrap {
    display: flex;
    flex-direction: column;
  }
}

@include respond-up('s-large') {
  .contacts-offices {
    &__top-wrap {
      justify-content: space-between;
      padding-right: calc(var(--grid-gap) + var(--grid-column) - 40px);
    }

    &__recall-wrap {
      padding-left: 40px;
      padding-bottom: 22px;
      flex: 0 0 var(--grid-column3);
      justify-content: space-between;
      row-gap: 52px;

      &:before {
        height: calc(100% + 40px);
        border-left: 1px solid var(--border-color);
        top: -20px;
      }
    }

    &__map-wrap {
      margin-top: 60px;
    }

    &__map {
      height: 557px;
    }

    &__recall-phone-wrap {
      row-gap: 6px;
    }

    &__socials-wrap {
      margin-top: 14px;
    }
  }
}

@include respond('medium') {
  .contacts-offices {
    &__recall-wrap {
      padding-top: 30px;
      margin-top: 50px;
      flex-direction: row;
      column-gap: 30px;
      justify-content: space-between;
    }

    &__map-wrap {
      margin-top: 40px;
    }

    &__map {
      height: 331px;
    }
  }
}

@include respond-down('medium') {
  .contacts-offices {
    &__top-wrap {
      flex-direction: column;
    }

    &__recall-wrap {
      &:before {
        width: 100%;
        border-top: 1px solid var(--border-color);
        top: 0;
      }
    }

    &__recall-phone-wrap {
      row-gap: 4px;
    }

    &__socials-wrap {
      margin-top: 16px;
    }
  }
}

@include respond-down('small') {
  .contacts-offices {
    &__recall-wrap {
      padding-top: 20px;
      margin-top: 40px;
    }

    &__map-wrap {
      margin-top: 30px;
    }

    &__map {
      height: 440px;
    }

    &__button-wrap {
      margin-top: 40px;
    }

    &__button {
      &.button {
        padding: 12px 46px;
      }
    }
  }
}