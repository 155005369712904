.products-slider {
  &__item{
    &.swiper-slide{
      height: auto;
    }
  }
}

@include respond-down('small') {
  .products-slider {
    &.swiper{
      overflow: unset;
      width: auto;
    }

    &__item{
      &:first-child{
        margin-left: calc(var(--grid-indent) + var(--grid-spacer));
      }
    }
  }
}