.share {
  display: flex;
  align-items: center;

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      width: 100%;
      height: 100%;
    }
  }

  .ya-share2 {
    &__list.ya-share2__list_direction_horizontal {
      margin: 0;
      display: flex;

      .ya-share2 {
        &__item {
          margin: 0;
          border: 1px solid var(--border-color);
          border-radius: 50%;
          transition: border-color var(--default-timing) var(--default-transition-function);
        }

        &__link {
          transition: filter var(--default-timing) var(--default-transition-function);
        }
      }
    }

    &__container.ya-share2__container_color-scheme_whiteblack {
      .ya-share2__badge {
        background: var(--t);

        .ya-share2__icon {
          background-repeat: no-repeat;
        }
      }

      .ya-share2__item {
        &_service_vkontakte {
          .ya-share2__icon {
            background-image: url("../images/svg/share-vk.svg");
          }
        }
      }

      .ya-share2__item {
        &_service_telegram {
          .ya-share2__icon {
            background-image: url("../images/svg/share-tg.svg");
          }
        }
      }

      .ya-share2__item {
        &_service_viber {
          .ya-share2__icon {
            background-image: url("../images/svg/share-vb.svg");
          }
        }
      }

      .ya-share2__item {
        &_service_whatsapp {
          .ya-share2__icon {
            background-image: url("../images/svg/share-wa.svg");
          }
        }
      }
    }

    &__container_shape_round.ya-share2__container_size_m .ya-share2__badge .ya-share2__icon:not(.ya-share2__icon_messenger-contact):not(.ya-share2__icon_more):not(.ya-share2__icon_copy) {
      background-size: cover;
    }
  }
}

@include respond-up('s-large') {
  .share {
    .ya-share2 {
      &__list.ya-share2__list_direction_horizontal {
        .ya-share2 {
          &__item {
            &:hover {
              opacity: 1;
              border-color: var(--grey-text);
              .ya-share2 {
                &__link {
                  filter: brightness(0) saturate(100%) invert(15%) sepia(7%) saturate(0%) hue-rotate(289deg) brightness(92%) contrast(91%);
                }
              }
            }
          }
        }
      }
    }
  }
}

@include respond-up('medium') {
  .share {
    &__icon {
      width: 20px;
      height: 20px;
      margin-right: 14px;
    }

    .ya-share2 {
      &__list.ya-share2__list_direction_horizontal {
        column-gap: 12px;
      }

      &__container.ya-share2__container_color-scheme_whiteblack {
        .ya-share2__badge {
          .ya-share2__icon {
            width: 30px;
            height: 30px;
          }
        }
      }
    }
  }
}

@include respond-down('small') {
  .share {
    &__icon {
      width: 18px;
      height: 18px;
      margin-right: 10px;
    }

    .ya-share2 {
      &__list.ya-share2__list_direction_horizontal {
        column-gap: 10px;
      }

      &__container.ya-share2__container_color-scheme_whiteblack {
        .ya-share2__badge {
          .ya-share2__icon {
            width: 24px;
            height: 24px;
          }
        }
      }
    }
  }
}