.product-page-top-price {
  background-color: #fff;
  &__price{
    @extend h3;
  }

  &__quantity-total{
    color: var(--green-color);
  }

  &__delivery{
    margin-bottom: 10px;
    flex: 5;
    &:first-letter{
      text-transform: uppercase;
    }
  }

  &__favorite{
    flex: 1;
    justify-content: flex-end;
  }

  &__kit-price{
    color: var(--grey-text);
  }

  &__bottom{
    border-top: 1px solid var(--border-color);
    height: 50px;
  }

  &__help-link{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  &__help-icon{
    display: flex;
    margin-right: 6px;
  }

  &__link-text{
    color: var(--grey-text);
  }

  &__wrap-block{
    display:flex;
    gap: 10px;
    justify-content: space-between;
  }
}

@include respond-up('s-large') {
  .product-page-top-price {
    padding: 25px 30px 0 30px;

    &__quantity-total{
      margin-top: 30px;
      margin-bottom: 18px;
    }

    &__quantity{
      margin: 20px 0;
    }

    &__button{
      display: flex;
    }

    &__bottom{
      height: 50px;
      margin: 20px -30px 0;
      border-top: 1px solid var(--border-color);
    }
  }
}

@include respond-up('medium') {
  .product-page-top-price {

  }
}

@include respond('medium') {
  .product-page-top-price {
    padding: 20px 30px 0 30px;
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-column-gap: 18px;

    &__price-wrap{
      grid-column: 1 / 3;
    }

    &__quantity{
      grid-column: 3 / 4;
      height: fit-content;
      margin-top: 5px;
    }

    &__button{
      grid-column: 5 / 7;
      height: fit-content;
    }

    &__bottom{
      grid-column: 1 / 7;
      margin: 0 -30px 0;
    }

    &__quantity-total{
      margin: 16px 0 20px 0;
    }
  }
}

@include respond-down('medium') {
  .product-page-top-price {

  }
}

@include respond-down('small') {
  .product-page-top-price {
    padding: 20px 20px 0 20px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 16px;
    &__price-wrap{
      grid-column: 1 / 3;
      grid-row: 1 / 3;
    }

    &__button{
      grid-column: 3 / 5;
      grid-row: 2 / 3;
      height: fit-content;
      min-width: 160px;
      margin-top: 20px;
    }

    &__quantity-total{
      margin-top: 32px;
      margin-bottom: 20px;
    }

    &__quantity{
      grid-column: 3 / 5;
      grid-row: 1 / 2;
      height: fit-content;
      justify-self: flex-end;
    }

    &__bottom{
      grid-column: 1 / 5;
      grid-row: 3 / 4;
      margin: 20px -20px 0;
    }
  }
}