.top-fieldset {
  display: flex;

  &__fields {
    width: 100%;
    //flex: 0 1 100%;
  }

  &__buttons-wrap {
    &_mobile {
      display: none;
    }
  }

  &__button {
    border: none;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 145%; /* 21.75px */
    text-transform: uppercase;
    width: 100%;
  }

  &__question-link {
    padding: 10px;
    margin: 19px -10px -10px;
  }

  .filter-fieldset__field{
    padding: unset;
  }
}

@include respond-up('s-large') {
  .top-fieldset {
    &__buttons-wrap {
      width: 170px;
      padding-top: 24px;
    }

    &__fields{
      margin-right: 30px;
    }
  }
}

@include respond-up('medium') {
  .top-fieldset {

  }
}

@include respond('medium') {
  .top-fieldset {

  }
}

@include respond-down('medium') {
  .top-fieldset {

  }
}

@include respond-down('small') {
  .top-fieldset {

  }
}