.article-card {
  display: flex;
  flex-direction: column;
  height: 100%;
  background: #fff;
  border-radius: 6px;

  &__main {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }

  &__text-wrap {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
  }

  &__picture {
    display: flex;
    width: 100%;
    overflow: hidden;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 6px;
  }
}

@include respond-up('s-large') {
  .article-card {
    padding: 30px 30px 40px;
    transition: box-shadow var(--default-timing) var(--default-transition-function);

    &_index {
      .article-card {
        &__text-wrap {
          margin-top: 30px;
        }

        &__picture {
          height: calc((var(--grid-column4) - 60px) * 194/388);
        }

        &__title {
          width: calc(var(--grid-column3) - 30px);
        }
      }
    }

    &:hover {
      box-shadow: 0 7px 25px 0 #2C2C2F1A;
      .article-card {
        &__button {
          opacity: 1;
        }
      }
    }

    &__main {
      margin-top: 30px;
    }

    &__text-wrap {
      margin-top: 18px;
    }

    &__title {
      width: calc(var(--grid-column4) - 30px);
    }

    &__button {
      transition: {
        property: opacity, background;
        duration: var(--default-timing);
        timing-function: var(--default-transition-function);
      };
      opacity: 0;
    }

    &__picture {
      height: calc((var(--grid-column6) - 60px) * 313/626);
    }
  }
}

@include respond-up('medium') {
  .article-card {

  }
}

@include respond('medium') {
  .article-card {
    padding: 20px 20px 30px;

    &__main {
      margin-top: 16px;
    }

    &__text-wrap {
      margin-top: 23px;
    }

    &__title {
      width: calc(var(--grid-column2) - 5px + var(--grid-gap));
    }

    &__picture {
      height: calc((var(--grid-column3) - 40px) * 149/299);
    }
  }
}

@include respond-down('medium') {
  .article-card {

  }
}

@include respond-down('small') {
  .article-card {
    padding: 20px 20px 24px;

    &_index {
      .article-card {
        &__picture {
          height: calc((var(--grid-column4) - 40px) * 139/280);
        }
      }
    }

    &__main {
      margin-top: 12px;
    }

    &__text-wrap {
      margin-top: 26px;
    }

    &__title {
      width: calc(var(--grid-column3) - 12px);
    }

    &__picture {
      height: calc((var(--grid-column4) - 40px) * 146/295);
    }
  }
}