.partner-hero {
  &__slider-container {
    position: relative;
  }

  &__item {
    overflow: hidden;
  }

  &__image-wrap {
    width: 100%;
    border-radius: 6px;
    overflow: hidden;
    display: flex;
  }

  &__picture,
  &__image {
    width: 100%;
    height: 100%;
  }

  &__picture {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__image {
    object-fit: cover;
  }
}

@include respond-up('s-large') {
  .partner-hero {
    &__image-wrap {
      height: calc(var(--grid-column12) * 600/1400);
    }

    &__pagination,
    &__arrows {
      bottom: 60px;
    }

    &__pagination {
      left: 60px;
    }

    &__arrows {
      position: absolute;
      right: 60px;
      z-index: 10;
    }
  }
}

@include respond-up('medium') {
  .partner-hero {
    &__pagination {
      position: absolute;
      z-index: 10;
    }
  }
}

@include respond('medium') {
  .partner-hero {
    &__image-wrap {
      height: calc(var(--grid-column6) * 300/696);
    }

    &__pagination {
      left: 30px;
      bottom: 30px;
    }
  }
}

@include respond-down('medium') {
  .partner-hero {
    &__arrows {
      display: none;
    }
  }
}

@include respond-down('small') {
  .partner-hero {
    &__image-wrap {
      height: calc(var(--grid-column4) * 200/335);
    }

    &__pagination {
      margin-top: 12px;
    }
  }
}