.filter-fieldset{
  &__field{
    padding: 0 20px;
  }
}

@include respond-up('s-large') {
  .filter-fieldset{

  }
}