.payment-delivery-part {
  border-radius: 6px;
  background: #fff;

  &__title-wrap {
    border-bottom: 1px solid var(--border-color);
  }

  &__point {
    &:not(:last-child) {
      border-bottom: 1px solid var(--border-color);
    }
  }
}

@include respond-up('s-large') {
  .payment-delivery-part {
    &:not(:first-child) {
      margin-top: 28px;
    }

    &__title-wrap {
      padding: 40px 40px 30px;
    }
  }
}

@include respond('medium') {
  .payment-delivery-part {
    &:not(:first-child) {
      margin-top: 18px;
    }

    &__title-wrap {
      padding: 30px 30px 20px;
    }
  }
}

@include respond-down('small') {
  .payment-delivery-part {
    &:not(:first-child) {
      margin-top: 16px;
    }

    &__title-wrap {
      padding: 20px;
    }
  }
}