.product-params-top{
  &__name{
    color: var(--grey-text);
  }

  &__value{
    color: var(--primary-black);
  }

  &__link{
    color: var(--primary-red);
    position: relative;
    display: block;
    width: fit-content;
    &:before{
      position: absolute;
      right: -8px;
      top: calc(50% - 4px);
      content: '';
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 4px 0 4px 4px;
      border-color: transparent transparent transparent var(--primary-red);
      transform: rotate(0deg);
    }
  }
}

@include respond-up('s-large') {
  .product-params-top{
    &__params-list{
      width: var(--grid-column3);
    }

    &__link{
      margin-top: 30px;
    }

    &__item{
      margin-bottom: 16px;
      &:last-child{
        margin: unset;
      }
    }
  }
}

@include respond-up('medium') {
  .product-params-top{
    &__params-list{
      display: flex;
      justify-content: space-between;
    }
    &__item{
      display: flex;
      align-items: center;
    }
  }
}

@include respond('medium') {
  .product-params-top{
    &__link{
      margin-top: 20px;
    }

    &__item{
      margin-bottom: 12px;
      &:last-child{
        margin: unset;
      }
    }
  }
}