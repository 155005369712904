.contacts-info-list {
  display: grid;

  &__info-item {
    display: flex;
    flex-direction: column;
    grid-column: span 1;
  }

  &__info-subtitle {
    color: var(--grey-text);
  }

  &__link {
    display: flex;
  }
}

@include respond-up('s-large') {
  .contacts-info-list {
    grid-template-columns: repeat(2, var(--grid-column3));
    grid-column-gap: 106px;

    &__link {
      &_phone {
        align-items: center;
        column-gap: 10px;
      }
    }
  }
}

@include respond-up('medium') {
  .contacts-info-list {
    grid-row-gap: 40px;

    &__info-subtitle {
      margin-top: 10px;

      &_link {
        margin-top: 0;
        .contacts-info-list {
          &__link {
            margin-bottom: -10px;
            padding: 10px 0;
          }
        }
      }
    }
  }
}

@include respond('medium') {
  .contacts-info-list {
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 54px;

    &__link {
      &_phone {
        flex-direction: column;
        row-gap: 2px;
      }
    }
  }
}

@include respond-down('small') {
  .contacts-info-list {
    grid-template-columns: 1fr;
    grid-row-gap: 30px;

    &__info-item {
      &_address {
        grid-row: 1;
      }

      &_schedule {
        grid-row: 2;
      }

      &_email {
        grid-row: 3;
      }

      &_phone {
        grid-row: 4;
      }
    }

    &__info-subtitle {
      margin-top: 8px;

      &_link {
        margin-top: 0;
        .contacts-info-list {
          &__link {
            margin-bottom: -8px;
            padding: 8px 0;
          }
        }
      }
    }

    &__link {
      &_phone {
        align-items: center;
        column-gap: 8px;
      }
    }
  }
}