.product-base-top-slider {
  position: relative;
  background-color: #fff;

  &__primary-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__primary {
    height: 100%;
    width: 100%;
  }

  &__picture {
    display: flex;
    width: 100%;
    height: 100%;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  &__secondary-wrap {
    .product-base-top-slider{
      &__picture{
        display: flex;
        align-items: center;
        justify-content: center;
      }

      &__image{
        width: 65px;
        height: 65px;
      }
    }
  }

  &__secondary-slide {
    border: 1px solid transparent;
    &._active{
      border: 1px solid var(--primary-black);
      border-radius: 6px;
    }
    &.swiper-slide {
      //width: calc((58 / 1500) * 100vw);
      //height: calc((56 / 1500) * 100vw);
      max-width: 83px;
      max-height: 70px;
    }
  }

  &__features {
    position: absolute;
    z-index: 25;
    display: flex;
    align-items: center;
    margin: -5px;
  }

  &__feature {
    margin: 5px;
  }
}

@include respond-up('s-large') {
  .product-base-top-slider {
    width: var(--grid-column4);
    padding: 25px;
    display: flex;
    flex-direction: column;

    &__primary-wrap {
      width: 100%;
      height: 100%;
    }

    &__primary {
      &.swiper {
        margin-left: 0;
      }
    }

    &__secondary.swiper {
      height: 70px;
    }

    &__primary-slide{
      .product-base-top-slider{
        &__image{
          width: calc(440 / 1600 * 100vw);
          height: calc(400 / 1600 * 100vw);
          padding: 20px 40px;
        }
      }
    }

    &__features {
      left: 20px;
    }
  }
}

@include respond-up('medium') {
  .product-base-top-slider {
    &__pagination {
      display: none;
    }

    &__features {
      top: 20px;
    }
  }
}

@include respond('medium') {
  .product-base-top-slider {
    width: calc(var(--grid-column3));
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px;

    &__primary-wrap {
      margin-bottom: 20px;
      width: 289px;
      height: 261px;
    }

    &__secondary-wrap {
      margin: 10px -10px 0;
      width: var(--grid-column3);
    }

    &__secondary.swiper {
      padding: 0 10px;
    }

    &__features {
      left: 22px;
    }
  }
}

@include respond-down('medium') {
  .product-base-top-slider{

  }
}

@include respond-down('small') {
  .product-base-top-slider {
    padding-bottom: 14px;

    &__primary-wrap {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: 20px;
    }

    &__primary.swiper {
      width: var(--grid-column4);
      height: calc(var(--grid-column4) * (227 / 228));
    }

    .swiper-pagination {
      position: static;
    }

    &__features {
      top: 12px;
      left: 18px;
    }

    &__secondary-wrap {
      margin-top: 16px;
      padding-left: 20px;
      //display: none;
    }
  }
}