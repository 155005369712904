.warranty-advantages{
  background: #FFF;
  border-radius: 6px;
  &__title{
    display: block;
    color: var(--primary-black);
  }

  &__item{
    background: var(--light-background-color);
    border-radius: 6px;
    &:last-child{
      position: relative;
    }
  }

  &__icon{
    border-radius: 100%;
    background: var(--light-grey);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__subtitle{
    color: var(--primary-black);
  }

  &__item-back{
    position: absolute;
    right: 0;
    bottom: 0;
    display: flex;
  }
}

@include respond-up('s-large') {
  .warranty-advantages{
    padding: 40px 40px 50px;
    &__list{
      grid-gap: 28px;
    }

    &__item{
      padding: 40px 40px 50px;
    }

    &__icon{
      width: 70px;
      height: 70px;
      margin-bottom: 30px;
    }
  }
}

@include respond-up('medium') {
  .warranty-advantages{
    &__list{
      padding-top: 30px;
      display: grid;
      grid-template-columns: 1fr 1fr;
    }
  }
}

@include respond('medium') {
  .warranty-advantages{
    padding: 30px 30px 40px;
    &__list{
      grid-gap: 18px;
    }

    &__item{
      padding: 30px;
      min-height: 248px;
    }

    &__icon{
      width: 60px;
      height: 60px;
      margin-bottom: 20px;
      svg{
        width: 34px;
        height: 34px;
      }
    }
  }
}

@include respond-down('small') {
  .warranty-advantages{
    padding: 20px 20px 30px;

    &__list{
      padding-top: 20px;
      display: grid;
      grid-row-gap: 16px;
    }

    &__item{
      padding: 20px 20px 30px;
      min-height: 194px;
      &:last-child{
        overflow: hidden;
      }
    }

    &__item-back{
      right: -30px;
      svg{
        height: 194px;
      }
    }

    &__icon{
      width: 50px;
      height: 50px;
      margin-bottom: 16px;
      svg{
        width: 30px;
        height: 30px;
      }
    }
  }
}