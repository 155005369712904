.city-select {
  position: relative;
  display: flex;
  align-items: center;

  &:hover {
    .city-select {
      &__wrap {
        &::after {
          border-width: 0 4px 4px 4px;
        }
      }

      &__cities-list {
        opacity: 1;
        pointer-events: auto;
      }
    }
  }

  &__wrap {
    display: flex;
    align-items: flex-start;
    column-gap: 4px;
    position: relative;
    padding: 20px 0;

    &::after {
      content: '';
      position: absolute;
      right: -10px;
      top: 30px;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 4px 4px 0 4px;
      transform: rotate(0deg);
      transition: {
        property: border-width, border-color;
        duration: var(--default-timing);
        timing-function: var(--default-transition-function);
      };
    }
  }

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;

    svg {
      width: 100%;
      height: 100%;

      path {
        transition: stroke var(--default-timing) var(--default-transition-function);
      }
    }
  }

  &__cities-list {
    position: absolute;
    transition: opacity var(--default-timing) var(--default-transition-function);
    opacity: 0;
    pointer-events: none;
    background: var(--background-color);
    display: flex;
    flex-direction: column;
    border-radius: 0 0 4px 4px;
    overflow: hidden;
    top: 100%;
    box-shadow: 0 2px 4px 0 #2C2C2F1A;
    z-index: 10;
  }

  &__city-item {
    &_active {
      background: #fff;

      .city-select {
        &__link {
          color: var(--primary-red);
        }
      }
    }
  }

  &__link {
    padding: 11px 14px;
    display: flex;
  }
}

@include respond-up('s-large') {
  .city-select {
    height: 100%;

    &:hover {
      .city-select {
        &__icon {
          svg path {
            stroke: var(--primary-red);
          }
        }

        &__wrap {
          &::after {
            border-color: transparent transparent  var(--border-color) transparent;
          }
        }
      }
    }

    &__wrap {
      &::after {
        border-color: var(--border-color) transparent transparent transparent;
      }
    }

    &__cities-list {
      width: 213px;

      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 9px;
        border-radius: 0 0 4px 4px;
        //background: linear-gradient(180deg, rgba(44, 44, 47, 0.066) -38.89%, rgba(255, 255, 255, 0.6) 39.87%);
      }
    }
  }
}

@include respond-down('medium') {
  .city-select {
    &__icon {
      svg path {
        stroke: var(--primary-red);
      }
    }

    &__current-city {
      color: var(--primary-red);
    }

    &:hover {
      .city-select {
        &__wrap {
          &::after {
            border-color: transparent transparent  var(--primary-red) transparent;
          }
        }
      }
    }

    &__wrap {
      &::after {
        border-color: var(--primary-red) transparent transparent transparent;
      }
    }

    &__cities-list {
      width: 220px;
    }
  }
}

@include respond-down('small') {
  .city-select {
    &__current-city {
      &.text__small {
        font-size: 14px;
      }
    }
  }
}