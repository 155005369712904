.requisites-block {
  &__info-wrap {
    display: flex;
  }

  &__info-wrap {
    flex-direction: column;
  }

  &__line {
    display: flex;
    border-bottom: 1px solid var(--border-color);
    margin-top: 20px;
  }

  &__ceo-name {
    margin-top: 20px;
  }

  &__ceo-email {
    display: flex;
  }
}

@include respond-up('s-large') {
  .requisites-block {
    &__wrap {
      margin-top: 40px;
      display: grid;
      grid-template-columns: var(--grid-column4) 1fr;
      grid-column-gap: calc(var(--grid-gap) * 2 + var(--grid-column2));
    }

    &__info-wrap {
      grid-column: 1/2;
    }

    &__line {
      width: var(--grid-column4);
    }

    &__requisites-block {
      grid-column: 2/3;
    }
  }
}

@include respond-up('medium') {
  .requisites-block {
    &__name {
      font-weight: 700;
    }

    &__address {
      margin-top: 10px;
    }

    &__ceo-email {
      padding: 5px;
      margin: 0 -5px -5px;
    }
  }
}

@include respond('medium') {
  .requisites-block {
    &__wrap {
      margin-top: 30px;
    }

    &__address {
      width: calc(var(--grid-column4) - 10px);
    }

    &__line {
      width: var(--grid-column5);
    }

    &__requisites-list {
      margin-top: 40px;
    }
  }
}

@include respond-down('medium') {
  .requisites-block {
    &__wrap {
      display: flex;
      flex-direction: column;
    }
  }
}

@include respond-down('small') {
  .requisites-block {
    &__wrap {
      margin-top: 20px;
    }

    &__address {
      margin-top: 8px;
    }

    &__line {
      width: 100%;
    }

    &__ceo-email {
      padding: 8px;
      margin: 0 -8px -8px;
    }

    &__requisites-list {
      margin-top: 30px;
    }
  }
}