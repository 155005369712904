.partner {
  &__dark-wrap {
    background: var(--primary-black);
  }

  &__grey-wrap {
    background: var(--grey-background-color-opacity-50);
  }

  &__white-wrap {
    background: #fff;
  }
}

@include respond-up('s-large') {
  .partner {
    &__dark-wrap {
      padding: 40px 0 100px;

      &:before {
        background-image: url("../images/base/partner-tyre-desk.png");
        background-size: 903px 1021px;
        width: 903px;
        height: 1021px;
      }
    }

    &__produce-slider-wrap,
    &__technologies-wrap,
    &__certificate-wrap,
    &__producer-products {
      padding: 80px 0 100px;
    }

    &__recall-wrap {
      padding: 80px 0 120px;
    }
  }
}

@include respond-up('medium') {
  .partner {
    &__dark-wrap {
      position: relative;

      &:before {
        position: absolute;
        content: '';
        left: 0;
        bottom: 0;
        background-repeat: no-repeat;
        background-position: center;
        z-index: 5;
      }

      .container {
        z-index: 10;
        position: relative;
      }
    }
  }
}

@include respond('medium') {
  .partner {
    &__dark-wrap {
      padding: 30px 0 80px;

      &:before {
        background-image: url("../images/base/partner-tyre-tab.png");
        background-size: 375px 795px;
        width: 375px;
        height: 795px;
      }
    }

    &__produce-slider-wrap,
    &__technologies-wrap,
    &__producer-products {
      padding: 60px 0 80px;
    }

    &__certificate-wrap {
      padding: 40px 0 80px;
    }

    &__recall-wrap {
      padding: 40px 0 100px;
    }
  }
}

@include respond-down('medium') {
  .partner {

  }
}

@include respond-down('small') {
  .partner {
    &__dark-wrap {
      padding: 20px 0 60px;
    }

    &__produce-slider-wrap,
    &__technologies-wrap,
    &__certificate-wrap,
    &__producer-products {
      padding: 40px 0 60px;
    }

    &__recall-wrap {
      padding: 30px 0 80px;
    }
  }
}