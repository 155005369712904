.vue-field {
  &_select {
    &._inactive {
      select {
        background-image: none;
      }
    }
  }

  &_checkbox {
    display: flex;
    align-items: center;

    label {
      margin-left: 10px;
    }
  }
}

@include respond-up('large') {
  .vue-field {

  }
}

@include respond-up('medium') {
  .vue-field {

  }
}

@include respond('medium') {
  .vue-field {

  }
}

@include respond-down('medium') {
  .vue-field {

  }
}

@include respond-down('small') {
  .vue-field {

  }
}