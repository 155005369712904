.map-block {
  &__offices-tab {
    display: none;
  }

  &__map {
    display: flex;
  }

  &__map-element {
    border-radius: 6px;
    overflow: hidden;
  }
}

@include respond-up('s-large') {
  .map-block {
    padding-left: 100px;
    padding-right: 40px;

    &__map {
      flex-direction: row-reverse;
      justify-content: space-between;
    }

    &__map-wrap {
      margin-top: 29px;
    }

    &__map-element {
      width: calc(var(--grid-column4) + var(--grid-gap) - 10px);
      height: 343px;
      margin-right: var(--grid-gap);
    }

    &__map-item {
      width: calc(var(--grid-column4) - 40px);
    }
  }
}

@include respond-up('medium') {
  .map-block {
    margin-top: 20px;
  }
}

@include respond('medium') {
  .map-block {
    padding-left: 90px;
    padding-right: 30px;

    &__map-wrap {
      margin-top: 20px;
    }

    &__map-element {
      margin-top: 30px;
      width: var(--grid-column5);
      height: 357px;
    }
  }
}

@include respond-down('medium') {
  .map-block {
    &__map {
      flex-direction: column;
    }
  }
}

@include respond-down('small') {
  .map-block {
    margin-top: 16px;

    &__title {
      padding: 0 20px;
    }

    &__map-wrap {
      margin-top: 16px;
    }

    &__map-element {
      margin: 20px 20px 0;
      width: calc(var(--grid-column4) - 40px);
      height: 440px;
    }
  }
}