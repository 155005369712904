.cart-page {
  &__content {

  }

  &__products {
  }

  &__result {
  }

  &__order {
  }

  &__empty{
  }

  &__empty-content {
    background-color: white;
    position: relative;
    border-radius: 6px;
  }

  &__empty-subtitle.text {
    color: var(--grey-text);
  }

  &__button-wrap {
    display: flex;
    justify-content: space-between;
  }

  &__politic.text {
    font-size: 12px;
    color: var(--grey-text);

    .cart-page__politic-link {
      color: var(--primary-red);
    }
  }

  &__button{
    border: unset;
    font-style: normal;
    font-weight: 600;
    line-height: 145%; /* 21.75px */
    text-transform: uppercase;
  }
}

@include respond-up('s-large') {
  .cart-page {
    padding-bottom: 120px;

    &__content {
      display: grid;
      grid-gap: 40px var(--grid-gap);
      grid-template-columns: repeat(var(--grid-columns-number), 1fr);
    }

    &__products {
      grid-column: 1/10;
      grid-row: 1/2;
    }

    &__result {
      grid-column: 10/13;
      grid-row: 1/3;
      position: relative;
    }

    &__order {
      grid-column: 1/10;
      grid-row: 2/3;
    }

    &__empty {
      display: grid;
      grid-gap: var(--grid-gap);
      grid-template-columns: repeat(var(--grid-columns-number), 1fr);
    }

    &__empty-wrap{
      display: flex;
    }

    &__empty-icon{
      margin-right: 20px;
    }

    &__empty-content {
      grid-column: 1 / 10;
      padding: 43px 60px 60px;
    }

    &__button-wrap {
      margin-top: 35px;
      align-items: center;
    }
  }
}

@include respond-up('medium') {
  .cart-page {

    &__empty-subtitle {
      margin: 12px 0 30px;
    }

    &__politic {
      margin-right: 20px;
    }

    &__button{
      font-size: 15px;
    }
  }
}

@include respond('medium') {
  .cart-page {
    padding-bottom: 90px;

    &__products, &__result {
      margin-bottom: 40px;
    }

    &__empty-content {
      padding: 30px 40px 60px;
    }

    &__button-wrap {
      margin-top: 28px;
    }

    &__empty-icon{
      margin-bottom: 20px;
    }
  }
}

@include respond-down('medium') {
  .cart-page {

  }
}

@include respond-down('small') {
  .cart-page {
    padding-bottom: 50px;

    &__products, &__result {
      margin-bottom: 30px;
    }

    &__empty-content {
      padding: 40px 40px 50px;
      overflow: hidden;
    }

    &__empty-subtitle {
      margin: 10px 0 22px;
    }

    &__button-wrap {
      flex-wrap: wrap;
    }

    &__button.button {
      order: 2;
      flex: 0 0 100%;
      width: 100%;
    }

    &__politic {
      flex: 0 0 100%;
      order: 5;
      margin-top: 12px;
    }

    &__button-wrap {
      margin-top: 25px;
    }

    &__empty-icon{
      margin-bottom: 28px;
    }

    &__button{
      font-size: 14px;
    }
  }
}