.slider-pagination {
  &.swiper-pagination {
    display: flex;
    align-items: center;
  }

  .swiper-pagination-bullet {
    transition: {
      property: width, height, background;
      duration: var(--default-timing);
      timing-function: var(--default-transition-function);
    };
    display: block;
    background: var(--white-color-opacity40);
    border-radius: 50%;
  }

  .swiper-pagination-bullet-active {
    background: #fff;
  }
}

@include respond-up('s-large') {
  .slider-pagination {
    .swiper-pagination-bullet {
      cursor: pointer;
    }
  }
}

@include respond-up('medium') {
  .slider-pagination {
    &.swiper-pagination {
      flex-direction: column;
      gap: 20px;
    }

    &_horizontal {
      &.swiper-pagination {
        flex-direction: row;
      }
    }

    .swiper-pagination-bullet {
      width: 8px;
      height: 8px;
    }

    .swiper-pagination-bullet-active {
      width: 10px;
      height: 10px;
    }
  }
}

@include respond-down('small') {
  .slider-pagination {
    &.swiper-pagination {
      column-gap: 18px;
    }

    .swiper-pagination-bullet {
      width: 6px;
      height: 6px;
    }

    .swiper-pagination-bullet-active {
      width: 8px;
      height: 8px;
    }
  }
}