.technologies {
  &__list {
    display: grid;
    grid-auto-rows: 1fr;
  }

  &__item {
    background: #fff;
    border-radius: 6px;
    display: flex;
    grid-column: span 1;
  }

  &__description {
    margin-top: 10px;
  }

  &__picture {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 6px;
  }
}

@include respond-up('s-large') {
  .technologies {
    &__list {
      margin-top: 50px;
      grid-row-gap: 28px;
      grid-template-columns: var(--grid-column10);
    }

    &__item {
      padding: 40px var(--grid-column) 40px 40px;
    }

    &__text-wrap {
      padding-top: 30px;
    }

    &__image-wrap {
      margin-right: 40px;
    }

    &__picture {
      height: calc(var(--grid-column2) - 10px);
      width: calc(var(--grid-column2) - 10px);
    }

    &_partners {
      .technologies {
        &__picture {
          height: calc(var(--grid-column) + var(--grid-gap));
          width: calc(var(--grid-column) + var(--grid-gap));
        }
      }
    }
  }
}

@include respond('medium') {
  .technologies {
    &__list {
      margin-top: 30px;
      grid-row-gap: 18px;
      grid-template-columns: var(--grid-column6);
    }

    &__item {
      padding: 30px;
    }

    &__image-wrap {
      margin-right: 30px;
    }

    &__picture {
      height: calc(var(--grid-column2) - 60px);
      width: calc(var(--grid-column2) - 60px);
    }

    &_partners {
      .technologies {
        &__picture {
          height: var(--grid-column);
          width: var(--grid-column);
        }
      }
    }
  }
}

@include respond-down('small') {
  .technologies {
    &__list {
      margin-top: 10px;
      grid-row-gap: 16px;
      grid-template-columns: var(--grid-column4);
    }

    &__item {
      flex-direction: column;
      padding: 20px 20px 30px;
    }

    &__text-wrap {
      margin-top: 20px;
    }

    &__picture {
      height: calc(var(--grid-column) + var(--grid-gap) - 8px);
      width: calc(var(--grid-column) + var(--grid-gap) - 8px);
    }

    &_partners {
      .technologies {
        &__picture {
          height: calc(var(--grid-column) + var(--grid-gap) - 8px);
          width: calc(var(--grid-column) + var(--grid-gap) - 8px);
        }
      }
    }
  }
}