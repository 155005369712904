.button {
  text-align: center;
  transition: var(--default-transition);
  cursor: pointer;
  text-decoration: none;
  border-radius: 100px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;

  &:disabled,
  &._disabled {
    background: var(--light-grey);
    color: var(--grey-text);
    border-color: var(--border-color);
    cursor: unset;
    pointer-events: none;

    .button {
      &__icon-wrap {
        &_stroke {
          svg path {
            stroke: var(--grey-text);
          }
        }

        &_fill {
          svg path {
            fill: var(--grey-text);
          }
        }
      }
    }
  }

  &__icon-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &_red {
    background: var(--primary-red);
    color: #fff;
  }

  &_border {
    border: 1px solid var(--primary-red-60);
    color: var(--primary-red);

    &:disabled,
    &._disabled {
      background: var(--t);
    }
  }

  &_close {
    border: 1px solid var(--border-color);
  }

  &__close-icon {
    position: relative;

    &:before,
    &:after {
      position: absolute;
      content: '';
      top: 50%;
      left: 50%;
      border-bottom: 2px solid var(--grey-text);
    }

    &:before {
      transform: translate3d(-50%, -50%, 0) rotate(45deg);
    }

    &:after {
      transform: translate3d(-50%, -50%, 0) rotate(-45deg);
    }
  }

  &__mini {
    border-radius: 19px;
    height: fit-content;
  }
}

@include respond-up('s-large') {
  .button {
    &_red {
      &:hover {
        background: var(--dark-red);
      }
    }

    &_border {
      &:hover {
        border-color: var(--primary-red);
      }
    }

    &__mini {
      background: var(--border-color);
      color: var(--primary-black);
      transition: {
        property: background, color;
        duration: var(--default-timing);
        timing-function: var(--default-transition-function);
      };

      &:hover {
        background: var(--primary-black);
        color: #fff;
      }
    }
  }
}

@include respond-up('medium') {
  .button {
    &__default {
      padding: 13px 26px;
    }

    &__icon-wrap {
      margin-left: 12px;

      &_round {
        margin-left: 0;
      }
    }

    &_border {
      padding: 12px 29px;
    }

    &_icon {
      padding: 12px 26px;
    }

    &_round {
      width: 46px;
      height: 46px;
    }

    &_close {
      width: 40px;
      height: 40px;
    }

    &__close-icon {
      width: 22px;
      height: 22px;

      &:before,
      &:after {
        width: 20px;
      }
    }

    &__mini {
      padding: 5px 14px;
    }
  }
}

@include respond-down('medium') {
  .button {
    &__mini {
      background: var(--primary-black);
      color: #fff;
    }
  }
}

@include respond-down('small') {
  .button {
    &__default,
    &_border,
    &_icon {
      padding: 12px 20px;
    }

    &__icon-wrap {
      &_round {
        width: 20px;
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
          height: 100%;
          width: 100%;
        }
      }
    }

    &_round {
      width: 38px;
      height: 38px;
    }

    &__icon-wrap {
      margin-left: 10px;

      &_round {
        margin-left: 0;
      }
    }

    &_close {
      width: 34px;
      height: 34px;
    }

    &__close-icon {
      width: 18px;
      height: 18px;

      &:before,
      &:after {
        width: 17px;
      }
    }

    &__mini {
      padding: 4px 12px;
    }
  }
}