.warranty-return-rules{
  background: #FFFFFF;
  border-radius: 6px;

  &__title{
    display: block;
  }

  &__block{
    height: 0;
    transition: opacity .3s, height .3s;
    overflow: hidden;
  }

  &__element{
    border-top: 1px solid var(--border-color);
    &._opened{
      .warranty-return-rules{
        &__rule-icon{
          &:after{
            opacity: 0;
          }
        }
      }
    }
    &:last-child{
      border-bottom: 1px solid var(--border-color);
    }
  }

  &__link{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__rule-icon{
    background: var(--primary-red-10);
    border-radius: 100%;
    position: relative;
    &:before{
      content: '';
      position: absolute;
      background: var(--primary-red);
      height: 2px;
    }

    &:after{
      content: '';
      position: absolute;
      background: var(--primary-red);
      width: 2px;
      transition: opacity .3s;
    }
  }

  &__description{
    padding-left: 20px;
    position: relative;
    &:before{
      content: '';
      position: absolute;
      width: 1px;
      height: 100%;
      left: 0;
      top: 0;
      bottom: 0;

      background: linear-gradient(180deg, rgba(215, 39, 46, 0) 0%, #D7262D 47.92%, rgba(215, 38, 45, 0) 100%);
      transform: rotate(-180deg);

    }
  }
}

@include respond-up('s-large') {
  .warranty-return-rules{
    padding: 40px 40px 50px;
    &__title{
      margin-bottom: 34px;
    }

    &__block-content{
      padding-bottom: 30px;
    }

    &__description{
      margin-top: 45px;
    }
  }
}

@include respond-up('medium') {
  .warranty-return-rules{
    &__element{
      padding-top: 20px;
    }

    &__link{
      padding-bottom: 20px;
    }

    &__rule-icon{
      width: 30px;
      height: 30px;

      &:before{
        width: 16px;
        left: calc(50% - 8px);
        top: calc(50% - 1px);
      }

      &:after{
        height: 16px;
        top: calc(50% - 8px);
        left: calc(50% - 1px);
      }
    }
  }
}

@include respond('medium') {
  .warranty-return-rules{
    padding: 30px 30px 40px;
    &__title{
      margin-bottom: 30px;
    }

    &__element{
      padding-top: 20px;
    }

    &__block-content{
      padding-bottom: 20px;
    }

    &__description{
      margin-top: 35px;
    }
  }
}

@include respond-down('small') {
  .warranty-return-rules{
    padding: 20px 20px 30px;
    &__title{
      margin-bottom: 16px;
    }

    &__element{
      padding-top: 16px;
    }

    &__link{
      padding-bottom: 16px;
    }

    &__block-content{
      padding-bottom: 20px;
    }

    &__rule-icon{
      width: 24px;
      height: 24px;
      margin-left: 10px;
      flex: 0 0 24px;

      &:before{
        width: 12px;
        left: calc(50% - 6px);
        top: calc(50% - 1px);
      }

      &:after{
        height: 12px;
        top: calc(50% - 6px);
        left: calc(50% - 1px);
      }
    }

    &__description{
      margin-top: 35px;
    }
  }
}