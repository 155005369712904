.hero-slider {
  &__slider {
    border-radius: 6px;
    overflow: hidden;
  }

  &__content-wrap,
  &__pagination,
  &__arrows-wrap {
    position: absolute;
    z-index: 30;
  }

  &__item {
    overflow: hidden;
    position: relative;
  }

  &__image-wrap {
    z-index: 5;
  }

  &__title,
  &__subtitle {
    color: #fff;
  }

  &__subtitle {
    position: relative;

    &:before {
      position: absolute;
      content: '';
      top: 0;
      left: 0;
      bottom: 0;
      border-left: 2px solid var(--primary-red);
    }
  }

  &__picture {
    display: flex;
    align-items: center;
    justify-content: center;

    &_slide {
      width: 100%;
      position: relative;
      &:before {
        position: absolute;
        content: '';
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: linear-gradient(180deg, #3C3C48 6.95%, rgba(60, 60, 72, 0) 100%);
        mix-blend-mode: multiply;
      }
    }
  }

  &__image {
    width: 100%;
    height: 100%;

    &_slide {
      object-fit: cover;
    }

    &_logo {
      object-fit: contain;
    }
  }
}

@include respond-up('s-large') {
  .hero-slider {
    &__content-wrap {
      top: calc(125 / 1600 * 100vw);
      left: 80px;
      width: var(--grid-column6);
    }

    &__title {
      margin-top: 20px;
      line-height: 135%;
      max-font-size: 56px;
      font-size: calc(56 / 1600 * 100vw);
    }

    &__subtitle {
      margin-top: 40px;
      padding-left: 22px;
      font-size: 18px;
      font-weight: 600;
      line-height: 150%;
    }

    &__button-wrap {
      margin-top: 60px;
    }

    &__arrows-wrap,
    &__pagination {
      right: 80px;
    }

    &__arrows-wrap {
      bottom: 80px;
    }

    &__picture {
      &_slide {
        height: calc(var(--grid-column12) * 620/1400);
      }

      &_logo {
        width: 116px;
        height: 30px;
      }
    }
  }
}

@include respond-up('medium') {
  .hero-slider {
    &__pagination {
      top: 50%;
      transform: translate3d(0, -50%, 0);
    }

    &__picture {
      &_slide-mob {
        display: none;
      }
    }

    &__hero-block-button {
      &.button {
        padding: 12px 52px;
      }
    }
  }
}

@include respond('medium') {
  .hero-slider {
    &__content-wrap {
      top: 60px;
      left: 40px;
    }

    &__subtitle {
      font-size: 15px;
      padding-left: 18px;
      margin-top: 14px;
    }

    &__button-wrap {
      margin-top: 50px;
    }

    &__arrows-wrap,
    &__pagination {
      right: 40px;
    }

    &__arrows-wrap {
      bottom: 50px;
    }

    &__picture {
      &_slide {
        height: calc(var(--grid-column6) * 394/696);
      }
    }
  }
}

@include respond-down('medium') {
  .hero-slider {
    &__content-wrap {
      width: calc(var(--grid-column4) - 60px);
    }

    &__title {
      margin-top: 20px;
    }

    &__subtitle {
      font-weight: 400;
      line-height: 140%;
    }

    &__picture {
      &_logo {
        width: 85px;
        height: 22px;
      }
    }
  }
}

@include respond-down('small') {
  .hero-slider {
    &__content-wrap {
      top: 30px;
      left: 30px;
      display: flex;
      flex-direction: column;
      height: calc(100% - 120px)
    }

    &__subtitle {
      font-size: 13px;
      padding-left: 14px;
      margin-top: 16px;
    }

    &__button-wrap {
      margin-top: auto;
    }

    &__arrows-wrap,
    &__pagination {
      bottom: 30px;
    }

    &__arrows-wrap {
      right: 30px;
    }

    &__pagination {
      left: 30px;
    }

    &__picture {
      &_slide {
        height: calc(var(--grid-column4) * 520/335);
      }

      &_slide-desk {
        display: none;
      }
    }
  }
}