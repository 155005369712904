.text {
  &__main {
    @extend %text-main;
  }

  &__body,
  &__small,
  &__mini,
  &__subtitle {
    font-style: normal;
    font-size: var(--font-size);
  }

  &__body,
  &__small,
  &__mini {
    font-weight: 400;
  }

  &__body {
    line-height: 160%;
  }

  &__mini,
  &__subtitle {
    line-height: 150%;
  }

  &__form-phone{
    text-transform: uppercase;
    font-weight: 700;
    line-height: 150%;
  }

  &__subtitle {
    font-weight: 600;
    text-transform: uppercase;
  }

  &_red {
    color: var(--primary-red);
  }

  &_black {
    color: var(--primary-black);
  }

  &_light-grey {
    color: var(--light-grey);
  }

  &_grey {
    color: var(--grey-text);
  }

  &_white{
    color: white;
  }

  &__button{
    font-weight: 600;
    text-transform: uppercase;
    font-style: normal;
    font-size: var(--font-size);
  }
}

@include respond-up("s-large") {
  .text {
    &__subtitle {
      --font-size: 20px;
    }

    &__card-title {
      font-style: normal;
      line-height: 150%;
    }
  }
}

@include respond-up("medium") {
  .text {
    &__body {
      --font-size: 16px;
    }

    &__small {
      line-height: 160%;
      --font-size: 14px;
    }

    &__button {
      --font-size: 15px;
      line-height: 145%;
    }

    &__mini {
      --font-size: 12px;
    }
    &__form-phone{
      font-size: 26px;
    }

    &__form-choice {
      line-height: 150%;
      font-size: 18px;
      font-weight: 600;
    }

    &__form-phone{
      font-size: 26px;
    }
  }
}

@include respond("medium") {
  .text {
    &__subtitle {
      --font-size: 17;
    }

    &__card-title {
      --font-size: 16px;
      font-weight: 600;
    }
  }
}

@include respond-down("small") {
  .text {
    &__body {
      --font-size: 15px;
    }

    &__small {
      line-height: 150%;
      --font-size: 13px;
    }

    &__mini {
      --font-size: 11px;
    }

    &__subtitle {
      --font-size: 15px;
    }

    &__button {
      --font-size: 13px;
      line-height: 140%;
    }

    &__card-title {
      --font-size: 14px;
      font-weight: 500;
    }

    &__form-phone{
      font-size: 20px;
      line-height: 31px;
    }

    &__form-choice{
      line-height: 160%;
      font-size: 15px;
      font-weight: 400;
    }

    &__form-phone{
      font-size: 20px;
    }
  }
}
