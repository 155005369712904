.catalog-filter-top {
  background-color: #fff;
  border-radius: 6px;
  position: relative;

  &__heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__main {
    display: flex;

    .filter-fieldset {
      &__fields {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-column-gap: 28px;
        grid-row-gap: 30px;
      }

      &__field {
        grid-column: span 1;

        &_season,
        &_type {
          grid-column: span 3;
        }
      }
    }
  }

  &__tab-link{
    display: flex;
    align-items: center;
  }

  &__tab-icon{
    display: flex;
    margin-right: 10px;
  }

  &__filters {
    flex: 0 1 100%;
  }

  &__mobile-main {
    border-top: 1px solid var(--grey-stroke-color);
  }

  &__modification-info-wrap {
    display: none;
  }

  &__modification-info {
    display: flex;
  }

  &__modification-info-item {
    display: flex;
  }

  &__modification-info-item-title {
    color: var(--grey-text-color);
  }

  &__modification-info-item-value {
    margin-left: 12px;
  }

  &_car {
    .catalog-filter-top {
      &__filters {
        flex: 0 1 100%;
      }

      &__main {
        flex-direction: column;
      }

      &__modification-info-wrap {
        display: block;
      }
    }

    .filter-fieldset {
      &__fields {
        grid-template-columns: repeat(4, 1fr);
      }
    }
  }
}

@include respond-up('s-large') {
  .catalog-filter-top {
    padding: 30px 40px 40px;

    &__heading {
      margin-bottom: 29px;
    }

    &__mobile-main {
      display: none;
    }

    &__sort {
      display: none;
    }

    &__tabs-list{
      height: 44px;
    }

    &__tab-icon{
      svg{
        path{
          transition: var(--default-transition);
        }
      }
    }

    &__tab{
      &:hover{
        svg{
          path{
            fill: #FFF;
          }
        }
      }

      &._active{
        svg{
          path{
            fill: #FFF;
          }
        }
      }
    }
  }
}

@include respond-up('medium') {
  .catalog-filter-top {
    &__modification-info {
      margin-top: 30px;
    }

    &__modification-info-item {
      &:not(:first-child) {
        margin-left: 30px;
      }
    }
  }
}

@include respond('medium') {
  .catalog-filter-top {
    &__heading {
      padding: 30px 30px 0;
    }

    &__show-filters {
      margin: 22px 0;
      bottom: 40px;
    }

    &__mobile-main {
      padding: 0 24px;
      grid-gap: var(--grid-gap);
    }


    &__main {
      padding: 30px 30px 40px;
    }


    .filter-fieldset {
      &__fields {
        grid-template-columns: repeat(6, 1fr);
      }

      &__field {
        grid-column: span 3;

        &_season,
        &_type {
          grid-column: span 4;
        }
      }

      &__buttons-wrap {
        display: none;
      }
    }

    .top-fieldset {
      &__buttons-wrap {
        justify-content: flex-end;
        grid-column: span 2;

        &_mobile {
          display: flex;
        }
      }
    }

    .enum-filter-field {
      height: 100%;

      &__wrapper,
      &__values {
        height: 100%;
      }
    }
  }
}

@include respond-down('medium') {
  .catalog-filter-top {
    &__main {

    }

    &__switcher-wrap.catalog-switcher {
      display: none;
    }

    &__mobile-main {
      display: grid;
      align-items: center;
      grid-template-columns: repeat(2, 1fr);
    }

    &__sort {
      grid-column: 1;
      width: max-content;
    }

    &__show-filters {
      position: fixed;
      left: 50%;
      transform: translate3d(-50%, 0, 0);
      z-index: 1000;
    }

    &__main {
      display: flex;
    }
  }
}

@include respond-down('small') {
  .catalog-filter-top {
    &__tab-link {
      &.tabs__link {
        font-size: 13px;
      }
    }

    &__show-filters-title {
      font-size: 15px;
      font-style: normal;
      font-weight: 500;
      line-height: 125%;
      letter-spacing: -0.3px;
      text-transform: capitalize;
    }

    &__heading {
      padding: 20px 20px 0;
    }

    &__show-filters {
      margin: 18px 0;
      bottom: 40px;
    }

    &__mobile-main {
      padding: 0 12px;
      grid-gap: 25px;
    }

    &__modification-info {
      margin-top: 25px;
    }

    &__modification-info {
      flex-direction: column;
    }

    &__modification-info-item {
      &:not(:first-child) {
        margin-top: 8px;
      }
    }

    &__main {
      padding: 20px 20px 30px;
    }


    .filter-fieldset {
      flex-wrap: wrap;

      &__fields {
        grid-template-columns: 1fr;
        grid-row-gap: 20px;
      }

      &__field {
        grid-column: span 1;

        &_season,
        &_type {
          .filter-field {
            margin-bottom: 25px;
          }
        }
      }

      &__buttons-wrap,
      &__button {
        width: 100%;
      }

      &__question-link {
        display: none;
      }
    }

    .enum-filter-field {
      &__values {
        flex-wrap: wrap;
      }

      &__value {
        flex: 0 0 100%;

        &:not(:first-child) {
          margin-left: 0;
          margin-top: 12px;
        }
      }
    }

    &__tab-icon {
      display: none;
    }
  }
}