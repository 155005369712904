.contacts-heading {
  &__tab-item {
    &._active {
      &:before {
        border-color: var(--primary-red);
      }

      .contacts-heading {
        &__tab-title {
          color: var(--primary-red);
        }
      }
    }
  }

  &__tabs-wrap {
    border-bottom: 1px solid var(--border-color);
  }

  &__tabs-list {
    position: relative;
    margin-bottom: 0;
  }
}

@include respond-up('s-large') {
  .contacts-heading {
    &__tabs-wrap {
      margin-top: 40px;
    }

    &__tab-item {
      &:hover {
        &:before {
          opacity: 0;
        }

        .contacts-heading {
          &__tab-title {
            color: var(--primary-red);
          }
        }
      }

      &._active {
        &:hover {
          &:before {
            opacity: 1;
          }
        }
      }
    }
  }
}

@include respond('medium') {
  .contacts-heading {
    &__tabs-wrap {
      margin-top: 30px;
    }
  }
}

@include respond-down('small') {
  .contacts-heading {
    &__tabs-wrap {
      margin-top: 20px;
    }
  }
}