.chosen-field {
  &__values {
    display: flex;
    margin: -4px;
  }

  &__value {
    margin: 4px;
    white-space: nowrap;
  }

  input {
    display: none;
  }

  label {
    //@extend .text__small;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%; /* 22.4px */
    position: relative;
    color: var(--primary-black);
    cursor: pointer;
    display: flex;
    align-items: center;
    border: 1px solid var(--light-grey);
    border-radius: 19px;
    padding: 4px 28px 4px 8px;
    background-color: transparent;

    &::before,
    &::after {
      content: '';
      position: absolute;
      top: calc(50% - 1px);
      right: 8px;
      width: 15px;
      border-bottom: 1px solid #7E7E7F;
    }

    &::before {
      transform: translate3d(0, -50%, 0) rotate(45deg);
    }

    &::after {
      transform: translate3d(0, -50%, 0) rotate(-45deg);
    }
  }
}

@include respond-up('large') {
  .chosen-field {
    label {
      &::before,
      &::after {
        transition: border-bottom-color .3s;
      }

      &:hover {
        &::before,
        &::after {
          border-bottom-color: var(--grey-text-color);
        }
      }
    }
  }
}

@include respond-up('medium') {
  .chosen-field {
    &__values {
      flex-wrap: wrap;
    }
  }
}

@include respond('medium') {
  .chosen-field {

  }
}

@include respond-down('medium') {
  .chosen-field {

  }
}

@include respond-down('small') {
  .chosen-field {
    &__values {
      overflow-x: scroll;
      padding: 0 var(--grid-spacer);
      margin: 0 calc(var(--grid-spacer) * -1);

      &::-webkit-scrollbar {
        display: none;
      }
    }

    label {
      padding: 2px 23px 2px 6px;

      &::before,
      &::after {
        right: 6px;
      }
    }
  }
}