.header-mobile {
  background-color: var(--primary-black);
  height: 87px;
  border: 1px solid var(--grey-stroke-color);

  &__container{
    height: 100%;
  }

  &__user-item-icon{
    position: relative;
    display: flex;
  }

  &__user-item-badge{
    position: absolute;
    width: 15px;
    height: 15px;
    background-color: var(--primary-red);
    color: #FFFFFF;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
  }

  &__logo{
    display: flex;
  }

  &__logo-link{
    display: flex;
    svg{
      path{
        fill: #FFFFFF;
      }
    }
  }

  &__favorites{
    svg path {
      &:first-child {
        fill: var(--black-color);
      }

      &:last-child {
        display: none;
      }
    }
  }

  &__search-block {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;

    svg {
      width: 100%;
      height: 100%;
    }
  }
}

@include respond-up('s-large') {
  .header-mobile {
    display: none;
  }
}

@include respond-up('medium') {
  .header-mobile {

  }
}

@include respond('medium') {
  .header-mobile {
    height: 74px;

    &__logo-link{
      svg{
        width: 85px;
        height: 20px;
      }
    }

    &__mobile-menu-link{
      padding: 0 18px;
      margin-left: -18px ;
    }

    &__logo{
      margin-right: 30px;
    }

    &__recall-link{
      padding-left: 10px;
    }

    &__user-item{
      display: flex;
      align-items: center;
    }

    &__cart-price{
      margin-left: 11px;
    }

    &__contact {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      height: 100%;
    }

    &__recall{
      &_mobile{
        display: none;
      }
    }

    &__recall-text{
      font-size: 13px;
      font-style: normal;
      font-weight: 600;
      line-height: 140%; /* 18.2px */
      text-transform: uppercase;
      color: #FFFFFF;
      border-bottom: 1px solid var(--grey-text);
    }

    &__search-block {
      margin: 0 13px 0 20px;
    }
  }
}

@include respond-down('medium') {
  .header-mobile {
    z-index: 100;

    &__recall, &__delimiter, &__phone{
      display: none;
    }

    &__recall{
      &_mobile{
        display: block;
        height: 100%;
      }
    }

    &__recall_mobile-icon{
      width: 28px;
      height: 28px;
      background-color: var(--primary-color-15);
      border-radius: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: background var(--default-transition-timing) var(--default-transition-function);

      svg path {
        transition: fill var(--default-transition-timing) var(--default-transition-function);
      }
    }

    &__phone-link{
      display: flex;
      align-items: center;
      height: 100%;
      padding: 0 10px;
      margin: 0 -10px;
      transition: opacity var(--default-transition-timing) var(--default-transition-function);

      &._opened {
        opacity: 1;
        pointer-events: auto;
        .header-mobile {
          &__recall_mobile-icon {
            background: var(--primary-color);

            svg path {
              fill: #fff;
            }
          }
        }
      }
    }

    &__container{
      display: grid;
      grid-template-columns: 56px 0 auto auto;
      align-items: center;
    }

    &__user-item{
      height: 100%;
      display: flex;
      align-items: center;
    }

    &__menu{
      display: none;
    }

    &__lk-button{
      display: none;
    }

    &__user-item-text{
      display: none;
    }

    &__mobile-menu-link{
      height: 100%;
      display: flex;
      align-items: center;
      position: relative;

      &._menu-opened {
        .header-mobile {
          &__hamburger-line{
            &_top{
              transform: translateY(6px) rotate(45deg);
            }

            &_middle{
              opacity: 0;
            }

            &_bottom{
              transform: translateY(-6px) rotate(-45deg);
            }
          }
        }
      }
    }

    &__hamburger{
      height: 14px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    &__hamburger-line{
      border: 1px solid var(--black-color);
      width: 24px;
      &_top, &_bottom{
        transition: {
          property: transform;
          duration: var(--default-transition-timing);
          timing-function: var(--default-transition-function);
        };
      }

      &_middle{
        transition: opacity var(--default-transition-timing) var(--default-transition-function);
      }
    }

    &__contact{
      flex: 1 1 auto;
    }

    &__user-items{
      display: flex;
      height: 100%;
    }

    &__favorites{
      padding-right: 13px;
    }

    &__cart{
      padding-left: 13px;
    }

    &__user-item-badge{
      right: -6px;
      top: -7px;
    }

    &__cart-block{
      display: flex;
      width: 24px;
      margin: 0 7px;
    }

    &__buttons-wrap {
      display: flex;
      align-items: center;
    }

    &__search-block {
      svg {
        circle,
        path {
          stroke: #fff;
        }
      }
    }
  }
}

@include respond-down('small') {
  .header-mobile {
    height: 58px;

    &__logo-link{
      margin-right: 24px;
      svg{
        width: 60px;
        height: 14px;
      }
    }

    &__contact{
      display: flex;
      flex: 1 1 auto;
      justify-content: flex-end;
      height: 100%;
      align-items: center;
    }

    &__mobile-menu-link{
      padding: 0 10px;
      margin-left: -10px;
    }

    &__favorites{
      padding-right: 11px;
    }

    &__cart{
      padding-left: 11px;
    }

    &__cart-price{
      display: none;
    }

    &__recall{
      &_mobile{
      }
    }

    &__search-block {
      margin: 0 9px 0 20px;
    }

    &__container{
      display: flex;
      align-items: center;
    }

    &__drop-menu {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
    }

    &__recall {
      &_mobile-icon {
        width: 20px;
        height: 20px;
      }

      &_mobile {
        margin-right: 6px;
      }
    }

    &__cart-block {
      width: 20px;
      margin: 0 6px;

      &_cart {
        margin-right: 0;
      }
    }

    &__buttons-wrap {
      margin-left: auto;
    }
  }
}