@include respond-up('s-large') {
  .another-articles-sticky {
    position: sticky;
    top: 100px;

    &__list {
      margin-top: 11px;
    }

    &__item {
      &:not(:last-child) {
        border-bottom: 1px solid var(--border-color);
      }
    }

    &__link {
      display: flex;
      flex-direction: column;
      padding: 12px 0 20px;

      &:hover {
        .another-articles-sticky {
          &__link-title {
            color: var(--primary-black);
          }
        }
      }
    }

    &__link-title {
      margin-top: 4px;
      transition: color var(--default-timing) var(--default-transition-function);
      color: var(--grey-text);
    }
  }
}

@include respond-down('medium') {
  .another-articles-sticky {
    display: none;
  }
}