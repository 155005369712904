.product-base-tabs-mobile {
  &__item {
    &:not(:last-child) {
      border-bottom: 1px solid var(--border-color);
    }

    &._opened {
      .product-base-tabs-mobile{
        &__link-icon {
          &:before {
            transform: rotate(0deg);
            border-bottom-color: var(--primary-red);
          }
        }
        &__link-title{
          color: var(--primary-red);
        }
      }
    }
  }

  &__block {
    height: 0;
    transition: .4s;
    overflow: hidden;
  }

  &__content {
    padding: 15px 18px 25px;
  }

  &__link {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 18px;
  }

  &__link-title{
    transition: color .3s;
  }

  &__link-icon {
    position: relative;
    &:before{
      content: '';
      position: absolute;
      top: -2px;
      right: 0;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 6px 6px 6px;
      border-color: transparent transparent var(--primary-black) transparent;
      transform: rotate(180deg);
      transition: transform .3s, border .3s;
    }
  }
}