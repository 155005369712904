@include respond-up('s-large') {
  .index-publications {
    &__wrap {
      padding: 80px 0 100px;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: var(--grid-gap);
      grid-row-gap: 40px;
    }

    &__title,
    &__link-wrap {
      grid-row: 1;
    }

    &__title {
      grid-column: 1;
    }

    &__link-wrap {
      grid-column: 2;
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }

    &__list {
      grid-template-columns: repeat(3, var(--grid-column4));
      grid-column: 1/3;
      grid-row: 2;
    }
  }
}

@include respond-up('medium') {
  .index-publications {
    padding: 0 var(--grid-spacer-and-indent);

    &__list {
      display: grid;
      grid-gap: var(--grid-gap);
    }

    &__wrap {
      border-bottom: 1px solid var(--border-color);
    }
  }
}

@include respond('medium') {
  .index-publications {
    &__wrap {
      padding: 60px 0 60px;
    }

    &__list {
      grid-template-columns: repeat(2, var(--grid-column3));
    }

    &__list,
    &__link-wrap {
      margin-top: 30px;
    }

    &__item {
      min-height: 390px;
      &:nth-child(3) {
        display: none;
      }
    }
  }
}

@include respond-down('small') {
  .index-publications {
    &__wrap {
      padding: 40px 0 50px;
      position: relative;

      &::before {
        position: absolute;
        content: '';
        bottom: 0;
        left: var(--grid-spacer-and-indent);
        border-bottom: 1px solid var(--border-color);
        width: calc(100% - var(--grid-spacer-and-indent) * 2);
      }
    }

    &__list {
      display: flex;
      column-gap: 10px;
    }

    &__item {
      flex: 0 0 calc(var(--grid-column4) - 15px);
    }

    &__list,
    &__link-wrap {
      margin-top: 20px;
    }

    &__title,
    &__list,
    &__link-wrap {
      padding: 0 var(--grid-spacer-and-indent);
    }

    &__list {
      overflow: scroll hidden;
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */

      &::-webkit-scrollbar {
        display: none;
      }

      section::-webkit-scrollbar {
        width: 0 !important
      }

      overflow: -moz-scrollbars-none; /* Firefox */
    }
  }
}