.index-why-we-cards {
  &__list {
    display: grid;
  }

  &__item {
    background: #fff;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    row-gap: 10px;
  }

  &__title {
    line-height: 140%;
    color: var(--primary-red);
  }

  &__title,
  &__subtitle,
  &__description {
    z-index: 20;
  }

  &__tyre {
    z-index: 10;
  }

  &_dark {
    .index-why-we-cards {
      &__item {
        background: rgba(126, 126, 127, 0.4);
      }

      &__subtitle {
        color: #fff;
      }

      &__description {
        article {
          color: #fff;
          p {
            color: #fff;
          }
        }
      }

      &__tyre {
        svg {
          g {
            opacity: .3;
          }
        }
      }
    }
  }
}

@include respond-up('s-large') {
  .index-why-we-cards {
    &__item {
      padding: 30px 40px 40px;
      position: relative;
    }

    &__title {
      font-size: 46px;
    }

    &__tyre {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        width: 100%;
        height: 100%;
      }
    }
  }
}

@include respond-up('medium') {
  .index-why-we-cards {
    padding: 0 var(--grid-spacer-and-indent);
    margin-top: 40px;

    &__list {
      grid-template-columns: repeat(3, 1fr);
      grid-column-gap: 10px;
    }

    &__title {
      font-weight: 600;
    }
  }
}

@include respond('medium') {
  .index-why-we-cards {
    &__item {
      padding: 30px 20px;
    }

    &__title {
      font-size: 38px;
    }

    &__description {
      article {
        p, li, ol, a {
          font-size: 14px;
        }
      }
    }
  }
}

@include respond-down('medium') {
  .index-why-we-cards {
    &__tyre {
      display: none;
    }
  }
}

@include respond-down('small') {
  .index-why-we-cards {
    margin-top: 30px;

    &__list {
      grid-template-columns: repeat(3, calc(var(--grid-column4) - 15px));
      grid-column-gap: 9px;
      padding: 0 var(--grid-spacer-and-indent);

      overflow: scroll hidden;
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */

      &::-webkit-scrollbar {
        display: none;
      }

      section::-webkit-scrollbar {
        width: 0 !important
      }

      overflow: -moz-scrollbars-none; /* Firefox */
    }

    &__item {
      padding: 20px 20px 30px;
    }

    &__title {
      font-size: 40px;
      font-weight: 400;
    }

    &__description {
      color: var(--grey-text);
    }
  }
}