.form-bid {
  background-color: var(--primary-black);
  position: relative;
  border-radius: 6px;

  &::before{
    content: '';
    top: 0;
    right: 0;
    bottom: 0;
    background-size: cover;
    background-position: left top;
    background-repeat: no-repeat;
    position: absolute;
  }

  &__button-wrap {
    button {
      border: unset;
      font-size: 15px;
      font-style: normal;
      font-weight: 600;
      line-height: 145%; /* 21.75px */
      text-transform: uppercase;
      color: #FFFFFF;
    }
  }

  &__data {
    z-index: 10;
  }

  &__icon {
    display: flex;
    position: absolute;
    z-index: 5;
    top: 0;
    bottom: 0;
    left: 0;

    svg{
      height: 100%;
      width: 100%;
    }
  }

  &__heading {
    position: relative;
    z-index: 10;
    display: flex;
    flex-direction: column;
    transition: all .4s;

    &::before {
      content: '';
      position: absolute;
      bottom: 0;
    }
  }

  &__title,
  &__description {
    color: #fff;
    transition: opacity .3s;
  }

  &__main,
  &__bottom {
    display: flex;
  }

  &__button-wrap {
    display: flex;
    flex: 0 0 auto;
  }

  &__policy {
    flex: 0 1 auto;
  }

  &__success {
    display: flex;
    width: 100%;

    &.request-form {
      &__success {
        justify-content: flex-start;
        left: 0;
        right: 0;
      }
    }
  }

  &__success-wrap{
    display: flex;
    flex-direction: column;
  }

  &__success-icon {
    display: flex;
    overflow: hidden;
  }

  &__success-title {
    color: #fff;
  }

  &__success-description {
    color: #fff;
  }

  &__phone{
    color: white;
    margin-top: 8px;
  }

  &__fields{
    margin-top: 30px;
  }

  &__inner {
    position: relative;
  }
}

@include respond-up('s-large') {
  .form-bid {
    margin: 80px 0;
    height: 296px;

    &::before{
      background-image: url('../images/base/form-tyre-right.png');
      width: 526px;
      height: 296px;
    }

    &__data {
      padding: 40px 0 0 0;
    }

    &__description {
      max-width: 350px;

      &_top{
        margin-top: 30px;
      }
    }

    &__form-wrap {
      flex: 0 1 100%;
    }

    &__icon {
      &_top {
        left: 0;
      }
    }

    &__bottom {
      margin-top: 37px;
      display: flex;
    }

    &__button-wrap{
      margin-right: 32px;
    }

    &__heading {
      padding: 40px 0 80px;
      grid-column: 2 / 5;

      &::before {
        width: 216px;
        height: 1px;
        top: 147px;
        left: 94%;
        background: var(--grey-text);
        transform: rotate(90deg);
      }
    }

    &__inner{
      grid-column: 6 / 12;
    }


    &__fields{
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 20px;
      margin-top: 30px;
    }

    &__field{
      border-radius: 6px;
    }

    &__success {
      align-items: center;
    }

    &__success-icon {
      margin-right: 30px;
      margin-bottom: 35px;
    }

    &__success-description {
      margin-top: 10px;
    }

    &__text-choice{
      width: 452px;
    }

    &__phone {
      transition: color var(--default-timing) var(--default-transition-function);

      &:hover {
        color: var(--primary-red);
      }
    }
  }
}

@include respond-up('medium') {
  .form-bid {
    &__policy{
      width: 275px;
    }

    &__button-wrap {
      button {
        width: var(--grid-column2);
      }
    }
  }
}

@include respond('medium') {
  .form-bid {
    margin: 60px 0;
    height: 498px;

    &::before{
      width: 460px;
      height: 498px;
      background-image: url('../images/base/form-tyre-right-m.png');
    }

    &__heading {
      grid-column: 1 / 6;
      padding: 30px 0 30px 40px;
    }

    &__inner{
      grid-column: 1 / 6;

      &::before {
        width: calc(100% - 40px);
        left: 40px;
      }
    }

    &__data {
      padding: 20px 0 0 40px;
      width: 100%;
    }

    &__form-wrap{
      width: 100%;
    }

    &__fields{
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 20px;
    }

    &__bottom{
      margin-top: 25px;
      margin-bottom: 50px;
      display: flex;
      align-items: center;
    }

    &__button-wrap{
      margin-right: 55px;
    }

    &__success{
      padding-top: 40px;
      padding-left: 40px;
    }

    &__success-description {
      margin-top: 3px;
    }

    &__success-icon{
      margin-bottom: 20px;
    }

    &__description {
      &_top{
        margin-top: 20px;
      }
    }

    &__phone  {
      margin-top: 10px;
    }
  }
}

@include respond-down('medium') {
  .form-bid {
    &.grid-block {
      grid-row-gap: unset;
    }

    &__inner {
      position: relative;

      &::before {
        position: absolute;
        content: '';
        top: 0;
        right: 0;
        border-bottom: 1px solid var(--grey-text);
      }
    }

    &__success{
      flex-direction: column;
      width: 100%;
    }
  }
}

@include respond-down('small') {
  .form-bid {
    margin: 40px 0;

    &::before{
      background-size: cover;
      width: 335px;
      height: 100%;
      background-image: url('../images/base/form-tyre-right-s.png');
    }

    &__heading {
      grid-column: 1 / 5;
      padding: 20px 30px 30px 20px;

      &::before{
        width: calc(100% - 50px);
        height: 1px;
        background: var(--grey-text);
      }
    }

    &__title{
      font-size: 21px;
      letter-spacing: 0.1em;
    }

    &__inner{
      grid-column: 1 / 5;
      padding: 0 30px 0 20px;
    }

    &__description{
      &.text__body {
        line-height: 170%;
      }
    }

    &__data {
      width: 100%;
      padding-top: 20px;
    }

    &__form-wrap{
      width: 100%;
    }

    &__fields{
      display: grid;
      grid-template-columns: 1fr;
      grid-row-gap: 11px;
    }

    &__field{
      div{
        width: 100%;

        input[type='text']{
          height: 38px;
        }
      }
    }

    &__button-wrap{
      display: block;
      margin-bottom: 10px;

      button{
        max-width: 227px;
        width: 100%;
        letter-spacing: -0.1em;
      }
    }

    &__bottom{
      width: var(--grid-column3);
      margin-top: 17px;
      margin-bottom: 22px;
      display: flex;
      flex-direction: column;
    }

    &__policy{
      .policy-checkbox__inner {
        text-align: left;
      }
    }

    &__success{
      padding: 40px 30px 0 20px;
    }

    &__success-icon{
      margin-bottom: 18px;
    }

    &__success-wrap{
      row-gap: 6px;
    }

    &__description {
      &_top{
        margin-top: 16px;
      }
    }

    &__success-description {
      &.text__main {
        font-weight: 400;
        font-size: 15px;
        line-height: 160%;
      }
    }
  }
}