.content-header{
  &_dark {
    background-color: var(--primary-black);

    .content-header {
      &__title {
        color: #fff;
      }
    }

    .breadcrumbs {
      &__item {
        &:last-child {
          .breadcrumbs__name {
            color: #fff;
          }
        }
      }
    }
  }

  &__total-title-wrap {
    display: flex;
  }

  &__total {
    font-weight: 600;
    line-height: 150%;
    color: var(--grey-text);
  }
}

@include respond-up('s-large') {
  .content-header{
    padding-top: 30px;

    &_article {
      .content-header {
        &__title-wrap {
          padding-top: 60px;
          width: var(--grid-column8);
        }

        &__article-title {
          width: var(--grid-column6);
        }
      }
    }

    &__title{
      padding-top: 60px;
      padding-bottom: 40px;
    }
  }
}

@include respond-up('medium') {
  .content-header{
    &_article {
      .content-header {
        &__title-wrap {
          align-items: flex-end;
          justify-content: space-between;
          display: flex;
        }

        &__date-wrap {
          padding-bottom: 7px;
        }
      }
    }

    &__total-title-wrap {
      align-items: baseline;
    }

    &__total {
      margin-left: 20px;
      font-size: 16px;
    }
  }
}

@include respond('medium') {
  .content-header{
    &_article {
      .content-header {
        &__title-wrap {
          padding-top: 50px;
        }

        &__article-title {
          width: calc(var(--grid-column5) - 44px);
        }
      }
    }

    &__title{
      padding-top: 50px;
    }
  }
}

@include respond-down('medium') {
  .content-header{
    padding-top: 20px;

    &__title{
      padding-bottom: 30px;

      &_news {
        padding-bottom: 20px;
      }
    }
  }
}

@include respond-down('small') {
  .content-header{
    &_article {
      .content-header {
        &__title-wrap {
          padding-top: 30px;
          flex-direction: column;
        }

        &__date-wrap {
          margin-top: 8px;
        }
      }
    }

    &__title{
      padding-top: 30px;
    }

    &__total-title-wrap {
      flex-direction: column;
    }

    &__total {
      margin-top: 2px;
      font-size: 15px;
    }
  }
}