.sidebar-fieldset {
  .filter-fieldset{
    &__field{
      border-bottom: 1px solid var(--border-color);
    }
  }

  &__heading {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
  }

  &__main {
    margin-top: 20px;
  }

  &__submit {
    margin-top: 20px;
    width: 100%;
  }

  &__fields-wrap {
    background: #FFFFFF;
    position: relative;
    margin-top: 20px;
    border-top: 1px solid var(--grey-stroke-color);
    border-radius: 6px;
  }

  &__ninja-button {
    position: absolute;
    z-index: 950;
    top: 0;
    left: 100%;
    transform: translate3d(0, -50%, 0);
    color: #FFFFFF;
    background: var(--primary-red);

    border-radius: 0;
    border: none;
    padding: 7px 15px 10px;

    transition: background, top;
    transition-duration: var(--default-timing);
    transition-timing-function: var(--default-transition-function);

    opacity: 0;
    pointer-events: none;

    &._show {
      opacity: 1;
      pointer-events: auto;
    }

    &:hover {
      background: var(--dark-red);
      border-color: var(--dark-red);

      &::before {
        border-color: var(--t) var(--dark-red) var(--t) var(--t);
      }
    }

    &::before {
      content: '';
      position: absolute;
      top: calc(50% - 4px);
      right: 100%;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 5px 5px 5px 0;
      border-color: var(--t) var(--primary-red) var(--t) var(--t);
      transition: border-color var(--default-timing) var(--default-transition-function);
    }
  }

  &__ninja-title {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 135%;
    letter-spacing: -0.02em;
    text-transform: capitalize;
    padding-bottom: 1px;
  }

  &__clear-wrap{
    padding: 30px 20px 40px;
  }

  &__clear{
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--primary-black);
    border-radius: 50px;
    padding: 13px 26px;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 145%; /* 21.75px */
    text-transform: uppercase;
    color: #FFFFFF;
  }
}

@include respond-down('medium') {
  .sidebar-fieldset {
    display: none;

    &__ninja-button {
      display: none;
    }
  }
}