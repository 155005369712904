.product-base-tabs {
  &__tabs {
    border-bottom: 1px solid var(--grey-stroke-color);
  }

  &__top-wrap {
    display: flex;
  }

  &__line {
    border-bottom: 1px solid var(--border-color);
    flex: auto;
  }
}

@include respond-up('s-large') {
  .product-base-tabs {
    &__item {
      padding: 40px calc(var(--grid-column1) + var(--grid-gap)) 40px 40px;
    }
  }
}

@include respond('medium') {
  .product-base-tabs {
    &__item {
      padding: 30px;
    }
  }
}