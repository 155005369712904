.partners {
  display: flex;

  &__title-wrap {
    display: flex;
    flex-direction: column;
  }

  &__subtitle {
    margin-top: 10px;
  }

  &__link {
    display: flex;
  }

  &__picture {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: 6px;
  }
}

@include respond-up('s-large') {
  .partners {
    align-items: center;
    justify-content: space-between;
    padding: 80px var(--grid-spacer-and-indent) 0;

    &__title-wrap {
      flex: 0 0 calc(var(--grid-column3) - 32px);
    }

    &__slider {
      &.swiper {
        margin: 0;
      }
    }

    &__slider-arrow {
      display: none;
    }

    &__list {
      column-gap: 28px;
    }

    &__item {
      &.swiper-slide {
        width: calc(var(--grid-column2) + var(--grid-gap));
      }
    }

    &__picture {
      height: calc((var(--grid-column2) + var(--grid-gap)) * 141/240);
    }
  }
}

@include respond('medium') {
  .partners {
    padding: 60px var(--grid-spacer-and-indent) 0;

    &__subtitle {
      width: var(--grid-column4);
    }

    &__slider-wrap {
      margin-top: 40px;
      position: relative;
    }

    &__slider-arrow {
      position: absolute;
      top: 50%;
      z-index: 5;

      .slider-arrow {
        margin: 0;
        box-shadow: 0 2px 20px 0 #2C2C2F1A;
      }

      &_left {
        left: 0;
        transform: translate3d(-50%, -50%, 0);
      }

      &_right {
        right: 0;
        transform: translate3d(50%, -50%, 0);
      }
    }

    &__item {
      &.swiper-slide {
        width: var(--grid-column2);
      }
    }

    &__picture {
      height: calc(var(--grid-column2) * 129/220);
    }
  }
}

@include respond-down('medium') {
  .partners {
    flex-direction: column;
    &__link{
      background: #fff;
      border-radius: 6px;
      padding: 0 20px;
    }
  }
}

@include respond-down('small') {
  .partners {
    padding: 40px 0 20px;

    &__title-wrap {
      padding: 0 var(--grid-spacer-and-indent);
    }

    &__slider-wrap {
      margin-top: 30px;
    }

    &__slider-arrow {
      display: none;
    }

    &__slider {
      &.swiper {
        padding: 0 var(--grid-spacer-and-indent);
      }
    }

    &__item {
      &.swiper-slide {
        width: calc(var(--grid-column2) + var(--grid-gap) + 4px);
      }
    }

    &__picture {
      height: calc((var(--grid-column2) + var(--grid-gap) + 4px) * 106/180);
    }
  }
}