.about-page {
  &__main-block-wrap {
    background: var(--primary-black);
  }

  &__why-we-block-wrap {
    background: #EBECEE;
  }

  &__advantages-slider-wrap {
    background: var(--grey-background-color-opacity-50);
  }
}

@include respond-up('s-large') {
  .about-page {
    &__partners-block-wrap {
      background: #fff;
      padding-bottom: 40px;
    }
  }
}

@include respond('medium') {
  .about-page {
    &__partners-block-wrap {
      padding-bottom: 40px;
    }
  }
}

@include respond-down('medium') {
  .about-page {
    &__partners-block-wrap {
      background: #EBECEE;
    }
  }
}

@include respond-down('small') {
  .about-page {
    &__partners-block-wrap {
      padding-bottom: 40px;
    }
  }
}