.advantage-slider {
  &__wrap {
    background: #fff;
    border-radius: 6px;
    overflow: hidden;
  }
}

@include respond-up('s-large') {
  .advantage-slider {
    padding: 80px 0 100px;

    &__wrap {
      margin-top: 40px;
    }
  }
}

@include respond('medium') {
  .advantage-slider {
    padding: 60px 0 80px;

    &__wrap {
      margin-top: 30px;
    }
  }
}

@include respond-down('small') {
  .advantage-slider {
    padding: 40px 0 60px;

    &__wrap {
      margin-top: 20px;
    }
  }
}