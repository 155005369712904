.action-card {
  background-color: #fff;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  height: 100%;

  &__bottom {
    border-top: 1px solid var(--grey-stroke-color);
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex: 0 0 auto;
  }

  &__title {
    //@extend text__
    display: block;
    flex: 0 1 100%;
  }

  &__picture {
    display: flex;
    overflow: hidden;
    width: 100%;
    height: 100%;
    border-radius: 6px;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__image-wrap {
    width: 100%;
    flex: 0 0 auto;
  }

  &__icon {
    display: flex;
    height: max-content;
  }

  &__date {
    color: var(--grey-text);
    position: relative;

    //&::before {
    //  content: '';
    //  position: absolute;
    //  top: 50%;
    //  left: 0;
    //  transform: translate3d(0, -50%, 0);
    //  background-color: var(--primary-color);
    //  border-radius: 100%;
    //}
  }

  &__slash{
    color: var(--primary-red);
    display: inline-block;
    margin-right: 4px;
    font-weight: 600;
  }

  &__button{
    align-self: flex-end;
  }
}

@include respond-up('s-large') {
  .action-card {
    padding: 25px;
    transition: box-shadow .3s;
    &__title {
      //padding: 20px 25px 40px;
    }

    &__bottom {
      //padding: 21px 25px 21px 29px;
    }

    &__image-wrap {
      height: calc((238 / 1500) * 100vw);
      max-height: 298px;
    }

    &__image {
      transition: transform .5s;
    }

    &__icon {
      svg path {
        transition: stroke .5s;
      }
    }

    &__date{
      margin-top: 66px;
    }

    &:hover {
      box-shadow: 0 7px 25px 0 rgba(44, 44, 47, 0.10);
      .action-card {
        &__image {
          transform: scale(1.05);
        }

        &__button{
          opacity: 1;
        }
      }
    }

    &__button{
      opacity: 0;
      transition: opacity .3s, background .3s;
    }
  }
}

@include respond-up('medium') {
  .action-card {
    &__date {
    }

    &__title{
      margin-top: 20px;
    }
  }
}

@include respond('medium') {
  .action-card {
    padding: 20px 20px 30px;

    &__date{
      margin-top: 40px;
    }

    &__image-wrap {
      height: calc((215 / 768) * 100vw);
    }
  }
}

@include respond-down('medium') {
  .action-card {

  }
}

@include respond-down('small') {
  .action-card {
    padding: 20px 20px 26px;
    &__title {
      margin-top: 16px;
    }

    &__bottom {
    }

    &__image-wrap {
      height: calc((211 / 375) * 100vw);
    }

    &__date {
      margin-top: 54px;
    }

    &__icon {
      width: 18px;
      height: 19px;

      svg {
        width: 100%;
        height: 100%;
      }
    }
  }
}