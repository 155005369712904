.warranty{

}

@include respond-up('s-large') {
  .warranty{
    padding-top: 40px;
    padding-bottom: 120px;

    &__head{
      padding-bottom: 60px;
    }

    &__content{
      grid-column: 1 / 10;
    }

    &__return-time{

    }

    &__advantages{
      margin-top: 28px;
    }

    &__return-rules{
      margin-top: 28px;
    }

    &__side{
      grid-column: 10 / 13;
    }
  }
}

@include respond('medium') {
  .warranty{
    padding-top: 30px;
    padding-bottom: 100px;
    &__head{
      padding-bottom: 40px;
    }

    &__content{
      grid-column: 1 / 7;
    }

    &__return-time{
    }

    &__advantages{
      margin-top: 18px;
    }

    &__return-rules{
      margin-top: 18px;
    }

    &__side{
      grid-column: 1 / 7;
    }
  }
}

@include respond-down('medium') {
  .warranty{

  }
}

@include respond-down('small') {
  .warranty{
    padding-top: 20px;
    padding-bottom: 80px;
    &__head{
      padding-bottom: 30px;
    }

    &__content{
      grid-column: 1 / 5;
    }

    &__return-time{

    }

    &__advantages{
      margin-top: 16px;
    }

    &__return-rules{
      margin-top: 16px;
    }

    &__side{
      grid-column: 1 / 5;
    }
  }
}