.favorites-page {
  &__grid{
    display: grid;
    grid-template-columns: repeat(auto-fill,minmax(205px,1fr));
    grid-gap: 14px;
  }

  &__empty{
    margin-top: 40px;
    border-radius: 10px;
    background-color: #fff;
    padding: 50px;
    position: relative;
  }

  &__empty-title{
    @extend h3;
    margin-bottom: 15px;
  }

  &__empty-subtitle{
    margin-bottom: 15px;
    color: var(--grey-text-color);
  }
}

@include respond-up('s-large') {
  .favorites-page {
    padding-bottom: 40px;
    &__grid{
      //padding-bottom: 120px;
    }
  }
}

@include respond-up('medium') {
  .favorites-page {

  }
}

@include respond('medium') {
  .favorites-page {
    padding-bottom: 30px;
    &__grid{
      //padding-bottom: 90px;
    }
  }
}

@include respond-down('medium') {
  .favorites-page {

  }
}

@include respond-down('small') {
  .favorites-page {
    padding-bottom: 40px;
    &__grid{
      grid-template-columns: repeat(auto-fit, minmax(160px, 1fr));
      //padding-bottom: 50px;
    }

    &__empty{
      padding: 30px;
    }
  }
}