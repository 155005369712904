.filter-mobile {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--dark-background-color);
  z-index: 5000;
  display: flex;
  opacity: 0;
  pointer-events: none;

  &._visible {
    opacity: 1;
    pointer-events: auto;
  }

  &__inner {
    position: relative;
    background-color: #FFF;
    display: flex;
    flex-direction: column;
  }

  &__heading {
    background-color: #fff;
    display: flex;
    align-items: center;
    border-bottom: 1px solid var(--border-color);
    flex: 0 0 auto;
  }

  &__close {
    display: flex;
    padding: 15px;
    margin: -15px;
    margin-left: auto;
  }

  &__main {
    height: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
    flex: 0 1 100%;

    &::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 2px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 50px;
      background-color: var(--grey-stroke-color);
      box-shadow: 0 0 1px rgba(255, 255, 255, .5);
    }
  }

  &__switcher-wrap {
    padding: 20px var(--grid-spacer);
    border-bottom: 1px solid var(--grey-stroke-color);
  }

  &__filters-wrap {

  }

  &__filters {
    .filter-fieldset {
      &__field {
        box-shadow: 0 1px 0 0 #CBCCD0;
      }

      .range-field {
        padding: 0;
      }
    }
  }

  &__submit-wrap {
    position: absolute;
    z-index: 50;
    left: var(--grid-spacer);
    right: var(--grid-spacer);
  }

  &__submit,
  &__clear {
    width: 100%;
  }

  &__clear {
    background: #fff;
  }

  &__sort-wrap {
    .filter-fieldset {
      &__field {
        box-shadow: 0 1px 0 0 #CBCCD0;
      }
    }
  }
}

@include respond-up('large') {
  .filter-mobile {

  }
}

@include respond-up('medium') {
  .filter-mobile {

  }
}

@include respond('medium') {
  .filter-mobile {
    &__inner {
      width: 406px;
    }

    &__heading {
      padding: 16px 34px;
    }

    &__clear {
      margin-top: 12px;
    }

    &__submit-wrap {
      bottom: 50px;
    }

    &__main {
      padding-bottom: 186px;
    }

    .filter-fieldset {
      &__field {
        padding: 0 34px;
      }
    }
  }
}

@include respond-down('medium') {
  .filter-mobile {

  }
}

@include respond-down('small') {
  .filter-mobile {
    &__inner {
      width: 100%;
    }

    &__heading {
      padding: 16px var(--grid-spacer);
    }

    &__clear {
      margin-top: 10px;
    }

    &__submit-wrap {
      bottom: 30px;
    }

    &__main {
      padding-bottom: 154px;
    }
  }
}