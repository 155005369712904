.product-wy{
  background: var(--grey-background-color-opacity-50);
  &__item{
    background: #fff;
    border-radius: 6px;
  }

  &__main-title{
    display: block;
  }
}

@include respond-up('s-large') {
  .product-wy{
    &__wrap{
      padding-top: 60px;
      padding-bottom: 120px;
    }

    &__main-title{
      margin-bottom: 40px;
    }

    &__list{
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-column-gap: 19px;
    }
  }
}

@include respond-up('medium') {
  .product-wy{
    padding-top: 40px;
    //padding-bottom: 100px;
    &__item{
      padding: 30px;
      min-height: 282px;
    }

    &__main-title{
      margin-bottom: 30px;
    }

    &__icon{
      margin-bottom: 20px;
    }

    &__title{
      margin-bottom: 10px;
    }
  }
}

@include respond('medium') {
  .product-wy{
    &__list{
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 18px;
    }

    &__wrap{
      padding-bottom: 100px;
    }
  }
}

@include respond-down('small') {
  .product-wy{
    padding-top: 30px;
    padding-bottom: 80px;
    &__main-title{
      margin-bottom: 22px;
    }
    &__list{
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 10px;
    }

    &__item{
      padding: 20px;
    }
  }
}