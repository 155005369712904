.action-page {
  &__interested-title {
    font-style: normal;
    font-weight: 600;
    line-height: 125%;
    letter-spacing: -0.02em;
  }

  &__interested-list {
    display: grid;
    grid-gap: var(--grid-gap);
  }

  &__action-goods {
    background: var(--grey-background-color-opacity-50);
  }
}

@include respond-up('s-large') {
  .action-page {
    &__interested {
      display: none;
    }

    &__action-products{
      &.product-slider {
        margin: 0;
      }
    }

    &__action-goods {
      padding: 60px 0 120px;
    }
  }
}

@include respond-up('medium') {
  .action-page {

  }
}

@include respond('medium') {
  .action-page {
    &__interested-title {
      font-size: 22px;
    }

    &__interested-list {
      grid-template-columns: repeat(2, 1fr);
      margin-top: 28px;
    }

    &__bottom {
      padding: 0 0 100px;
      row-gap: 40px;
    }

    &__action-products{
      &.product-slider {
        margin: 0;
      }
    }

    &__action-goods {
      padding: 40px 0 60px;
    }
  }
}

@include respond-down('medium') {
  .action-page {
    &__interested-item {
      &:nth-child(3) {
        display: none;
      }
    }

    &__bottom {
      display: flex;
      flex-direction: column;
    }
  }
}

@include respond-down('small') {
  .action-page {
    &__interested-title {
      font-size: 20px;
    }

    &__interested-list {
      grid-template-columns: 1fr;
      grid-row-gap: 15px;
      margin-top: 22px;
    }

    &__bottom {
      padding: 0 0 80px;
      row-gap: 30px;
    }

    &__action-goods {
      padding: 30px 0 40px;

      .container_spacer {
        padding: 0;
      }

      .product-slider {
        &__head {
          .container_spacer {
            padding: 0 var(--grid-spacer);
          }
        }

        &__slider {
          &.swiper {
            padding: 0 var(--grid-spacer);
          }
        }

        &__item {
          &:first-child {
            margin-left: 0;
          }
        }
      }
    }
  }
}