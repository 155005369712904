.social-icons {
  display: flex;
  text-align: center;

  &._dark{
    .social-icons{
      &__icon{
        border: 1px solid rgba(255,255,255, 0.3);
        svg{
          path{
            fill: #fff;
          }
        }
      }
    }
  }

  &._grey{
    .social-icons{
      &__icon{
        svg{
          path{
            fill: var(--grey-text);
          }
        }
      }
    }
  }

  &__icon {
    border-radius: 100%;
    border: 1px solid var(--border-color);
    display: flex;
    align-items: center;
    justify-content: center;
    transition: {
      property: background, border-color;
      duration: var(--default-timing);
      timing-function: var(--default-transition-function);
    };

    svg {
      display: flex;
      path {
        transition: fill var(--default-timing) var(--default-transition-function);
      }
    }
  }
}

@include respond-up('s-large') {
  .social-icons {
    &._dark {
      .social-icons {
        &__icon {
          &:hover {
            border-color: var(--primary-red);
          }
        }
      }
    }

    &._dark,
    &._grey {
      .social-icons {
        &__icon {
          &:hover {
            svg{
              path{
                fill: #fff;
              }
            }
          }
        }
      }
    }

    &__icon {
      &:hover {
        background: var(--primary-red);
        border-color: var(--primary-red);
        svg{
          path{
            fill: #fff;
          }
        }
      }
    }
  }
}

@include respond-up('medium') {
  .social-icons {
    &__icon {
      width: 30px;
      height: 30px;
      margin-right: 12px;
      &:last-child{
        margin: unset;
      }
    }
  }
}

@include respond('medium') {
  .social-icons {

  }
}

@include respond-down('medium') {
  .social-icons{

  }
}

@include respond-down('small') {
  .social-icons {
    &__icon {
      width: 28px;
      height: 28px;
      margin-right: 9px;
      &:last-child{
        margin: unset;
      }
    }
  }
}
