.modal-success {
  &__inner {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__title {
    white-space: nowrap;
    text-align: center;
    margin-top: 30px;
  }

  &__description {
    text-align: center;
    color: var(--grey-text);
  }

  &__icon {

  }
}

@include respond-up('s-large') {
  .modal-success {
    padding: 60px 125px 118px;

    &__description {
      margin-top: 10px;
    }
  }
}

@include respond('medium') {
  .modal-success {
    padding: 75px 163px 180px;
  }
}

@include respond-down('medium') {
  .modal-success {
    &__description {
      margin-top: 16px;
    }
  }
}