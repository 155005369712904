.payment-delivery-recall {
  background: var(--primary-black);
  border-radius: 6px;
  position: relative;

  &:before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url('../images/base/payment-delivery-form-tyre-desk.png');
    background-position: left top;
    background-repeat: no-repeat;
    z-index: 10;
    background-size: cover;
    border-radius: 6px;
  }

  &__wrap {
    z-index: 20;
    position: relative;
  }

  &__title,
  &__subtitle {
    color: #fff;
  }
}

@include respond-up('s-large') {
  .payment-delivery-recall {
    position: sticky;
    top: 100px;

    &__bottom-wrap {
      margin-top: 8px;
    }

    &__button-wrap {
      margin-top: 40px;
    }

    &__button {
      width: 100%;
    }
  }
}

@include respond-up('medium') {
  .payment-delivery-recall {
    padding: 30px 30px 40px;
  }
}

@include respond('medium') {
  .payment-delivery-recall {
    &:before {
      width: 381px;
      height: 100%;
    }

    &__bottom-wrap {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      margin-top: 10px;
    }

    &__subtitle {
      width: calc(var(--grid-column3) - 30px);
    }

    &__button {
      &.button {
        padding: 13px 57px;
      }
    }
  }
}

@include respond-down('small') {
  .payment-delivery-recall {
    padding: 20px 20px 40px;

    &__title {
      line-height: 140%;
      font-size: 24px;
    }

    &__bottom-wrap {
      margin-top: 8px;
    }

    &__button-wrap {
      margin-top: 30px;
    }

    &__button {
      &.button {
        padding: 12px 50px;
      }
    }
  }
}