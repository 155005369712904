.news-item-page {
  &__wrap {
    border-radius: 6px;
    background: #fff;
  }
}

@include respond-up('s-large') {
  .news-item-page {
    padding: 40px 0 120px;

    &__wrap {
      padding: 30px 40px 80px;
    }
  }
}

@include respond('medium') {
  .news-item-page {
    padding: 30px 0 100px;

    &__wrap {
      padding: 30px 30px 60px;
    }
  }
}

@include respond-down('small') {
  .news-item-page {
    padding: 20px 0 80px;

    &__wrap {
      padding: 20px 20px 30px;
    }
  }
}