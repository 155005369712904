.catalog-selection-card {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  &::before {
    content: '';
    position: absolute;
    z-index: 10;
    left: 0;
    bottom: 0;
    width: 100%;
  }

  &__title {
    position: relative;
    z-index: 20;
    color: #FFFFFF;
  }

  &__icons {
    display: flex;
    justify-content: space-between;
    position: relative;
    z-index: 20;
  }

  &__icon-wrap {
    display: flex;
    align-items: flex-end;
  }

  &__circle-wrap {
    display: flex;
  }

  &__circle {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--black-color);
    border-radius: 100%;
    width: 32px;
    height: 32px;
  }

  &__icon {
    display: flex;
  }

  &__arrow-wrap {
    width: 8px;
    height: 14px;
    display: flex;
    justify-content: center;
  }
}

@include respond-up('s-large') {
  .catalog-selection-card {
    width: 368px;
  }
}

@include respond-up('medium') {
  .catalog-selection-card {
    &__button{
      width: 239px;
    }
  }
}

@include respond('medium') {
  .catalog-selection-card {

  }
}

@include respond-down('medium') {
  .catalog-selection-card {

  }
}

@include respond-down('small') {
  .catalog-selection-card {
    &__button{
      width: 204px;
    }
  }
}