.model-page-top-params {
  background-color: #fff;

  &__item {
    display: flex;
    align-items: center;
    padding: 8px 0;
    border-bottom: 1px solid var(--grey-stroke-color);

    &:first-child {
      border-top: 1px solid var(--grey-stroke-color);
    }
  }

  &__item-name,
  &__item-value {
    flex: 0 0 50%;
  }

  &__item-name {
    display: flex;
    align-items: center;
  }

  &__item-name-title {
    color: var(--grey-text-color);
  }

  &__item-help {
    position: relative;
    display: flex;
    margin-left: 8px;

    &::before {
      content: '';
      position: absolute;
      z-index: 50;
      bottom: calc(100% + 5px);
      left: 2px;
      background-color: #fff;
      width: 10px;
      height: 10px;
      transform: rotate(45deg);
      opacity: 0;
      pointer-events: none;
      transition: opacity .3s;
    }

    &:hover {
      &::before,
      .model-page-top-params__item-help-title {
        opacity: 1;
        pointer-events: auto;
      }
    }
  }

  &__item-help-icon {
    display: flex;
  }

  &__item-help-title {
    position: absolute;
    z-index: 25;
    bottom: 24px;
    left: 50%;
    transform: translate3d(-50%, 0, 0);
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 120%;
    letter-spacing: -0.02em;
    color: var(--grey-text-color);
    background-color: #fff;
    filter: drop-shadow(0px 0px 20px rgba(45, 41, 40, 0.1));
    padding: 14px;
    transition: opacity .3s;
    max-width: 214px;
    width: max-content;
    opacity: 0;
    pointer-events: none;
  }

  &__info {
    display: flex;
    align-items: center;
  }

  &__info-title {
    color: var(--grey-text-color);
  }
}

@include respond-up('large') {
  .model-page-top-params {
    padding: 24px 30px 46px;

    &__info {
      background-color: var(--grey-light-color);
      padding: 12px 30px 12px 35px;
      margin-top: 25px;
    }

    &__info-title {
      margin-left: 31px;
    }
  }
}

@include respond('medium') {
  .model-page-top-params {
    padding: 12px 19px 15px;
  }
}

@include respond-down('medium') {
  .model-page-top-params {
    height: 100%;
    display: flex;

    &__list {
      display: none;
    }

    &__info-title {
      margin-left: 18px;
    }
  }
}

@include respond-down('small') {
  .model-page-top-params {
    padding: 14px 18px 18px;
  }
}