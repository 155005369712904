.catalog-category-card {
  background-color: var(--grey-text);
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: space-between;
  border-radius: 6px;

  &__icon-wrap {
    display: flex;
  }

  &__image-wrap {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  &__title {
    display: block;
    color: #FFFFFF;
  }

  &__picture {
    position: absolute;
    bottom: 0;
    right: 0;
  }

  &__image {
    bottom: 0;
    right: 0;
    position: absolute;
    object-fit: contain;
  }

  &__arrow-wrap {
    width: 8px;
    height: 14px;
    display: flex;
    justify-content: center;
  }
}

@include respond-up('s-large') {
  .catalog-category-card {
    padding: 30px 30px 35px 40px;
    height: 420px;
    overflow: hidden;

    &:hover{
      .catalog-category-card{
        &__picture{
          transform: scale(1.05);
        }

        &__icon-wrap{
          opacity: 1;
        }
      }
    }

    &__picture {
      width: 333px;
      height: 321px;
      transition: transform 1s;
    }

    &__icon-wrap {
      opacity: 0;
      transition: opacity .5s;
    }

    //&__icon {
    //  width: 44px;
    //  height: 44px;
    //  transition: background-color .4s;
    //}
    //
    //&:hover {
    //  .catalog-category-card {
    //    &__icon {
    //      background-color: var(--black-color);
    //    }
    //  }
    //}
  }
}

@include respond-up('medium') {
  .catalog-category-card {

  }
}

@include respond('medium') {
  .catalog-category-card {
    height: 240px;
    padding: 22px 22px 25px 25px;

    &__picture {
      height: 240px;
      width: 100%;
    }

    &__icon {
      width: 40px;
      height: 40px;
    }
  }
}

@include respond-down('medium') {
  .catalog-category-card {

  }
}

@include respond-down('small') {
  .catalog-category-card {
    height: 170px;
    padding: 20px 20px 22px 22px;

    &__picture {
      //width: calc((218 / 375) * 100vw);
      //height: calc((184 / 375) * 100vw);
      height: 170px;
      width: 100%;
    }

    &__icon {
      width: 34px;
      height: 34px;
    }
  }
}