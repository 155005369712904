.article-content {
  &__list {
    display: flex;
    flex-direction: column;
  }

  &__text-wrap {
    > *:first-child {
      margin-top: 0 !important;
    }
  }
}

@include respond-up('s-large') {
  .article-content {
    &_action {
      .article-content {
        &__list {
          row-gap: 60px;
          width: var(--grid-column7);
          margin-top: 60px;
        }

        &__slider-wrap {
          margin-top: 60px;
        }
      }
    }

    &_news {
      .article-content {
        &__list {
          row-gap: 40px;
        }

        &__text-wrap,
        &__share-wrap {
          width: var(--grid-column7);
        }

        &__slider-wrap {
          margin-top: 40px;
        }
      }
    }

    &__text-wrap {
      ul, ol {
        &:not(:first-child) {
          margin-top: 22px;
        }
      }
    }

    &__share-wrap {
      margin-top: 60px;
    }
  }
}

@include respond-up('medium') {
  .article-content {
    &__text-wrap {
      p {
        &:not(:first-child) {
          margin-top: 20px;
        }
      }

      br {
        margin-top: 20px;
      }
    }

    &__image-description {
      margin-top: 20px;
    }
  }
}

@include respond('medium') {
  .article-content {
    &_action {
      .article-content {
        &__list {
          margin-top: 40px;
        }
      }
    }

    &_news {
      .article-content {
        &__list {
          margin-top: 50px;
        }
      }
    }

    &__list {
      row-gap: 40px;
    }

    &__slider-wrap {
      margin-top: 30px;
    }

    &__text-wrap {
      ul, ol {
        &:not(:first-child) {
          margin-top: 21px;
        }
      }
    }

    &__share-wrap {
      margin-top: 50px;
    }
  }
}

@include respond-down('small') {
  .article-content {
    &_action {
      .article-content {
        &__list {
          margin-top: 30px;
        }
      }
    }

    &_news {
      .article-content {
        &__list {
          margin-top: 20px;
        }
      }
    }

    &__list {
      row-gap: 30px;
    }

    &__slider-wrap {
      margin-top: 20px;
    }

    &__text-wrap {
      p {
        &:not(:first-child) {
          margin-top: 16px;
        }
      }

      br {
        margin-top: 16px;
      }

      ul, ol {
        &:not(:first-child) {
          margin-top: 16px;
        }
      }
    }

    &__share-wrap {
      margin-top: 40px;
    }

    &__image-description {
      margin-top: 10px;
    }
  }
}