.cart-result {
  z-index: 200;

  &__content, &__help{
    background-color: #FFFFFF;
  }

  &__info-name, &__help-subtitle{
    color: var(--grey-text);
  }
}

@include respond-up('s-large') {
  .cart-result {
    position: sticky;
    top: 87px + 14px;
    &__content, &__help{
      border-radius: 6px;
    }

    &__content{
      margin-bottom: 10px;
      padding: 30px 70px 40px 30px;
    }

    &__help{
      padding: 24px 30px 40px;
    }

    &__info-item{
      &:first-child{
        margin-bottom: 30px;
      }
    }

    &__help-button{
      margin-top: 20px;
    }
  }
}

@include respond-up('medium') {
  .cart-result {
    &__info-name{
      margin-bottom: 2px;
    }
  }
}

@include respond('medium') {
  .cart-result {
    &__content{
      padding: 30px;
    }

    &__help{
      padding: 20px 30px 30px;
    }

    &__info, &__help{
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-column-gap: var(--grid-gap);
    }
  }
}

@include respond-down('medium') {
  .cart-result {
    &__content{
      border-radius: 6px 6px 0 0;
      border-bottom: 1px solid var(--border-color);
    }

    &__help{
      border-radius: 0 0 6px 6px;
    }
  }
}

@include respond-down('small') {
  .cart-result {
    &__content, &__help{
      padding: 20px 20px 40px;
    }

    &__info-name{
      margin-bottom: 4px;
    }

    &__info-item{
      &:first-child{
        margin-bottom: 20px;
      }
    }

    &__help-button{
      display: flex;
      margin-top: 20px;
    }
  }
}