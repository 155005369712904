.breadcrumbs {
  &__list {
    display: flex;
    align-items: center;
  }

  &__item {
    display: block;
    &:last-child{
      .breadcrumbs{
        &__name{
          color: var(--primary-black);
        }
      }
    }
  }

  &__name{
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 16.8px */
    font-size: 14px;
    color: var(--grey-text);
  }

  &__item_delimiter {
    min-width: 9px;
    height: 1px;
    margin: 0 8px;
    background: var(--primary-red);
    padding: unset !important;
  }

  &__link,
  &__item_delimiter-icon {
    display: flex;
  }
}

@include respond-up("s-large") {
  .breadcrumbs {

    &__list {
      margin: -4px;
    }

    &__item {
      padding: 4px;
    }

    &__link {
      transition: color .4s;
      padding: 4px;
      margin: -4px;

      &:hover {
      }
    }
  }
}

@include respond-up('medium') {
 .breadcrumbs{
    &__name{
    }
 }
}

@include respond("medium") {
  .breadcrumbs {
  }
}

@include respond-down("medium") {
  .breadcrumbs {
    &__list {
      margin: -3px;
      overflow: scroll;
      &::-webkit-scrollbar{
        display: none;
      }
    }

    &__item {
      padding: 3px;
      white-space: nowrap;

      //&:last-child {
      //  white-space: nowrap;
      //  overflow: hidden;
      //  text-overflow: ellipsis;
      //}
    }

    &__link {
      padding: 3px;
      margin: -3px;
    }
  }
}

@include respond-down("small") {
  .breadcrumbs {
  }
}
