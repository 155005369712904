.checkbox-list-openable {
  border-bottom: 1px solid var(--grey-stroke-color);

  &__content {
    &._searchInProgress {
      height: fit-content !important;
    }
  }

  &__element {
    &._opened {
      .checkbox-list-openable {
        &__show-all-icon {
          &::after {
            transform: translate3d(-50%, -50%, 0) rotate(180deg);
          }
        }

        &__show-title {
          display: none;
        }

        &__hide-title {
          display: block;
        }
      }
    }
  }

  &__block {
    height: 114px;
    overflow: hidden;
    transition: height .5s;

    &._searchable {
      height: 159px;
    }

    &._fit-content {
      height: fit-content;

      .checkbox-list-openable__values {
        padding-bottom: 15px;
      }
    }

    &._searchInProgress {
      height: fit-content !important;

      .checkbox-list-openable__values {
        padding-bottom: 11px;
      }
    }
  }

  &__link {
    &._disabled {
      pointer-events: none;
    }
  }

  &__link-icon {

  }

  &__show-all {
    align-items: center;
    padding: 6px 0 15px;
    font-style: normal;
    font-size: 15px;
    font-weight: 600;
    line-height: 140%; /* 21px */
    color: var(--primary-red);
    display: none;

    &._visible {
      display: flex;
      .checkbox-list-openable{
        &__show-all-icon{
          &:after{
            right: -22px;
          }
        }
      }
    }
  }

  &__show-all-icon {
    position: relative;
    width: 0;
    height: 0;

    &::after {
      content: '';
      position: absolute;
      border-style: solid;
      border-width: 5px 5px 0 5px;
      border-color: var(--primary-red) transparent transparent transparent;
      transform: rotate(0deg);
      top: calc(50% - 1px);
      right: -14px;
    }
  }

  &__show-title,
  &__hide-title {
    padding-top: 1px;
  }

  &__hide-title {
    display: none;
  }

  &__input {
    display: none;

    &:checked + .checkbox-list-openable__item-label {
      background-color: var(--color-background);

      .checkbox-list-openable {
        &__choice-icon {
          border: 1px solid var(--border-color);

          svg {
            opacity: 1;
          }
        }
      }
    }

    + .checkbox-list-openable__item-label {
      cursor: pointer;
      display: flex;
      align-items: center;
      padding: 4px 0;
      margin: 0;
    }
  }

  &__choice-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 19px;
    height: 19px;
    margin-bottom: 2px; // Шрифт кривой
    border: 1px solid var(--border-color);
    border-radius: 4px;
    background-color: #fff;
    transition: background-color .2s, border-color .2s;
    transition-timing-function: var(--default-timing-function);

    svg {
      transition: opacity .2s var(--default-timing-function);
      opacity: 0;
    }
  }

  &__search-no-result {
    //@extend .text__small;
    color: var(--black-color);
    padding: 4px 10px 0;
  }

  &__choice-name {
    //@extend .text__small;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 21px */
    color: var(--primary-black);
    margin-left: 8px;
  }

  &__search-wrap {
    padding-bottom: 12px;
  }

  &__search {
    position: relative;
  }

  &__search-input {
    //@extend .text__small;
    background-color: #fff;
    border: 1px solid var(--border-color);
    border-radius: 4px;
    padding: 6px 33px 6px 10px;
    width: 100%;
  }

  &__search-icon {
    display: flex;
    position: absolute;
    pointer-events: none;
    top: 50%;
    right: 8px;
    transform: translate3d(0, -50%, 0);
  }
}