.payment-delivery-page {

}

@include respond-up('s-large') {
  .payment-delivery-page {
    padding-bottom: 120px;
    display: grid;
    grid-template-columns: var(--grid-column9) var(--grid-column3);
    grid-column-gap: var(--grid-gap);
    position: relative;

    &__content-wrap {
      grid-column: 1/2;
    }

    &__recall-wrap {
      grid-column: 2/3;
      position: relative;
    }
  }
}

@include respond-up('medium') {
  .payment-delivery-page {

  }
}

@include respond('medium') {
  .payment-delivery-page {
    padding-bottom: 100px;

    &__recall-wrap {
      margin-top: 40px;
    }
  }
}

@include respond-down('medium') {
  .payment-delivery-page {

  }
}

@include respond-down('small') {
  .payment-delivery-page {
    padding-bottom: 46px;

    &__recall-wrap {
      margin-top: 30px;
    }
  }
}