.warranty-head {
  display: flex;
  background: white;
  border-radius: 6px;

  &__info {
    display: flex;
  }

  &__text-wrap {
    display: flex;
    flex-direction: column;
  }

  &__image-container {
    border-radius: 6px;
    overflow: hidden;
  }

  &__picture {
    display: flex;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

@include respond-up('s-large') {
  .warranty-head {
    justify-content: space-between;

    &__text-wrap {
      justify-content: space-between;
      flex: 0 0 var(--grid-column6);
      padding: 40px calc(var(--grid-column1) + var(--grid-gap)) 50px 40px;
    }

    &__image-container {
      margin: 40px 40px 50px 0;
      flex: 0 0 var(--grid-column6);
    }

    &__title {
      margin-bottom: 30px;
    }
  }
}

@include respond-up('medium') {
  .warranty-head {
    &__info {
      margin-top: 40px;
    }

    &__info-icon {
      margin-right: 20px;
    }
  }
}

@include respond('medium') {
  .warranty-head {
    &__text-wrap {
      padding: 30px 30px 0 30px;
    }

    &__image-container {
      margin: 50px 30px 30px;
    }
  }
}

@include respond-down('medium') {
  .warranty-head {
    flex-direction: column;

    &__title {
      margin-bottom: 20px;
    }
  }
}

@include respond-down('small') {
  .warranty-head {
    &__subtitle {
      margin-bottom: 20px;
    }

    &__info {
      flex-direction: column;
    }

    &__text-wrap {
      padding: 20px 20px 0 20px;
    }

    &__image-container {
      margin: 20px;
    }

    &__info-icon {
      margin-bottom: 18px;
    }
  }
}