.actions-page {
  &__list {
    display: grid;
    grid-gap: var(--grid-gap);
  }
}

@include respond-up('s-large') {
  .actions-page {
    padding-bottom: 120px;

    &__list {
      grid-template-columns: repeat(3, 1fr);
    }
  }
}

@include respond-up('medium') {
  .actions-page {

  }
}

@include respond('medium') {
  .actions-page {
    padding-bottom: 90px;

    &__list {
      grid-template-columns: repeat(2, 1fr);
      grid-row-gap: 24px;
    }
  }
}

@include respond-down('medium') {
  .actions-page {

  }
}

@include respond-down('small') {
  .actions-page {
    padding-bottom: 50px;

    &__list {
      grid-template-columns: 1fr;
      grid-row-gap: 15px;
    }
  }
}