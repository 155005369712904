.produce-slider {
  &__item {
    background: var(--grey-text);
    border-radius: 6px;
    overflow: hidden;
    display: flex;
    justify-content: flex-end;

    &:hover {
      .produce-slider {
        &__content-wrap {
          opacity: 1;
          pointer-events: auto;
        }
      }
    }

    &:before {
      position: absolute;
      content: '';
      width: 708px;
      height: 728px;
      background: var(--primary-black);
      opacity: 0.6;
      filter: blur(74.3px);
      top: 0;
      z-index: 10;
      border-radius: 50%;
    }
  }

  &__image-wrap,
  &__slide-title-wrap,
  &__content-wrap {
    z-index: 20;
  }

  &__slide-title-wrap {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__slide-title,
  &__slide-description {
    color: #fff;
  }

  &__slide-title-wrap {
    align-items: flex-end;
  }

  &__button {
    display: flex;
    flex: 0 0 38px;
    height: 38px;
    border-radius: 50%;
    background: var(--primary-red);
    position: relative;

    &:before,
    &:after {
      position: absolute;
      content: '';
      top: 50%;
      left: 50%;
      width: 20px;
      border-bottom: 2px solid #fff;
      transform-origin: center;
      transform: translate3d(-50%, -50%, 0);
    }

    &:after {
      transform: translate3d(-50%, -50%, 0) rotate(90deg);
    }
  }

  &__content-wrap {
    position: absolute;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: var(--primary-black);
    transition: opacity var(--default-timing) var(--default-transition-function);
    opacity: 0;
    pointer-events: none;
  }

  &__image-wrap {
    position: relative;
  }

  &__picture {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  &__image {
    width: 100%;
    height: 100%;
  }
}

@include respond-up('s-large') {
  .produce-slider {
    &__slider-wrap {
      margin-top: 40px;
    }

    &__item {
      &.swiper-slide {
        width: var(--grid-column4);
      }

      &:before {
        left: var(--grid-column);
      }
    }

    &__slide-title-wrap {
      left: 30px;
      right: 30px;
      bottom: 40px;
    }

    &__image-wrap {
      width: calc(var(--grid-column3) + var(--grid-gap));
    }

    &__picture {
      height: calc((var(--grid-column3) + var(--grid-gap)) * 505/375);
    }

    &__content-wrap {
      padding: 40px 50px 40px 30px;
    }

    &__image {
      object-fit: cover;
    }

    &_image-full {
      .produce-slider {
        &__image-wrap {
          width: var(--grid-column4);
        }

        &__picture {
          height: calc(var(--grid-column4) * 505/448);
        }
      }
    }
  }
}

@include respond-up('medium') {
  .produce-slider {
    padding: 0 var(--grid-spacer-and-indent);

    &__top {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &__slide-description {
      margin-top: 20px;
    }
  }
}

@include respond('medium') {
  .produce-slider {
    &__slider-wrap {
      margin-top: 30px;
    }

    &__item {
      &.swiper-slide {
        width: var(--grid-column3);
      }
    }

    &__slide-title-wrap {
      left: 30px;
      right: 30px;
      bottom: 30px;
    }

    &__image-wrap {
      width: calc(var(--grid-column3) - 41px);
    }

    &__picture {
      height: calc((var(--grid-column3) - 41px) * 505/298);
    }

    &__content-wrap {
      padding: 40px 30px;
    }

    &_image-full {
      .produce-slider {
        &__image-wrap {
          width: var(--grid-column3);
        }

        &__picture {
          height: calc(var(--grid-column3) * 505/339);
        }
      }
    }
  }
}

@include respond-down('medium') {
  .produce-slider {
    &__item {
      &:before {
        left: 41px;
      }
    }

    &__image {
      object-fit: contain;
    }

    &_image-full {
      .produce-slider {
        &__image {
          object-fit: cover;
        }
      }
    }
  }
}

@include respond-down('small') {
  .produce-slider {
    &__top {
      padding: 0 var(--grid-spacer);
    }

    &__arrows {
      display: none;
    }

    &__slider-wrap {
      margin-top: 24px;
    }

    &__slider {
      &.swiper {
        padding: 0 var(--grid-spacer-and-indent);
      }
    }

    &__item {
      &.swiper-slide {
        width: calc(var(--grid-column4) - 15px);
      }
    }

    &__slide-title-wrap {
      left: 20px;
      right: 20px;
      bottom: 30px;
    }

    &__image-wrap {
      width: calc(var(--grid-column4) - 56px);
    }

    &__picture {
      height: calc((var(--grid-column4) - 56px) * 480/279);
    }

    &__content-wrap {
      padding: 30px 20px;
    }

    &__slide-title {
      font-size: 20px;
    }

    &__slide-description {
      margin-top: 16px;
    }

    &_image-full {
      .produce-slider {
        &__image-wrap {
          width: calc(var(--grid-column4) - 15px);
        }

        &__picture {
          height: calc((var(--grid-column4) - 15px) * 480/320);
        }
      }
    }
  }
}