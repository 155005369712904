.advantages-slider {
  display: flex;

  &__content-wrapper {
    display: flex;
  }

  &__images-wrapper {
    display: flex;
    justify-content: center;
    position: relative;
  }

  &__images-slide {
    overflow: hidden;
  }

  &__picture-wrap {
    position: relative;
    display: flex;
    width: 100%;
    height: 100%;
  }

  &__picture {
    display: flex;
    width: 100%;
    height: 100%;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__text-slider {
    position: relative;
  }

  &__text-slider,
  &__text-slider-list {
    width: 100%;
    height: 100%;
  }

  &__text-slide {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: fit-content;
    z-index: 5;
    overflow: hidden;
    opacity: 0;
    transform: translate3d(0, 20px, 0);

    &._active {
      z-index: 10;
      opacity: 1;
      transition-duration: 1s;
      transform: translate3d(0, 0, 0);
    }

    &._out-next-act,
    &._out-prev-act {
      transition-duration: 1s;
      transform: translate3d(0, -20px, 0);
    }

    &._in-next-act {
      transition-delay: .4s;
    }
  }

  &__pagination {
    position: absolute;
    right: 0;
    border-radius: 6px 0 0 6px;
    background: var(--primary-red);
    z-index: 5;
    color: #fff;
    display: flex;
  }
}

@include respond-up('s-large') {
  .advantages-slider {
    &__content-wrapper {
      width: var(--grid-column5);
      height: auto;
      padding: 100px 0 60px 80px;
    }

    &__description {
      margin-top: 20px;
    }

    &__images-slider {
      max-width: var(--grid-column6);
    }

    &__images-slider {
      height: calc(var(--grid-column6) * 478/686);
    }

    &__pagination {
      bottom: 62px;
    }
  }
}

@include respond-up('medium') {
  .advantages-slider {
    justify-content: space-between;

    &__content-wrapper {
      flex-direction: column;
    }

    &__arrows {
      margin-top: 30px;
    }

    &__pagination {
      padding: 10px 70px 10px 22px;
    }
  }
}

@include respond('medium') {
  .advantages-slider {
    &__content-wrapper {
      width: var(--grid-column3);
      height: auto;
      padding: 30px 12px 30px 30px;
    }

    &__title-inner {
      font-size: 22px;
    }

    &__description {
      margin-top: 16px;
    }

    &__images-slider {
      max-width: var(--grid-column3);
    }

    &__images-slider {
      height: calc(var(--grid-column3) * 456/339);
    }

    &__pagination {
      bottom: 30px;
    }
  }
}

@include respond-down('small') {
  .advantages-slider {
    flex-direction: column-reverse;

    &__content-wrapper {
      width: var(--grid-column4);
      height: auto;
      padding: 30px 20px 40px;
      position: relative;
    }

    &__arrows {
      position: absolute;
      bottom: calc(100% + 20px);
      z-index: 5;
    }

    &__title-inner {
      padding-right: 10px;
    }

    &__description {
      margin-top: 12px;
    }

    &__text-slider-list {
      display: grid;
      grid-template-columns: calc(var(--grid-column4) - 40px);
    }

    &__text-slide {
      grid-row: 1;
      grid-column: 1;
      position: static;
    }

    &__images-slider {
      height: calc(var(--grid-column4) * 280/335);
    }

    &__pagination {
      padding: 8px 30px 8px 16px;
      bottom: 20px;
    }
  }
}