.about-block {
  border-top: 1px solid rgba(255, 255, 255, .4);

  &__title {
    margin-top: 40px;
  }

  &__text-wrap,
  &__list {
    display: flex;
    flex-direction: column;
  }

  &__logo-wrap,
  &__item-icon {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__logo-wrap {
    svg {
      width: 100%;
      height: 100%;
    }
  }

  &__item {
    display: flex;
    width: var(--grid-column4);
  }

  &__item-icon {
    border-radius: 50%;
    background: var(--primary-red);
  }

  &__title,
  &__item-text,
  &__quote {
    color: #fff;
  }

  &__quote {
    border-left: 2px solid var(--primary-red);
  }

  &__picture {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 6px;
  }
}

@include respond-up('s-large') {
  .about-block {
    margin-top: 100px;
    padding-top: 80px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: var(--grid-gap);

    &__text-wrap {
      grid-column: 1/2;
    }

    &__image-wrap {
      grid-column: 2/3;
    }

    &__logo-wrap {
      width: 208px;
      height: 50px;
    }

    &__quote {
      margin-top: 50px;
    }

    &__picture {
      height: calc(var(--grid-column6) * 548/686);
      max-height: 548px; // дабы не растягивало блок, поставим ему ограничение
    }
  }
}

@include respond-up('medium') {
  .about-block {
    &__list {
      row-gap: 30px;
      margin-top: 40px;
    }

    &__item {
      column-gap: 20px;
      align-items: center;
    }

    &__item-icon {
      flex: 0 0 40px;
      height: 40px;

      svg {
        width: 24px;
        height: 24px;
      }
    }

    &__quote {
      padding: 10px 0 10px 20px;
    }
  }
}

@include respond('medium') {
  .about-block {
    margin-top: 80px;
    row-gap: 50px;

    &__logo-wrap {
      width: 167px;
      height: 40px;
    }

    &__quote {
      margin-top: 40px;
    }

    &__picture {
      height: calc(var(--grid-column6) * 410/696);
    }
  }
}

@include respond-down('medium') {
  .about-block {
    display: flex;
    flex-direction: column;
    padding-top: 40px;
  }
}

@include respond-down('small') {
  .about-block {
    margin-top: 60px;
    row-gap: 40px;

    &__logo-wrap {
      width: 125px;
      height: 30px;
    }

    &__list {
      row-gap: 24px;
      margin-top: 30px;
    }

    &__item {
      column-gap: 16px;
      align-items: flex-start;
    }

    &__item-icon {
      flex: 0 0 36px;
      height: 36px;

      svg {
        width: 21px;
        height: 21px;
      }
    }

    &__quote {
      margin-top: 30px;
      padding: 8px 0 8px 16px;
    }

    &__picture {
      height: calc(var(--grid-column4) * 197/355);
    }
  }
}