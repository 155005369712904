.index-catalog {
  &__categories-list {
    display: flex;
  }

  &__category-item {
    background: var(--primary-black);
    border-radius: 6px;
  }

  &__category-link {
    display: flex;
    width: 100%;
    height: 100%;
    position: relative;
    justify-content: flex-end;
  }

  &__top-wrap {
    position: absolute;
    display: flex;
    justify-content: space-between;
  }

  &__title {
    color: #fff;
  }

  &__picture {
    display: flex;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: 6px;
  }
}

@include respond-up('s-large') {
  .index-catalog {
    &__categories-list {
      column-gap: var(--grid-gap);
    }

    &__category-item {
      overflow: hidden;

      &_tyres {
        flex: 0 0 var(--grid-column7);
      }

      &_wheels {
        flex: 0 0 var(--grid-column5);
      }
    }

    &__category-link {
      &:before {
        height: 190px;
      }

      &:hover {
        .index-catalog {
          &__arrow-wrap {
            opacity: 1;
          }

          &__picture {
            transform: scale(1.05);
          }
        }
      }
    }

    &__arrow-wrap {
      transition: opacity var(--default-timing) var(--default-transition-function);
      opacity: 0;
    }

    &__picture {
      max-height: 430px;
      transition: transform .6s var(--default-transition-function);
    }

    &__link-item {
      &:hover {
        border-color: #fff;
        background: #fff;

        .index-catalog {
          &__link-text {
            color: var(--primary-black);
          }
        }
      }
    }
  }
}

@include respond-up('medium') {
  .index-catalog {
    &__category-item {
      position: relative;
    }

    &__category-link {
      &:before {
        position: absolute;
        content: '';
        left: 0;
        right: 0;
        bottom: 0;
        background: linear-gradient(180deg, rgba(44, 44, 47, 0) 4.51%, #0F0F11 102.5%);
        border-radius: 6px;
        z-index: 5;
      }
    }

    &__top-wrap {
      align-items: center;
      top: 40px;
      left: 40px;
      width: calc(100% - 80px);
      z-index: 20;
    }

    &__links-wrap {
      position: absolute;
      left: 40px;
      bottom: 50px;
      z-index: 20;
    }

    &__link-item {
      border-color: #fff;
    }

    &__link {
      display: flex;
    }

    &__link-text {
      color: #fff;
    }

    &__image-wrap {
      z-index: 10;
    }
  }
}

@include respond('medium') {
  .index-catalog {
    &__category-link {
      &:before {
        height: 180px;
      }
    }

    &__picture {
      max-height: 370px;
    }
  }
}

@include respond-down('medium') {
  .index-catalog {
    &__categories-list {
      flex-direction: column;
      row-gap: var(--grid-gap);
    }
  }
}

@include respond-down('small') {
  .index-catalog {
    &__top-wrap {
      flex-direction: column;
      height: calc(100% - 50px);
      top: 20px;
      left: 30px;
    }

    &__picture {
      max-height: 220px;
    }

    &__links-wrap {
      display: none;
    }
  }
}