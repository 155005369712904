.pagination-endless{
  position: relative;
  max-width: 135px;
  &__title{
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 145%; /* 21.75px */
    text-transform: uppercase;
    color: var(--primary-red);
  }

  &__button{
    &:before{
      content: '';
      position: absolute;
      right: 0;
      top: calc(50% - 4px);
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 5px 5px 0 5px;
      border-color: #D7262D transparent transparent transparent;
      transform: rotate(0deg);
    }
  }
}

@include respond-up('s-large') {
  .pagination-endless{

  }
}

@include respond('medium') {
  .pagination-endless{

  }
}

@include respond-down('small') {
  .pagination-endless{
    max-width: 120px;
    &__title{
      font-size: 13px;
    }

    &:before{
      top: calc(50% - 3px);
      border-width: 4px 4px 0 4px;
      border-color: #D7262D transparent transparent transparent;
      transform: rotate(0deg);
    }
  }
}