.error-page {
  background: var(--primary-black);
  border-bottom: 1px solid var(--grey-text);

  &__wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
  }

  &__text-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    left: 50%;
    transform: translate3d(-50%, 0, 0);
  }

  &__title,
  &__description {
    color: #fff;
  }

  &__description {
    text-align: center;
    margin-top: 10px;
  }

  &__button {
    margin-top: 40px;
  }

  &__code-icon {
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      width: 100%;
      height: 100%;
    }
  }

  &__picture {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 6px;
  }
}

@include respond-up('s-large') {
  .error-page {
    padding: 60px 0 100px;

    &__text-wrap {
      top: calc(104/1600 * 100vw);
    }

    &__title {
      margin-top: 50px;
    }

    &__code-icon {
      width: 266px;
      height: 96px;
    }

    &__description {
      width: calc(372/1600 * 100vw);
    }

    &__picture {
      height: 528px;
    }
  }
}

@include respond-up('medium') {
  .error-page {

  }
}

@include respond('medium') {
  .error-page {
    padding: 40px 0 80px;

    &__text-wrap {
      top: calc(70/768 * 100vw);
    }

    &__code-icon {
      width: 220px;
      height: 80px;
    }

    &__description {
      width: calc(372/768 * 100vw);
    }

    &__picture {
      height: 462px;
    }
  }
}

@include respond-down('medium') {
  .error-page {
    border-top: 1px solid var(--grey-text);
    &__title {
      margin-top: 40px;
    }
  }
}

@include respond-down('small') {
  .error-page {
    padding: 30px 0 60px;

    &__text-wrap {
      top: calc(80/375 * 100vw);
    }

    &__code-icon {
      width: 159px;
      height: 57px;
    }

    &__description {
      width: calc(293/375 * 100vw);

      &.text__main {
        font-size: 15px;
      }
    }

    &__picture {
      height: 458px;
    }
  }
}