.articles-page {
  &__list {
    display: grid;
    grid-gap: var(--grid-gap);
    grid-auto-rows: 1fr;
  }

  &__item {
    grid-column: span 1;
  }
}


@include respond-up('s-large') {
  .articles-page {
    padding-bottom: 120px;

    &__list {
      grid-template-columns: repeat(2, var(--grid-column6));
    }
  }
}

@include respond('medium') {
  .articles-page {
    padding-bottom: 100px;

    &__list {
      grid-template-columns: repeat(2, var(--grid-column3));
    }
  }
}

@include respond-down('small') {
  .articles-page {
    padding-bottom: 80px;

    &__list {
      grid-template-columns: repeat(1, var(--grid-column4));
    }
  }
}