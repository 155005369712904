.product-base-top-actions {
  &__item {
    border-radius: 6px;
    background-color: var(--background-color);
    &:not(:first-child) {
      margin-top: 8px;
    }
  }

  &__link {
    display: flex;
    align-items: center;
  }

  &__icon {
    display: flex;
  }

  &__item-title {

  }

  &__icon{
    background: var(--primary-red-10);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    margin-right: 10px;
    svg{
      path{
        transition: fill .3s;
      }
    }
  }
}

@include respond-up('s-large') {
  .product-base-top-actions {
    padding-top: 60px;
    padding-bottom: 50px;
    &__item{
      padding: 8px 12px;
    }

    &__link{
      &:hover{
        .product-base-top-actions{
          &__icon{
            svg{
              path{
                fill: var(--primary-red);
              }
            }
          }
        }
      }
    }
  }
}

@include respond-up('medium') {
  .product-base-top-actions{
    &__icon{
      width: 30px;
      height: 30px;
    }
  }
}
@include respond('medium') {
  .product-base-top-actions {
    padding-top: 67px;
    padding-bottom: 40px;
  }
}

@include respond-down('small') {
  .product-base-top-actions {
    padding: 20px 18px;
  }
}