.contacts-page {
  &__requisites-block-wrap {
    //TODO цвет
    background: rgba(200, 203, 209, 0.5);
  }
}

@include respond-up('s-large') {
  .contacts-page {
    &__requisites-block-wrap {
      padding: 60px 0 120px;
      margin-top: 80px;
    }
  }
}

@include respond('medium') {
  .contacts-page {
    &__requisites-block-wrap {
      padding: 40px 0 100px;
      margin-top: 60px;
    }
  }
}

@include respond-down('small') {
  .contacts-page {
    &__requisites-block-wrap {
      padding: 20px 0 80px;
      margin-top: 40px;
    }
  }
}