.model-page-type-size-item {
  position: relative;
  border-top: 1px solid var(--border-color);

  &__link {
    display: flex;
  }

  &:last-child {
    border-bottom: 1px solid var(--border-color);
  }

  &__price {
    white-space: nowrap;
  }

  &__prices {
    display: flex;
    align-items: center;
  }

  &__kit-price {
    color: var(--grey-text);
  }

  &__buttons {
    display: flex;
    align-items: center;
    position: absolute;
    z-index: 25;
  }

  &__available-link {
    display: flex;
    width: max-content;
  }
}

@include respond-up('s-large') {
  .model-page-type-size-item {
    width: var(--grid-column8);
    padding: 16px 20px;
    margin: 0 40px;
    background: #FFFFFF;
    transition: background .3s, border-top-color .3s;
    &:last-child{
      transition: border-top-color .3s;
      &:hover{
        border-bottom: 1px solid transparent;
      }
    }
    &:hover{
      border-radius: 6px;
      border-top: 1px solid transparent;
      background: var(--light-background-color);

      .model-page-type-size-item {
        &__to-cart {
          background: var(--primary-red);

          svg path {
            stroke: #fff;
          }
        }
      }
    }

    &:hover + .model-page-type-size-item{
      border-top: 1px solid transparent;
    }

    &__link {
      //padding: 23px 50px;
    }

    &__kit-price {
      margin-left: 25px;
    }

    &__to-cart {
      margin-left: 31px;
      width: 38px;
      height: 38px;
      background: var(--light-grey);
      svg{
        width: 18px;
        height: 18px;
        path {
          stroke: var(--grey-text);
          transition: stroke var(--default-timing) var(--default-transition-function);
        }
      }
    }

    &__title {
      flex: 0 0 calc(var(--grid-column3) - 22px);
    }

    &__prices {
      flex: 0 0 calc(var(--grid-column3) + var(--grid-gap));
      margin-left: var(--grid-column);
    }

    &__available {
      flex: 0 0 var(--grid-column2);
      margin-left: calc((68 / 1500) * 100vw);
    }

    &__buttons {
      right: 50px;
    }
  }
}

@include respond-up('medium') {
  .model-page-type-size-item {
    &__link {
      align-items: center;
    }

    &__buttons {
      top: 50%;
      transform: translate3d(0, -50%, 0);
    }
  }
}

@include respond('medium') {
  .model-page-type-size-item {
    &__link {
      padding: 20px 28px;
    }

    &__title {
      flex: 0 0 calc((166 / 768) * 100vw);
    }

    &__prices {
      align-items: flex-start;
      flex-direction: column;
      flex: 0 0 calc((166 / 768) * 100vw);
      margin-left: calc((30 / 768) * 100vw);
    }

    &__available {
      flex: 0 0 calc((166 / 768) * 100vw);
      margin-left: calc((30 / 768) * 100vw);
    }

    &__to-cart {
      margin-left: 25px;
    }

    &__buttons {
      right: 28px;
    }
  }
}

@include respond-down('medium') {
  .model-page-type-size-item {

  }
}

@include respond-down('small') {
  .model-page-type-size-item {
    &__link {
      padding: 16px 18px 20px;
      flex-direction: column;
      flex-wrap: wrap;
      max-height: 132px;
    }

    &__prices {
      margin-top: 20px;
    }

    &__available {
      margin-top: 8px;
    }

    &__buttons {
      justify-content: space-between;
      flex-direction: column;
      align-items: flex-end;
      top: 16px;
      right: 18px;
    }

    &__kit-price {
      margin-left: 12px;
    }

    &__to-cart {
      margin-top: 37px;
    }
  }
}