.article-base {
  &__top {
    //background-color: ;
  }

  &__picture {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 6px;
  }
}

@include respond-up('s-large') {
  .article-base {
    &__main {
      grid-column: 1/9;
    }

    &__picture {
      height: calc(var(--grid-column8) * 540/924);
    }

    &__sticky-wrap {
      grid-column: 10/13;
    }

    &__top {
      padding: 30px 0 80px;
    }
  }
}

@include respond('medium') {
  .article-base {
    &__main {
      grid-column: 1/7;
    }

    &__top {
      padding: 24px 0 60px;
    }
  }
}

@include respond-down('medium') {
  .article-base {
    &__sticky-wrap {
      display: none;
    }
  }
}

@include respond-down('small') {
  .article-base {
    &__main {
      grid-column: 1/5;
    }

    &__top {
      padding: 20px 0 50px;
    }
  }
}