.slider-nav {
  display: inline-flex;
}

@include respond-up('medium') {
  .slider-nav {
    &__arrow {
      &_right {
        margin-left: 14px;
      }
    }
  }
}

@include respond-down('small') {
  .slider-nav {
    &__arrow {
      &_right {
        margin-left: 10px;
      }
    }
  }
}