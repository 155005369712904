.contacts-block {
  background: #fff;
  border-radius: 6px;
}

@include respond-up('s-large') {
  .contacts-block {
    padding: 40px 40px 50px;
    margin-top: 40px;

    &__offices-wrap {
      margin-top: 60px;
    }
  }
}

@include respond('medium') {
  .contacts-block {
    padding: 30px 30px 40px;
    margin-top: 30px;

    &__offices-wrap {
      margin-top: 40px;
    }
  }
}

@include respond-down('small') {
  .contacts-block {
    padding: 20px 20px 30px;
    margin-top: 20px;

    &__offices-wrap {
      margin-top: 30px;
    }
  }
}