.cart-order-block {
  background-color: white;
  border-bottom: 1px solid var(--light-grey) ;

  &_contacts {
    border-radius: 0 6px 0 0;
  }

  &_receiving {
    .cart-order-block{
      &__tabs{
        &:before{
          display: none;
        }
      }

      &__radio-list{
        flex: 1 1 100%;
      }

      &__input-label{
        &_flex{
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
      }
    }
  }

  &_pay {
  }

  &_comment {
    border-radius: 0 0 6px 6px;
    .cart-order-block{
      &__label{
        color: var(--grey-text);
      }
    }
    border-bottom: unset;
  }

  &__tabs{
    margin: unset;
  }


  &__head {
    display: flex;
    align-items: center;
    padding: 20px 0;
  }

  &__number-wrap {
    background-color: transparent;
    border-radius: 100%;
    border: 2px solid var(--primary-red-60);
    margin-right: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__number {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    border-radius: 100px;
    color: var(--primary-red);
    margin-top: 2px;
  }

  &__content {
    display: flex;
    flex-direction: column;
  }

  &__label.text {
    color: var(--grey-text-color);
  }

  &__title {
  }

  &__comment {
    resize: none;
    height: 96px;
  }

  &__radio-item {
    border-top: 1px solid var(--grey-stroke-color);

    &:last-child {
      border-bottom: 1px solid var(--grey-stroke-color);
    }
  }

  &__input-label {
  }

  &__label-title.text {
    display: block;
  }

  &__label-description.text {
    color: var(--grey-text-color);
    display: block;
    margin-top: 8px;
  }

  &__fields {
    display: grid;
    grid-gap: var(--grid-gap);
  }

  &__info.text {
    color: var(--primary-red);
  }
}

@include respond-up('s-large') {
  .cart-order-block {
    position: relative;
    &_comment {
      display: flex;
      padding: 40px;
      .cart-order-block{
        &__title{
          text-transform: unset;
          margin-bottom: 6px;
        }
        &__head{
          padding: unset;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          flex: 1 1 30%;
        }

        &__content{
          padding: unset;
          margin-left: 115px;
          flex: 1 1 70%;
        }

        &__label{
          max-width: 214px;
          color: var(--grey-text);
        }
      }
    }

    &_receiving {
      .cart-order-block{
        &__tabs{
          margin-bottom: 30px;
        }
      }
    }

    &__tabs{
      &:before{
        content: '';
        position: absolute;
        width: 100%;
        height: 1px;
        background-color: var(--light-grey);
        top: 55px;
      }
    }

    &__head{
      padding-left: 40px;
      padding-top: 40px;
    }

    &__number-wrap {
      width: 34px;
      height: 34px;
      //padding: 5px 5px 5px 38px;
    }

    &__content {
      padding: 0 40px 50px;
    }

    &__radio-item {
      margin: 0 -40px;
      padding: 0 40px;
    }

    &__info {
      margin-top: 25px;
    }

    &__fields {
      grid-template-columns: repeat(3, 1fr);
    }
  }
}

@include respond-up('medium') {
  .cart-order-block {
    &__label {
      margin-bottom: 8px;
    }
  }
}

@include respond('medium') {
  .cart-order-block {
    &_receiving {
      .cart-order-block{
        &__tabs{
          margin-bottom: 20px;
        }
      }
    }

    &__number-wrap {
      //padding: 5px 5px 5px 28px;
      width: 34px;
      height: 34px;
    }

    &__content {
      padding: 0 28px 35px;
    }

    &__head{
      padding-left: 28px;
    }

    &__radio-item {
      margin: 0 -28px;
      padding: 0 28px;
    }

    &__fields {
      grid-template-columns: 1fr 1fr;
    }
  }
}

@include respond-down('medium') {
  .cart-order-block {
    &_comment{
      padding: 30px 30px 40px 30px;
      .cart-order-block{
        &__title{
          text-transform: unset;
          margin-bottom: 6px;
        }
        &__head{
          padding: unset;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
        }

        &__content{
          padding: unset;
        }

        &__label{
          color: var(--grey-text);
          margin-bottom: 20px;
        }
      }
    }
  }
}

@include respond-down('small') {
  .cart-order-block {
    &_comment{
      padding: 20px;
    }

    &_receiving {
      .cart-order-block{
        &__tabs{
          margin-bottom: 16px;
        }
      }
    }

    &__number-wrap {
      width: 30px;
      height: 30px;
      //padding: 5px 5px 5px 18px;
    }

    &__head{
      padding-left: 18px;
    }

    &__content {
      padding: 0 18px 30px;
    }

    &__label {
      margin-bottom: 6px;
    }

    &__radio-item {
      margin: 0 -18px;
      padding: 0 18px;
    }

    &__tabs{
      overflow: scroll;
      &::-webkit-scrollbar{
        display: none;
      }
    }

    &__fields {
      grid-template-columns: 1fr;
    }
  }
}