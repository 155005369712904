.range-openable-field {
  input {
    //@extend .text__small;
    background-color: #fff;
    border: 1px solid var(--grey-stroke-color);
    padding: 6px 8px;
    width: 100%;
  }

  &__delimiter {
    background-color: var(--black-color);
    width: 14px;
    height: 1px;
    margin: 0 10px;
  }

  &__values {
    display: flex;
    align-items: center;
  }

  &__slider {
    margin-top: 15px;
    padding-bottom: 30px;

    .slider-base {
      --slider-connect-bg: var(--primary-color);
      --slider-handle-height: 15px;
      --slider-handle-width: 15px;
      --slider-radius: 0;
      height: 3px;
    }

    .slider-tooltip {
      display: none;
    }

    .slider-handle {
      box-shadow: none;
      background: var(--primary-color);
      border: none;
      border-radius: 100%;

      &:focus {
        box-shadow: none;
      }

      &::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate3d(-50%, -50%, 0);
        background-color: #fff;
        width: 5px;
        height: 5px;
        border-radius: 100%;
      }
    }

    .slider-handle-lower {
      right: calc(var(--slider-handle-width) * -1) !important;
    }

    .slider-handle-upper {
      right: 0 !important;
    }

    .slider-connects {
      background-color: var(--grey-stroke-color);
    }
  }
}