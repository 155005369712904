.index-about {
  &__wrap {
    display: flex;
  }

  &__title,
  &__subtitle {
    color: #fff;
  }

  &__description {
    color: var(--border-color);
  }

  &__subtitle {
    position: relative;

    &:before {
      position: absolute;
      content: '';
      top: 0;
      left: 0;
      height: 100%;
      width: 2px;
      background: linear-gradient(180deg, rgba(215, 39, 46, 0) 0%, #D7262D 47.92%, rgba(215, 38, 45, 0) 100%);
    }
  }

  &__picture {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 6px;
  }
}

@include respond-up('s-large') {
  .index-about {
    padding: 80px 0 100px;

    &__tyre-wrap {
      height: 100%;
      top: 0;

      svg {
        width: auto;
      }
    }

    &__tyre {
      &_tab {
        display: none;
      }
    }

    &__wrap {
      justify-content: space-between;
    }

    &__text-wrap {
      flex: 0 0 var(--grid-column5);
      padding-top: 7px;
    }

    &__image-wrap {
      flex: 0 0 var(--grid-column6);
    }

    &__picture {
      height: calc(var(--grid-column6) * 499/686);
    }

    &__description {
      margin-top: 30px;
    }

    &__subtitle {
      margin-top: 40px;
    }

    &__button-wrap {
      margin-top: 60px;
    }
  }
}

@include respond-up('medium') {
  .index-about {
    position: relative;

    &__tyre-wrap {
      position: absolute;
      content: '';
      left: 0;
      bottom: 0;
      z-index: 5;

      svg {
        height: 100%;
      }
    }

    &__tyre {
      width: 100%;
      height: 100%;
    }

    &__subtitle {
      padding: 4px 0 4px 20px;
    }

    &__wrap {
      z-index: 10;
      position: relative;
    }
  }
}

@include respond('medium') {
  .index-about {
    padding: 60px 0 80px;

    &__tyre-wrap {
      height: calc(100% - 180px);
      width: calc(var(--grid-spacer-and-indent) + var(--grid-column4));

      svg {
        width: 100%;
      }
    }

    &__tyre {
      &_desk {
        display: none;
      }
    }

    &__subtitle {
      width: var(--grid-column4);
    }

    &__button-wrap {
      margin-top: 40px;
    }

    &__image-wrap {
      width: var(--grid-column6);
      margin-top: 50px;
    }

    &__picture {
      height: calc(var(--grid-column6) * 348/696);
    }
  }
}

@include respond-down('medium') {
  .index-about {
    &__wrap {
      flex-direction: column;
    }

    &__description {
      margin-top: 20px;
    }

    &__subtitle {
      margin-top: 30px;
    }
  }
}

@include respond-down('small') {
  .index-about {
    padding: 40px 0 60px;

    &__tyre-wrap {
      display: none;
    }

    &__title {
      font-size: 30px;
    }

    &__subtitle {
      padding: 5px 0 6px 20px;
    }

    &__button-wrap {
      margin-top: 30px;
    }

    &__image-wrap {
      width: var(--grid-column4);
      margin-top: 40px;
    }

    &__picture {
      height: calc(var(--grid-column4) * 166/335);
    }
  }
}