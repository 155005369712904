.warranty-return-time{
  background: #fff;
  border-radius: 6px;
  &__title{
    display: block;
  }

  &__item{
    &_green{
      .warranty-return-time{
        &__item-title{
          color: rgba(94, 151, 0, 1);
        }
        &__line{
          &:after{
            background: rgba(94, 151, 0, 1);
            width: 30%;
          }
        }

        &__item-days{
          &:before{
            background: rgba(94, 151, 0, .2);
          }

          &:after{
            background: rgba(94, 151, 0, 1);
          }
        }
      }
    }

    &_yellow{
      .warranty-return-time{
        &__item-title{
          color: rgba(255, 185, 5, 1);
        }
        &__line{
          &:after{
            background: rgba(255, 185, 5, 1);
            width: 70%
          }
        }

        &__item-days{
          &:before{
            background: rgba(255, 185, 5, .2);
          }

          &:after{
            background: rgba(255, 185, 5, 1);
          }
        }
      }
    }

    &_red{
      .warranty-return-time{
        &__item-title{
          color: var(--primary-red);
        }

        &__line{
          &:after{
            background: var(--primary-red);
            width: 100%;
          }
        }

        &__item-days{
          &:before{
            background: rgba(215, 38, 45, .2);
          }

          &:after{
            background: rgba(215, 38, 45, 1);
          }
        }
      }
    }
  }

  &__item-title{
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
  }

  &__item-days{
    margin-left: 9px;
    position: relative;
    display: flex;
    justify-content: flex-end;
    width: 100%;
    padding-bottom: 20px;
    color: var(--grey-text);
    &:before{
      content: '';
      width: 20px;
      height: 20px;
      position: absolute;
      border-radius: 100%;
      left: -11px;
      bottom: -11px;
      z-index: 3;
    }

    &:after{
      content: '';
      width: 10px;
      height: 10px;
      position: absolute;
      border-radius: 100%;
      left: -6px;
      bottom: -6px;
      z-index: 3;
    }
  }

  &__line{
    &:before{
      content: '';
      width: 100%;
      height: 2px;
      background: var(--light-grey);
      position: absolute;
      left: 0;
      bottom: -2px;
      z-index: 1;
    }

    &:after{
      content: '';
      width: 100%;
      height: 2px;
      position: absolute;
      left: 0;
      bottom: -2px;
      z-index: 2;
    }
  }

  &__item-subtitle{
    color: var(--grey-text);
  }
}

@include respond-up('s-large') {
  .warranty-return-time{
    padding: 40px 0 50px 40px;
    &__item{
      width: calc(var(--grid-column8) - var(--grid-gap));
    }
  }
}

@include respond-up('medium') {
  .warranty-return-time{
    &__title{
      margin-bottom: 30px;
    }

    &__item{
      margin-bottom: 40px;
    }

    &__item-days{
      justify-content: flex-end;
    }

    &__item-subtitle{
      margin-top: 30px;
    }
  }
}
@include respond('medium') {
  .warranty-return-time{
    padding: 30px 30px 40px;
  }
}

@include respond-down('small') {
  .warranty-return-time{
    padding: 20px 20px 30px;
    &__title{
      margin-bottom: 20px;
    }

    &__item{
      margin-bottom: 17px;
    }

    &__item-title{
      font-size: 15px;
      font-weight: 600;
      line-height: 22px;
      margin-bottom: 4px;
    }

    &__item-days{
      justify-content: flex-start;
      margin: unset;
      &:before{
        left: -2px;
      }

      &:after{
        left: 3px;
      }
    }

    &__item-subtitle{
      margin-top: 20px;
    }

    &__line{
      &:before, &:after{
        left: 3px;
      }
    }
  }
}