.recall-form {
  background-color: var(--primary-black);
  position: relative;
  border-radius: 6px;

  &:before{
    content: '';
    top: 0;
    left: 0;
    bottom: 0;
    background-size: cover;
    background-position: left top;
    background-repeat: no-repeat;
    position: absolute;
  }

  &__button-wrap {
    button {
      border: unset;
      font-size: 15px;
      font-style: normal;
      font-weight: 600;
      line-height: 145%; /* 21.75px */
      text-transform: uppercase;
      color: #FFFFFF;
    }
  }

  &__data {
    z-index: 10;
  }

  &__icon {
    display: flex;
    position: absolute;
    z-index: 5;

    //svg {
    //  width: 100%;
    //  height: 100%;
    //}

    top: 0;
    bottom: 0;
    left: 0;
    svg{
      height: 100%;
      width: 100%;
    }
  }

  &__heading {
    position: relative;
    z-index: 10;
    display: flex;
    flex-direction: column;

    &::before {
      content: '';
      position: absolute;
      bottom: 0;
    }
  }

  &__title,
  &__description {
    color: #fff;
    transition: opacity .3s;
  }

  &__main,
  &__bottom {
    display: flex;
  }

  &__button-wrap {
    display: flex;
    flex: 0 0 auto;
  }

  &__policy {
    flex: 0 1 auto;
  }

  &__success {
    display: flex;
    align-items: center;
  }

  &__success-wrap{
    display: flex;
    flex-direction: column;
  }

  &__success-icon {
    display: flex;
    border-radius: 100%;
    overflow: hidden;
  }

  &__success-title {
    color: #fff;
  }

  &__success-description {
    margin-top: 12px;
    color: #fff;
  }
}

@include respond-up('s-large') {
  .recall-form {
    margin: 80px 0;
    height: 256px;
    &:before{
      width: 616px;
      height: 256px;
    }

    &__data {
      padding: 50px 0 0 0;
    }

    &__description {
      margin-top: 4px;
      max-width: 350px;
    }

    &__form-wrap {
      flex: 0 1 100%;
    }

    &__icon {
      &_top {
        left: 0;
      }
    }

    &__bottom {
      margin-top: 24px;
      display: flex;
      justify-content: space-between;
    }

    &__heading {
      padding: 50px 0 30px;
      grid-column: 2 / 7;

      &::before {
        left: 75px;
        right: 75px;
      }
    }

    &__inner{
      grid-column: 7 / 12;
    }


    &__fields{
      display: grid;
      /* justify-content: space-between; */
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 20px;
    }

    &__field{
      border-radius: 6px;
    }

    &__success-icon {
      margin-right: 30px;
      margin-bottom: 35px;
    }
  }
}

@include respond-up('medium') {
  .recall-form {
    &:before{
      background-image: url('../images/base/form-tyre.png');
    }

    &__success {
      //padding-top: 30px;
    }

    &__policy{
      width: 275px;
    }

    &__button-wrap {
      button {
        width: var(--grid-column2);
      }
    }
  }
}

@include respond('medium') {
  .recall-form {
    margin: 60px 0;
    &:before{
      width: 616px;
    }

    &__heading {
      grid-column: 1 / 6;
      padding: 30px 0 0 40px;
    }

    &__inner{
      grid-column: 1 / 6;
    }

    &__data {
      padding: 30px 0 0 40px;
      width: 100%;
    }

    &__form-wrap{
      width: 100%;
    }

    &__fields{
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 20px;
    }

    &__bottom{
      margin-top: 30px;
      margin-bottom: 50px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &__success{
      padding-top: 60px;
      padding-left: 40px;
    }

    &__success-icon{
      margin-bottom: 20px;
    }
  }
}

@include respond-down('medium') {
  .recall-form {
    &__success{
      flex-direction: column;
      width: 100%;
    }
  }
}

@include respond-down('small') {
  .recall-form {
    margin: 40px 0;
    &:before{
      background-size: cover;
      width: 100%;
      height: 100%;
      background-image: url('../images/base/form-tyre-mob.png');
    }

    &__heading {
      grid-column: 1 / 5;
      padding: 20px 20px 30px;
    }

    &__title{
      padding-bottom: 8px;
    }

    &__inner{
      grid-column: 1 / 5;
      padding: 0 20px;
    }

    &__data {
      width: 100%;
    }

    &__form-wrap{
      width: 100%;
    }

    &__fields{
      display: grid;
      grid-template-columns: 1fr;
      grid-row-gap: 16px;
    }

    &__button-wrap{
      button{
        width: 100%;
      }
      display: block;
      margin-bottom: 10px;
    }

    &__bottom{
      width: var(--grid-column3);
      margin-top: 30px;
      margin-bottom: 40px;
      display: flex;
      flex-direction: column-reverse;
    }

    &__policy{
      .policy-checkbox__inner {
        text-align: left;
      }
    }

    &__success{
      padding: 86px 25px 0;
      align-items: center;
    }

    &__success-icon{
      margin-bottom: 16px;
    }

    &__success-wrap{
      align-items: center;
      text-align: center;
    }

    &__description {
      font-size: 15px;
      line-height: 160%;
      font-weight: 400;
    }
  }
}