.about-main {
  border-top: 1px solid var(--grey-text);
  position: relative;

  &__top-wrap,
  &__text-wrap {
    display: flex;
  }

  &__text-wrap {
    flex-direction: column;
  }

  &__description {
    color: var(--border-color);
  }

  &__subtitle {
    color: #fff;
    position: relative;
    margin-top: 40px;

    &:before {
      position: absolute;
      content: '';
      top: 0;
      left: 0;
      height: 100%;
      width: 2px;
      background: linear-gradient(180deg, rgba(215, 39, 46, 0) 0%, #D7262D 47.92%, rgba(215, 38, 45, 0) 100%);
    }
  }

  &__logo-wrap,
  &__picture {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__logo-wrap {
    svg {
      width: 100%;
      height: 100%;

      path {
        fill: #fff;
      }
    }
  }

  &__picture {
    width: 100%;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 6px;
  }
}

@include respond-up('s-large') {
  .about-main {
    padding: 60px 0 120px;

    &__top-wrap {
      padding: 0 calc(var(--grid-gap) + var(--grid-column));
    }

    &__logo-wrap {
      flex: 0 0 210px;
      height: 50px;
    }

    &__text-wrap {
      flex: 0 0 var(--grid-column6);
    }

    &__image-wrap {
      margin-top: 60px;
    }

    &__picture {
      height: calc(var(--grid-column12) * 700/1400);
    }

    &__tyre {
      &_tab {
        display: none;
      }
    }
  }
}

@include respond-up('medium') {
  .about-main {
    &__top-wrap,
    &__image-wrap {
      z-index: 20;
      position: relative;
    }

    &__top-wrap {
      justify-content: space-between;
    }

    &__subtitle {
      padding: 4px 0 4px 22px;
    }

    &__tyre-wrap {
      z-index: 10;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 0;
      left: calc(-1 * var(--grid-spacer-and-indent));
      bottom: 0;
    }

    &__tyre {
      height: 100%;

      svg {
        width: 100%;
        height: 100%;
      }
    }
  }
}

@include respond('medium') {
  .about-main {
    padding: 50px 0 80px;

    &__logo-wrap {
      flex: 0 0 168px;
      height: 40px;
    }

    &__text-wrap {
      flex: 0 0 var(--grid-column4);
    }

    &__image-wrap {
      margin-top: 50px;
    }

    &__picture {
      height: calc(var(--grid-column6) * 348/696);
    }

    &__tyre {
      &_desk {
        display: none;
      }
    }
  }
}

@include respond-down('small') {
  .about-main {
    padding: 40px 0 60px;

    &__top-wrap {
      flex-direction: column;
    }

    &__logo-wrap {
      width: 130px;
      height: 31px;
    }

    &__description {
      margin-top: 30px;
    }

    &__subtitle {
      padding: 5px 0 6px 22px;
    }

    &__image-wrap {
      margin-top: 40px;
    }

    &__picture {
      height: calc(var(--grid-column4) * 166/335);
    }

    &__tyre-wrap {
      display: none;
    }
  }
}