.texts {
  &_input {
    font-family: var(--font);
    line-height: 140%;
    font-weight: 400;
    font-size: 15px;
  }

  &_card-heading {
    font-family: var(--font);
    line-height: 150%;
    font-weight: 600;
  }
}

@include respond-up('s-large') {
  .texts {

  }
}

@include respond-up('medium') {
  .texts {
    &_card-heading {
      font-size: 16px;
    }
  }
}

@include respond('medium') {
  .texts {

  }
}

@include respond-down('medium') {
  .texts {

  }
}

@include respond-down('small') {
  .texts {
    &_card-heading {
      font-size: 15px;
    }
  }
}