.pagination {
  display: flex;
  align-items: center;

  &__button {
    width: 135px;
  }

  &__list-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__list {
    display: flex;
    align-items: center;
  }

  &__item {
    @extend .text__small;
    display: flex;
    align-items: center;
    justify-content: center;
    font-style: normal;
    text-align: center;
    color: var(--primary-black);

    &_first,
    &_page,
    &_last {
      margin: 0 3px;
    }

    &_active {
      .pagination__link {
        &_first,
        &_page,
        &_last {
          background-color: var(--light-grey);
        }
      }
    }

    &_dots {
      width: 28px;
      height: 28px;
      margin: 0 3px 0 0;
    }
  }

  &__link {
    display: flex;
    align-items: center;
    justify-content: center;
    transition: color .3s;

    &:hover{
      color: var(--primary-red);
      &:before{
        border-right-color: var(--primary-red);
      }
    }

    &_previous,
    &_next {
      padding: 13px;
      &:before{
        transition: border-color .3s;
        content: '';
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 6px 6px 6px 0;
        border-color: transparent var(--light-grey) transparent transparent;
      }
    }

    &_previous {
      &:before{
        transform: rotate(0deg);
      }
    }

    &_next {
      &:before{
        transform: rotate(180deg);
      }
    }

    &_first,
    &_page,
    &_last {
      border-radius: 100%;
      width: 28px;
      height: 28px;
    }
  }
}

@include respond-up('s-large') {
  .pagination {
    &__list {
    }
  }
}

@include respond-up('medium') {
  .pagination {
    justify-content: space-between;
    &__list-wrap {
      justify-content: flex-end;
    }
  }
}

@include respond('medium') {
  .pagination {
  }
}

@include respond-down('medium') {
  .pagination {

  }
}

@include respond-down('small') {
  .pagination {
    flex-direction: column;

    &__list-wrap {
      margin-top: 25px;
    }

    &__link {
      &_previous,
      &_next {
        padding: 10px;
      }
    }
  }
}