.model-card {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  height: 100%;
  justify-content: space-between;
  border-radius: 6px;

  &__action-name{
    display: flex;
    flex-direction: column;
  }

  &__action-name-wrap{
    border-bottom: 1px solid var(--grey-stroke-color);
    padding: 5px;
    &:last-child{
      border: none;
    }
  }

  &__actions {
    position: absolute;
    top: 0;
    left: 0;
  }

  &__action {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    &:not(:first-child) {
      margin-top: 2px;
    }
  }

  &__features-wrap {
    position: absolute;
    left: 0;
    bottom: 0;
  }

  &__favorite {
    position: absolute;
    top: 0;
    right: 0;
  }

  &__image-link {
    display: flex;
    width: 100%;
    height: 100%;
  }

  &__picture {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  &__top {
    display: flex;
    position: relative;
  }

  &__action-link {
    display: flex;
    position: relative;
  }

  &__action-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--background-color);
    border-radius: 100%;
    svg {

    }
  }

  &__feature-icon {
    display: flex;
  }

  &__hidden-name {
    position: absolute;
    top: 50%;
    left: 100%;
    transform: translate3d(8px, -50%, 0);
    margin-left: 0;
    background-color: #fff;
    filter: drop-shadow(0px 0px 20px rgba(45, 41, 40, 0.1));
    padding: 5px;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 120%;
    letter-spacing: -0.02em;
    color: var(--black-color);
    opacity:0;
    pointer-events: none;
    transition: opacity var(--default-transition-timing);
    z-index: 1000;

    &:before {
      content: '';
      position: absolute;
      right: calc(100% - 1px);
      top: 50%;
      transform: translateY(-50%);
      display: block;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 7px 6px 7px 0;
      border-color: var(--t) #fff var(--t) var(--t);
    }

    &_actions{
      padding: 0;
      top: 0;
      left: 32px;
      transform: unset;
      &:before {
        content: unset;
      }
    }
  }

  &__hidden-target {
    &:hover {
      .model-card {
        &__hidden-name {
          opacity: 1;
        }

        &__action-icon {
          svg{
            path{
              fill: var(--primary-red);
              fill-opacity: 1;
            }
          }
        }
      }
    }
  }

  &__feature {
    display: flex;
    position: relative;

    &:not(:first-child) {
      margin-top: 8px;
    }

    .model-card {
      &__hidden-name {
        transform: translate3d(11px, -50%, 0);
      }
    }
  }

  &__link {
    display: flex;
  }

  &__price {
    &_old {
      font-style: normal;
      font-weight: 400;
      letter-spacing: -0.02em;
      color: var(--grey-text-color);
      text-decoration: line-through;
    }
  }

  &__buy {
    position: relative;
    margin-top: auto;
  }

  &__kit-price {
    color: var(--grey-text-color);
  }

  &__buy-top {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }

  &__title{
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 21px */
    color: var(--primary-black);
  }

  &__quantity{
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 18px */
    color: var(--green-color);
  }

  &__price{
    &_old{
      color: var(--grey-text)
    }
  }

  &__button{
    position: absolute;
    right: 0;
    bottom: 0;
  }

  &__price-title{
    text-transform: none !important;
  }

  &__button-icon-wrap{
    svg{
      path{
        fill: var(--grey-text);
      }
    }
  }
}

@include respond-up('s-large') {
  .model-card {
    transition: box-shadow .3s;
    &:hover{
      box-shadow: 0 0 20px 0 rgba(44, 44, 47, 0.10);
      .model-card{
        &__button{
          background: var(--primary-red);
          svg{
            path{
              stroke: #FFFFFF;
              fill: #FFFFFF;
            }
          }
        }

        &__action-icon{
          background-color: rgba(215, 38, 45, 0.10);
          svg{
            path{
              fill: var(--primary-red);
              fill-opacity: 0.6;
            }
          }
        }
      }
    }

    &__title {
      transition: color var(--default-transition-timing);
    }

    &__image-link{
      padding-bottom: 8vw;
    }

    &__link {
      &:hover {
        .model-card__title {
          color: var(--primary-color);
        }
      }
    }

    &__action-name-wrap{
      width: 165px;
    }

    &__action-icon{
      transition: background-color .3s;
      svg{
        path{
          transition: fill .3s, fill-opacity .3s;
        }
      }
    }

    &__button{
      background: var(--background-color);
      svg{
        path{
          transition: stroke .3s, fill .3s;
          stroke: var(--grey-text);
        }
      }
    }

    &__prices{
      display: flex;
      align-items: baseline;
    }

    &__price{
      &_old{
        margin-left: 8px;
      }
    }
  }
}

@include respond-up('medium') {
  .model-card {
    padding: 18px;

    &__title{
      font-size: 15px;
      margin-bottom: 8px;
    }

    &__quantity{
      font-size: 12px;
    }

    &__top {
      padding-top: 30px;
    }

    &__link {
      margin-top: 20px;
    }

    &__buy {
      padding-top: 6px;
    }

    &__price {

    }

    &__kit-price {
      margin-top: 22px;
    }

    &__action-icon {
      width: 28px;
      height: 28px;

      &:before {
        left: calc(100% - 1px);
      }
    }

    &__count{
      margin-bottom: 35px;
    }
  }
}

@include respond('medium') {
  .model-card {
    &__image-link{
      padding-bottom: 12vw;
    }

    &__kit-price{
      margin-top: 52px;
    }
  }
}

@include respond-down('medium') {
  .model-card {
    &__action-name-wrap{
      width: 130px;
    }

    &__button{
      background: var(--primary-red);
      svg{
        path{
          fill: #FFFFFF;
          stroke: #FFFFFF;
        }
      }
    }
  }
}

@include respond-down('small') {
  .model-card {
    padding: 10px;

    &__top {
      padding-top: 12px;
      min-height: 140px;
    }

    &__title{
      font-size: 13px;
      margin-bottom: 6px;
    }

    &__quantity{
      font-size: 11px;
    }

    &__image-link{
      padding-bottom: 19vw;
    }

    &__link {
      margin-top: 15px;
    }

    &__buy {
      padding-top: 8px;
    }

    &__price {
    }

    &__kit-price {
      margin-top: 22px;
    }

    &__action-icon {
      width: 24px;
      height: 24px;

      &:before {
        left: calc(100% + 2px);
      }
    }

    &__count{
      margin-bottom: 50px;
    }
  }
}