.offices-list {
  &__opening-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-left: -5px;
  }

  &__opening-item {
    display: flex;
    margin-left: 5px;
  }

  &__item {
    transition: background var(--default-timing) var(--default-transition-function);

    &._opened {
      background: var(--grey-bg-lighter);

      .offices-list {
        &__radio {
          &:before {
            opacity: 1;
          }
        }
      }
    }
  }

  &__link {
    display: flex;
    flex-direction: column;
  }

  &__radio {
    display: flex;
    border-radius: 50%;
    border: 1px solid var(--border-color);
    background: #fff;
    position: relative;
    width: 19px;
    height: 19px;
    flex: 0 0 19px;

    &:before {
      position: absolute;
      content: '';
      top: 4px;
      left: 4px;
      width: 9px;
      height: 9px;
      opacity: 0;
      border-radius: 50%;
      background: var(--primary-red);
      transition: opacity var(--default-timing) var(--default-transition-function);
    }
  }

  &__item-top {
    display: flex;
    align-items: center;
  }

  &__link-title {
    font-style: normal;
    color: var(--primary-black);
    line-height: 150%;
    font-weight: 600;
  }
}

@include respond-up('s-large') {
  .offices-list {
    &__link {
      padding: 20px;
    }

    &__content {
      padding-left: 31px;
    }
  }
}

@include respond-up('medium') {
  .offices-list {
    &__item {
      border-radius: 6px;
    }

    &__link-title {
      margin-left: 12px;
      font-size: 16px;
    }

    &__content {
      margin-top: 6px;
    }
  }
}

@include respond('medium') {
  .offices-list {
    &__link {
      padding: 16px 20px;
    }

    &__content {
      padding-left: 29px;
    }
  }
}

@include respond-down('small') {
  .offices-list {
    &__link {
      padding: 12px 20px;
    }

    &__link-title {
      margin-left: 8px;
      font-size: 15px;
    }

    &__content {
      margin-top: 3px;
      padding-left: 27px;
    }
  }
}