.partner-recall {
  position: relative;

  &__text-wrap {
    display: flex;
    flex-direction: column;
  }

  &__picture {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    border-radius: 6px;
    overflow: hidden;
    position: relative;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

@include respond-up('s-large') {
  .partner-recall {
    &__picture {
      height: calc(var(--grid-column12) * 600/1400);
    }

    &__text-wrap {
      left: calc(var(--grid-column) + var(--grid-gap));
      top: calc(80/1600 * 100vw);
      width: var(--grid-column7);
    }

    &__button-wrap {
      margin-top: 80px;
    }
  }
}

@include respond-up('medium') {
  .partner-recall {
    &__title,
    &__description {
      color: #fff;
    }

    &__picture {
      &:before {
        position: absolute;
        content: '';
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: var(--primary-black);
        opacity: .8;
      }
    }

    &__text-wrap {
      position: absolute;
    }

    &__description {
      margin-top: 20px;
    }

    &__button {
      padding: 13px 40px;
    }
  }
}

@include respond('medium') {
  .partner-recall {
    &__picture {
      height: calc(var(--grid-column6) * 358/696);
    }

    &__text-wrap {
      left: 30px;
      top: calc(30/768 * 100vw);
      width: calc(var(--grid-column5) - 30px);
    }

    &__button-wrap {
      margin-top: 40px;
    }
  }
}

@include respond-down('small') {
  .partner-recall {
    &__pictures {
      height: calc(var(--grid-column4) * 240/335);
    }

    &__text-wrap {
      margin-top: 30px;
    }

    &__description {
      margin-top: 16px;
      color: var(--grey-text);
    }

    &__button-wrap {
      margin-top: 40px;
    }
  }
}