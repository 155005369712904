@include respond-up('s-large') {
  .index-contacts {
    grid-row-gap: 40px;
  }
}

@include respond-up('medium') {
  .index-contacts {
    display: grid;
    grid-template-columns: repeat(2, 1fr);

    &__title,
    &__button-wrap {
      grid-row: 1;
      display: flex;
      align-items: center;
    }

    &__title {
      grid-column: 1/2;
    }

    &__button-wrap {
      grid-column: 2/3;
      justify-content: flex-end;
    }

    &__map-wrap {
      grid-column: 1/3;
      grid-row: 2;
    }
  }
}

@include respond('medium') {
  .index-contacts {
    grid-row-gap: 30px;
  }
}

@include respond-down('small') {
  .index-contacts {
    &__map-wrap {
      margin-top: 20px;
    }

    &__button-wrap {
      margin-top: 30px;
    }
  }
}