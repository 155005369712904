.why-we {
  &__list {
    display: grid;
    grid-gap: var(--grid-gap);
  }

  &__item {
    grid-column: span 1;
    border-radius: 6px;
    display: flex;

    &_white {
      background: #fff;
      flex-direction: column;
    }

    &_grey {
      background: var(--border-color);
      position: relative;

      &:before {
        position: absolute;
        content: '';
        top: 0;
        left: 0;
        bottom: 0;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        border-radius: 6px;
        z-index: 10;
      }
    }
  }

  &__item-title {
    font-weight: 400;
    line-height: 140%;
  }

  &__item-text-wrap {
    border-top: 1px solid var(--border-color);
    padding-top: 20px;
  }

  &__card-subtitles-list {
    display: flex;
    flex-direction: column;
  }

  &__card-subtitle {
    position: relative;

    &:before {
      position: absolute;
      content: '';
      width: 1px;
      height: 100%;
      background: linear-gradient(180deg, rgba(126, 126, 127, 0) 0%, #7E7E7F 47.92%, rgba(126, 126, 127, 0) 100%);
      top: 0;
      left: 0;
    }
  }

  &__card-title,
  &__card-subtitles-list {
    position: relative;
    z-index: 20;
  }
}

@include respond-up('s-large') {
  .why-we {
    padding: 80px 0 100px;

    &__list {
      margin-top: 40px;
      grid-template-columns: repeat(3, var(--grid-column4));
    }

    &__item {
      &_white {
        padding: 40px 40px 60px;
      }

      &_grey {
        grid-column: span 3;
        padding: 50px 60px;
        align-items: center;

        &:before {
          background-image: url("../images/base/why-we-tyre-desk.png");
          width: 556px;
        }
      }
    }

    &__item-text-wrap {
      margin-top: 40px;
    }

    &__item-description {
      padding-right: 18px;
    }

    &__card-title {
      flex: 0 0 calc(var(--grid-column4) - 60px);
      margin-right: calc(var(--grid-gap) * 2 + var(--grid-column));
    }

    &__card-subtitles-list {
      flex: 0 0 var(--grid-column5);
    }

    &__card-subtitle {
      padding: 6px 0 8px 21px;

      &:not(:first-child) {
        margin-top: 40px;
      }
    }
  }
}

@include respond-up('medium') {
  .why-we {
    &__item-title {
      font-size: 46px;
    }

    &__item-description {
      margin-top: 13px;
    }
  }
}

@include respond('medium') {
  .why-we {
    padding: 60px 0 80px;

    &__list {
      margin-top: 30px;
      grid-template-columns: repeat(2, var(--grid-column3));
    }

    &__item {
      padding: 30px;
    }

    &__item-text-wrap {
      margin-top: 30px;
    }

    &__card-title {
      font-size: 22px;
    }

    &__card-subtitle {
      font-size: 16px;
    }
  }
}

@include respond-down('medium') {
  .why-we {
    &__list {
      grid-auto-rows: 1fr;
    }

    &__item {
      &_grey {
        flex-direction: column;

        &:before {
          right: 0;
          background-image: url("../images/base/why-we-tyre-mob.png");
        }
      }
    }

    &__card-subtitles-list {
      margin-top: 20px;
    }

    &__card-subtitle {
      padding: 5px 0 5px 16px;

      &:not(:first-child) {
        margin-top: 20px;
      }
    }
  }
}

@include respond-down('small') {
  .why-we {
    padding: 40px 0 60px;

    &__list {
      margin-top: 20px;
      grid-template-columns: var(--grid-column4);
    }

    &__item {
      padding: 20px 30px 30px 20px;
    }

    &__item-title {
      font-size: 40px;
    }

    &__item-text-wrap {
      margin-top: 20px;
    }

    &__item-description {
      margin-top: 10px;
    }

    &__card-title {
      font-size: 17px;
    }

    &__card-subtitle {
      font-size: 15px;
    }
  }
}