.header-drop-menu {
  &__link-icon{
    display: flex;
  }

  &__title{
    @extend h4;
  }
}

@include respond-up('s-large') {
  .header-drop-menu {
    display: none;
  }
}

@include respond('medium') {
  .header-drop-menu {
    height: calc(100vh - 74px);
    top: 74px;

    &__link{
      height: 55px;
    }

    &__link-icon{
      margin-right: 14px;
    }

    &__contacts-wrap{
      margin-top: 40px;
      display: grid;
      grid-template-columns: 1fr 2fr;
      grid-column-gap: var(--grid-spacer);
      grid-row-gap: 40px;
    }

    &__socials{
      display: flex;
      align-items: flex-start;
    }

    &__email{
      margin-top: 5px;
    }

    padding-bottom: 70px;
  }
}

@include respond-down('medium') {
  .header-drop-menu {
    overflow-y: scroll;
    transition: {
      property: opacity, transform;
      duration: .8s;
    }
    transform: translateX(-100%);
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    background: #FFFFFF;
    opacity: 0;
    pointer-events: none;

    &__closer-block{
      padding: 20px var(--grid-spacer-and-indent);
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &__closer{
      display: flex;
    }

    &__menu-container{
      border-top: 1px solid var(--light-grey);
      border-bottom: 1px solid var(--light-grey);
    }

    &__element{
      border-bottom: 1px solid var(--light-grey);
      &:first-child{
        border-top: none;
      }

      &:last-child{
        border-bottom: none;
      }

      &._opened {
        .header-drop-menu {
          &__link{
            &:after{
              transform: rotate(-180deg);
              border-top-color: var(--primary-red);
            }
          }

          &__link-name{
            color: var(--primary-red);
          }
        }
      }
    }

    &__accordion-block{
      height: 0;
      overflow: hidden;
      transition: {
        property: height;
        duration: var(--default-timing);
        timing-function: var(--default-transition-function);
      }
    }

    &__block-wrap{
      display: flex;
      flex-direction: column;
      padding: 0 var(--grid-spacer-and-indent);
    }

    &__link{
      display: flex;
      align-items: center;
      padding-left: var(--grid-spacer-and-indent);
      &_with-arrow{
        position: relative;
        &:after{
          content: '';
          position: absolute;
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 6px 6px 0 6px;
          border-color: #2C2C2F transparent transparent transparent;
          transform: rotate(0deg);
          right: var(--grid-spacer-and-indent);
          top: calc(50% - 3px);
          transition: transform .3s, border-top-color .3s;
        }
      }
    }

    &__link-name-wrap{
      display: flex;
      align-items: center;
    }

    &__link-name{
      transition: color .3s;
    }

    &__child-link{
      color: var(--grey-text);
      padding: 4px 0;
    }

    &__block-wrap{
      padding-bottom: 20px;
    }

    &__phone{
      margin-bottom: 10px;
    }

    &__phone-link, &__email-link{
      padding: 10px 0;
    }

    &__email-link{
      font-weight: 400;
      text-transform: lowercase;
    }

    &__contacts-wrap{
      padding: 0 var(--grid-spacer-and-indent);
    }

    &__contact-wrap {
      display: flex;
      flex-direction: column;
    }

    &__phone-wrap {
      display: flex;
      flex-direction: column;
      row-gap: 20px;
    }

    &__text{
      color: var(--grey-text);
      margin-bottom: 4px;
    }

    &__email{
      color: var(--grey-text);
    }
  }
}

@include respond-down('small') {
  .header-drop-menu {
    padding-bottom: 70px;
    height: calc(100vh - 58px);
    top: 57px;
    &__menu-container{

    }

    &__link{
      height: 56px;
    }

    &__link-icon{
      margin-right: 12px;
    }

    &__contacts-wrap{
      margin-top: 30px;
    }

    &__contact-wrap{
      &:nth-child(2){
        margin: 20px 0;
      }
    }

    &__email{
      margin-bottom: 20px;
    }
  }
}