.news-article-main {
  &__title-wrap,
  &__date-wrap {
    display: flex;
  }

  &__date-wrap {
    align-items: center;
  }

  &__picture {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 6px;
  }
}

@include respond-up('s-large') {
  .news-article-main {
    &__title {
      flex: 0 0 var(--grid-column8);
    }

    &__date-wrap {
      padding-bottom: 8px;
    }

    &__image-wrap {
      margin-top: 30px;
    }

    &__picture {
      height: calc((var(--grid-column12) - 80px) * 660/1320);
    }
  }
}

@include respond-up('medium') {
  .news-article-main {
    &__title-wrap {
      justify-content: space-between;
      align-items: flex-end;
    }
  }
}

@include respond('medium') {
  .news-article-main {
    &__title {
      flex: 0 0 calc(var(--grid-column4) - 11px);
    }

    &__date-wrap {
      padding-bottom: 7px;
    }

    &__image-wrap {
      margin-top: 20px;
    }

    &__picture {
      height: calc((var(--grid-column6) - 60px) * 318/636);
    }
  }
}

@include respond-down('small') {
  .news-article-main {
    &__title-wrap {
      flex-direction: column;
    }

    &__date-wrap {
      margin-top: 8px;
    }

    &__image-wrap {
      margin-top: 16px;
    }

    &__picture {
      height: calc((var(--grid-column4) - 40px) * 147/295);
    }
  }
}