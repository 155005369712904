.footer {
  background: var(--primary-color);
  color: #FFFFFF;

  &__top{
    border-bottom: 1px solid rgba(255,255,255, 0.2);
  }

  &__bottom{

  }

  &__logo{
    svg{
      path{
        fill: #FFFFFF;
      }
    }
  }

  &__top-wrap{
    display: grid;
  }

  &__logo-block{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__list{
    display: flex;
    flex-direction: column;
  }

  &__menu-title{
    color: #FFFFFF;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 27px */
    text-transform: uppercase;
  }

  &__menu-link{
    color: var(--light-grey);
    font-style: normal;
    font-weight: 400;
    line-height: 160%; /* 20.8px */
    text-transform: uppercase;
  }

  &__email-link {
    display: flex;
    align-items: center;
  }

  &__email-icon{
    display: flex;
    margin-right: 10px;
    margin-bottom: 2px;
  }

  &__phone-city{
    color: var(--light-grey);
  }

  &__rclass-link{
    color: var(--primary-red);
  }

  &__contacts-block {
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-end;
  }

  &__phones-list {
    display: flex;
    flex-direction: column;
  }

  &__policy {
    color: var(--light-grey);
  }
}

@include respond-up('s-large') {
  .footer {
    &__top{
      padding: 60px 0;
    }

    &__bottom{
      padding: 30px var(--grid-spacer) 40px;
    }

    &__logo{
      svg{
        width: 119px;
        height: 28px;
      }
    }

    &__logo-block{
      grid-column: 1 / 3;
    }

    &__menu{
      &_catalog{
        grid-column: 3 / 5;
      }

      &_about{
        grid-column: 5 / 7;
      }

      &_client{
        grid-column: 7 / 9;
      }
    }

    &__contacts-block{
      grid-column: 10 / 13;
      position: relative;
      row-gap: 20px;
      &:before{
        content: '';
        position: absolute;
        width: 1px;
        height: 246px;
        background: var(--grey-text);
        left: -66px;
        top: -25px;
      }
    }

    &__menu-title{
      font-size: 18px;
      margin-bottom: 18px;
    }

    &__menu-link{
      font-size: 15px;
      padding: 8px 0;
      transition: color .3s;
      &:hover{
        color: var(--primary-red);
      }
    }

    &__copy{
      grid-column: 1 / 4;
    }

    &__files{
      display: flex;
      grid-column: 5 / 9;
    }

    &__policy{
      margin-right: 50px;
    }

    &__rclass{
      grid-column: 9 / 13;
      justify-self: end;
    }

    &__phones-list {
      row-gap: 14px;
    }
  }
}

@include respond-up('medium') {
  .footer {

  }
}

@include respond('medium') {
  .footer {
    &__top{
      padding: 40px 0 50px 0;
    }

    &__top-wrap{
      row-gap: 50px !important;
    }

    &__logo{
      svg{
        width: 99px;
        height: 23px;
      }
    }

    &__menu-title{
      font-size: 17px;
      margin-bottom: 16px;
    }

    &__menu-link{
      font-size: 14px;
    }

    &__logo-block{
      grid-column: 1 / 3;
      grid-row: 1 / 3;
    }

    &__menu{
      &_catalog, &_client{
        grid-column: 3 / 5;
      }

      &_about{
        grid-column: 5 / 7;
      }
    }

    &__contacts-block{
      grid-column: 5 / 7;
    }

    &__email-link{
      margin-bottom: 16px;
    }

    &__copy{
      grid-column: 1 / 3;
    }

    &__files{
      display: flex;
      flex-direction: column;
      grid-column: 3 / 5;
    }

    &__policy{
      margin-bottom: 15px;
    }

    &__rclass{
      grid-column: 5 / 7;
      justify-self: end;
      white-space: nowrap;
    }

    &__phones-list {
      row-gap: 10px;
    }
  }
}

@include respond-down('medium') {
  .footer {
    &__bottom{
      padding: 20px var(--grid-spacer) 40px;
    }

    &__menu-link{
      padding: 7px 0;
    }
  }
}

@include respond-down('small') {
  .footer {
    &__top{
      padding: 40px 0 60px;
    }

    &__top-wrap{
      row-gap: 50px !important;
    }

    &__logo{
      svg{
        width: 114px;
        height: 26px;
      }
    }

    &__menu-title{
      font-size: 16px;
      margin-bottom: 9px;
    }

    &__menu-link{
      font-size: 13px;
    }

    &__logo-block{
      grid-column: 1 / 5;
      grid-row: 1 / 2;
      flex-direction: row;
    }

    &__menu{
      &_catalog, &_client{
        grid-column: 1 / 3;
      }

      &_about{
        grid-column: 3 / 5;
      }
    }

    &__contacts-block{
      grid-column: 3 / 5;
    }

    &__bottom-wrap{
      row-gap: 14px !important;
    }

    &__copy, &__policy, &__rclass{
      grid-column: 1 / 5;
    }

    &__policy{
      margin-bottom: 10px;
    }

    &__files{
      display: flex;
      flex-direction: column;
      grid-column: 1 / 5;
    }

    &__phones-list {
      row-gap: 8px;
      margin-top: 12px;
    }

    &__socials {
      flex: 0 0 var(--grid-column2);
    }
  }
}

@media (max-width: 366px) {
  .footer{
    &__contacts-block{
      grid-column: 1 / 5;
      grid-row: 4 / 5;
    }

    //&__menu{
    //  &_client{
    //    grid-row: 2 / 3;
    //  }
    //}
  }
}