@include respond-up('s-large') {
  .partner-products-slider {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-row-gap: 40px;

    &__title {
      grid-column: 1;
      grid-row: 1;
    }

    &__button-wrap {
      grid-column: 2;
      grid-row: 1;
      display: flex;
      justify-content: flex-end;
    }

    &__slider-wrap {
      grid-column: 1/3;
      grid-row: 2;
    }

    &__arrows {
      .slider-arrow {
        margin: 0;
        position: absolute;
        top: 50%;
        z-index: 20;
        box-shadow: 0 2px 20px 0 #2C2C2F1A;

        &_left {
          left: 0;
          transform: translate3d(-50%, -50%, 0);
        }

        &_right {
          right: 0;
          transform: translate3d(50%, -50%, 0);
        }
      }
    }
  }
}

@include respond-up('medium') {
  .partner-products-slider {
    padding: 0 var(--grid-spacer-and-indent);

    &__slider-wrap {
      position: relative;
    }
  }
}

@include respond('medium') {
  .partner-products-slider {
    &__slider-wrap {
      margin-top: 30px;
    }

    &__button-wrap {
      margin-top: 32px;
    }

    &__arrows {
      position: absolute;
      right: 0;
      top: calc(100% + 30px);
    }
  }
}

@include respond-down('small') {
  .partner-products-slider {
    &__title {
      font-size: 20px;
      line-height: 150%;
    }

    &__title,
    &__button-wrap {
      padding: 0 var(--grid-spacer-and-indent);
    }

    &__slider-wrap {
      margin-top: 20px;
    }

    &__arrows {
      display: none;
    }

    &__button-wrap {
      margin-top: 30px;
    }
  }
}