.partners-page {
  &__dark-wrap {
    background: var(--primary-black);
  }

  &__grey-wrap {
    background: rgba(200, 203, 209, 0.5);
  }

  &__white-wrap {
    background: #fff;
  }

  &__form {
    &.recall-form {
      margin-bottom: 0;
    }
  }
}

@include respond-up('s-large') {
  .partners-page {
    &__hero {
      padding-top: 40px;
    }

    &__dark-wrap {
      padding-bottom: 100px;
    }

    &__contacts-wrap {
      padding: 75px 0 120px;
    }

    &__white-wrap,
    &__advantages-wrap {
      padding: 80px 0 100px;
    }

    &__partnership-wrap {
      padding: 80px 0 120px;
    }

    &__price-modal {
      display: flex;
      justify-content: flex-end;
      margin-top: 60px;
    }
  }
}

@include respond-up('medium') {
  .partners-page {
    &__price-modal-button {
      &.button {
        padding: 13px 50px;
      }
    }
  }
}

@include respond('medium') {
  .partners-page {
    &__hero {
      padding-top: 30px;
    }

    &__dark-wrap {
      padding-bottom: 80px;
    }

    &__contacts-wrap {
      padding: 40px 0 100px;
    }

    &__white-wrap {
      padding: 40px 0 80px;
    }

    &__partnership-wrap,
    &__advantages-wrap {
      padding: 60px 0 80px;
    }
  }
}

@include respond-down('medium') {
  .partners-page {
    &__price-modal {
      margin-top: 40px;
    }
  }
}

@include respond-down('small') {
  .partners-page {
    &__hero {
      padding-top: 20px;
    }

    &__dark-wrap {
      padding-bottom: 60px;
    }

    &__contacts-wrap {
      padding: 30px 0 80px;
    }

    &__white-wrap,
    &__partnership-wrap,
    &__advantages-wrap {
      padding: 40px 0 60px;
    }

    &__price-modal-button {
      &.button {
        width: var(--grid-column3);
      }
    }
  }
}