.news-content {

}

@include respond-up('s-large') {
  .news-content {
    position: relative;
    display: grid;
    grid-template-columns: calc(var(--grid-column3) - 40px) calc(var(--grid-column8) - 40px);
    grid-column-gap: calc(var(--grid-column) + var(--grid-gap) * 2);
    margin-top: 60px;

    &__another-news {
      grid-column: 1/2;
    }

    &__content {
      grid-column: 2/3;
    }
  }
}

@include respond-up('medium') {
  .news-content {

  }
}

@include respond('medium') {
  .news-content {

  }
}

@include respond-down('medium') {
  .news-content {
    &__another-news {
      display: none;
    }
  }
}

@include respond-down('small') {
  .news-content {

  }
}