.producer-models{
  &__list{
    display: grid;
    grid-gap: 14px;
  }
}

@include respond-up('s-large') {
  .producer-models{
    &__list {
      padding: 40px 0 0;
      grid-template-columns: repeat(auto-fill, minmax(247px, 1fr));
    }
  }
}

@include respond('medium') {
  .producer-models{
    &__list {
      padding: 30px 0 0;
      grid-template-columns: repeat(auto-fit, minmax(224px, 1fr));
    }
  }
}

@include respond-down('small') {
  .producer-models{
    &__list {
      padding: 20px 0 0;
      grid-template-columns: repeat(auto-fit, minmax(160px, 1fr));
    }
  }
}
