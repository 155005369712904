.additional-slider {
  border-top: 1px solid var(--grey-text);

  &__title {
    color: #fff;
  }

  &__slider-wrap {
    background: #EBECEE;
    border-radius: 6px;
    overflow: hidden;

    .advantages-slider {
      &__images-wrapper {
        border-radius: 6px;
        overflow: hidden;
      }
    }
  }
}

@include respond-up('s-large') {
  .additional-slider {
    padding-top: 60px;
    margin-top: 82px;

    &__slider-wrap {
      margin-top: 40px;
    }
  }
}

@include respond('medium') {
  .additional-slider {
    &__slider-wrap {
      margin-top: 30px;
    }
  }
}

@include respond-down('medium') {
  .additional-slider {
    padding-top: 40px;
    margin-top: 60px;
  }
}

@include respond-down('small') {
  .additional-slider {
    &__slider-wrap {
      margin-top: 20px;
    }
  }
}