.header {
  width: 100%;
  z-index: 4700;
  -webkit-box-shadow: 0 6px 10px -7px rgba(34, 60, 80, 0.2);
  -moz-box-shadow: 0 6px 10px -7px rgba(34, 60, 80, 0.2);
  box-shadow: 0 6px 10px -7px rgba(34, 60, 80, 0.2);

  &._sticky{
    position: sticky;
    top: 0;
  }
  &__wrap{
    display: flex;
    flex-direction: column;
  }

  &__top{
    background: var(--primary-color);
    height: 63px;
  }

  &__bottom{
    background: #FFFFFF;
    height: 72px;
  }

  &__top-container{
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
  }

  &__phones-wrap{
    display: flex;
    align-items: center;
  }

  &__phone{
    display: flex;
    align-items: baseline;
    position: relative;

    &::after {
      content: '';
      position: absolute;
      right: -20px;
      border-left: 1px solid var(--border-color);
      top: 14px;
      bottom: 14px;
    }
  }

  &__phone-link{
    color: #FFFFFF;
    padding: 18px 0;
  }

  &__phone-city{
    color: var(--light-grey);
    margin-left: 10px;
  }

  &__recall{
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 145%; /* 21.75px */
    text-transform: uppercase;
    border-bottom: 1px solid var(--white-color-opacity60);
    color: #FFF;
    transition: border var(--default-timing);
    &:hover{
      border-bottom: 1px solid #FFFFFF;
    }
  }

  &__bottom-container{
    height: 100%;
    align-items: center;
  }

  &__menu-wrap{
    grid-column: 3 / 11;
    display: flex;
  }

  &__menu-link{
    position: relative;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 24px */
    text-transform: uppercase;
    color: var(--primary-color);
    transition: color var(--default-timing);
    padding: 24px 0;

    &:hover{
      color: var(--primary-red);
    }

    &_with-child{
      &:hover{
        &:after{
          border-color: var(--primary-red) transparent transparent transparent;
        }

        .header{
          &__submenu{
            opacity: 1;
            pointer-events: auto;
            z-index: 10;
          }
        }
      }
      &:after{
        content: '';
        position: absolute;
        right: -15px;
        top: calc(50% - 3px);
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 5px 4.5px 0 4.5px;
        border-color: var(--primary-color) transparent transparent transparent;
        transform: rotate(0deg);
        transition: border-top-color var(--default-timing);
      }
    }
  }

  &__submenu{
    min-width: 212px;
    position: absolute;
    top: 72px;
    left: -18px;
    background: #FFFFFF;
    border-radius: 0 0 4px 4px;
    opacity: 0;
    pointer-events: none;
    transition: opacity var(--default-timing);
    -webkit-box-shadow: 0 5px 10px -6px rgba(34, 60, 80, 0.2);
    -moz-box-shadow: 0 5px 10px -6px rgba(34, 60, 80, 0.2);
    box-shadow: 0 5px 10px -6px rgba(34, 60, 80, 0.2);
  }

  &__submenu-wrap{
    padding: 10px 22px 10px 18px;
  }

  &__submenu-item-wrap, &__submenu-link{
    display: block;
  }

  &__submenu-link{
    padding: 10px 0;
  }

  &__submenu-link{
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    text-transform: none;
    cursor: pointer;
    line-height: 150%; /* 22.5px */
    color: var(--primary-color);
    transition: color var(--default-timing);
    &:hover{
      color: var(--primary-red);
    }
  }

  &__cart-block{
    margin-left: 20px;
  }

  &__cart-wrap{
    display: flex;
    align-items: center;
  }

  &__cart-icon{
    display: flex;
    position: relative;
    svg{
      width: 24px;
      height: 24px;
      path{
        stroke: var(--grey-text);
      }
    }
  }

  &__cart-text{
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 24px */
    text-transform: uppercase;
    color: var(--primary-color);
  }

  &__cart-count{
    width: 15px;
    height: 15px;
    position: absolute;
    right: -4px;
    top: -3px;
    color: #FFF;
    background-color: var(--primary-red);
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 15px */
  }

  &__widgets{
    display: flex;
    align-items: center;
    justify-self: self-end;
    grid-column: 11 / 13;
    //max-width: 158px;
    justify-content: space-between;
  }

  &__search-block {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 24px;
    border: 1px solid var(--light-grey);
  }

  &__contacts-wrap {
    display: flex;
    align-items: center;
    column-gap: 40px;
  }

  &__search-icon {
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      width: 100%;
      height: 100%;
    }
  }
}

@include respond-up('large') {
  .header {
    &__search-block {
      width: 236px;
      margin-right: 22px;
      padding: 9px 12px;
    }

    &__menu-wrap {
      column-gap: clamp(45px, calc(60/1600 * 100vw), 70px);
      margin-left: calc(80/1600 * 100vw);
    }
  }
}

@include respond-up('s-large') {
  .header {
    &__search-block {
      margin-right: 22px;
      padding: 9px 12px;
    }

    &__bottom-container {
      display: flex;
      align-items: center;
    }

    &__icon {
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 0 0 134px;
      height: 32px;

      svg {
        width: 100%;
        height: 100%;
      }
    }

    &__menu-wrap {
      flex: auto;
    }

    &__widgets {
      margin-left: calc(40/1600 * 100vw);
    }

    &__menu-link {
      &_with-child {
        padding-right: 15px;

        &::after {
          right: 0;
        }
      }
    }

    &__search-text {
      color: var(--grey-text);
    }

    &__search-icon {
      width: 22px;
      height: 22px;

      svg {
        circle,
        path {
          stroke: var(--primary-red);
        }
      }
    }
  }
}

@include respond('s-large') {
  .header {
    &__search-block {
      width: calc(236/1600 * 100vw);
    }

    &__menu-wrap {
      column-gap: calc(30/1200 * 100vw);
      margin-left: calc(40/1200 * 100vw);
    }
  }
}

@include respond-up('medium') {
  .header {
    &__search-text {
      &_mob {
        display: none;
      }
    }
  }
}

@include respond('medium') {
  .header {
    &__search-block {
      width: 322px;
      padding: 7px 12px;
      margin-right: 20px;
    }

    &__search-icon {
      width: 20px;
      height: 20px;
    }
  }
}

@include respond-down('medium') {
  .header{
    &__wrap{
      display: none;
    }

    &._menu-opened{
      .header-bottom{
        &__hamburger-line{
          &_top{
            transform: translateY(6px) rotate(45deg);
          }

          &_middle{
            opacity: 0;
          }

          &_bottom{
            transform: translateY(-6px) rotate(-45deg);
          }
        }
      }

      .header-drop-menu{
        transform: translateY(0);
        opacity: 1;
        pointer-events: all;
        z-index: 100;
      }

      .header-bottom__phone-link {
        opacity: 0;
        pointer-events: none;
      }
    }

    &__cart{
      width: 100%;
    }
    &__cart-wrap{
      width: 100%;
      background: transparent;
    }

    &__cart-text{
      display: none;
    }

    &__cart-icon{
      svg{
        path{
          stroke: #FFFFFF;
        }
      }
    }

    &__search-text {
      color: #fff;
    }

    &__search-icon {
      svg {
        circle,
        path {
          stroke: #fff;
        }
      }
    }
  }
}

@include respond-down('small') {
  .header {
    &__search-block {
      margin-right: 16px;
      width: calc(120/375 * 100vw);
      padding: 5px 8px;
    }

    &__search-icon {
      width: 18px;
      height: 18px;
    }

    &__search-text {
      &_desk {
        display: none;
      }

      &.texts_input {
        font-size: 13px;
      }
    }

    &__cart-icon {
      svg {
        width: 20px;
        height: 20px;
      }
    }
  }
}