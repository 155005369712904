.city-popup {
  &__title {
    text-align: center;
  }

  &__buttons-wrap {
    display: flex;
    align-items: center;
  }

  &__button {
    display: flex;
    justify-content: center;
    border-radius: 43px;
    background: var(--background-color);
    color: var(--grey-text);

    &_active {
      background: var(--primary-red);
      color: #fff;
    }
  }

  &__button-text{
    pointer-events: none;
  }
}

@include respond-up('medium') {
  .city-popup {
    &__buttons-wrap {
      margin-top: 30px;
      column-gap: 14px;
    }

    &__button {
      width: 160px;
      padding: 10px;
    }
  }
}

@include respond('medium') {
  .city-popup {
    &__title {
      &.text__subtitle {
        font-size: 20px;
      }
    }
  }
}

@include respond-down('small') {
  .city-popup {
    &__buttons-wrap {
      margin-top: 20px;
      column-gap: 8px;
    }

    &__button {
      width: 138px;
      padding: 8px;
    }

    &__button-text {
      &.texts_card-heading {
        font-size: 14px;
      }
    }
  }
}