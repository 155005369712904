.product-slider {
  overflow: hidden;
  &__body{
    position: relative;
  }

  &__title-wrap{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__title{
    display: block;
    @extend h2;
  }

  &__arrow-wrap{
    position: absolute;
    right: 0;
  }

  &__arrows {
    &._hidden {
      display: none;
    }
  }
}

@include respond-up('s-large') {
  .product-slider {
    &__title{
      margin-bottom: 35px;
    }
    &__arrow-wrap{
      top: -88px;
    }
  }
}

@include respond-up('medium') {
  .product-slider {
    margin-right: calc(var(--grid-indent) + var(--grid-spacer));
    margin-left: calc(var(--grid-indent) + var(--grid-spacer));

    &__head {
      .container_spacer {
        padding: 0;
      }
    }
  }
}

@include respond('medium') {
  .product-slider {
    &__title{
      margin-bottom: 28px;
    }

    &__arrow-wrap{
      top: -71px;
    }
  }
}

@include respond-down('medium') {
  .product-slider {

  }
}

@include respond-down('small') {
  .product-slider {
    &__title{
      margin-bottom: 20px;
    }

    &__arrow-wrap{
      display: none;
    }
  }
}