/*
spacer - Задаёт фиксированные отступы по краям контейнера.

Использование в шаблоне:

<div class="container container_spacer">
  ...
</div>

По умолчанию в качестве единиц измерения для отступа используются - 'px'.
При необходимости их можно изменить переменной $spacer-unit в файлике ../_common/_container.scss
*/

$grid: (
  small: (
    from: 0,
    to: 767
  ),
  medium: (
    from: 768,
    to: 1199
  ),
  s-large: (
    from: 1200,
    to: 1599
  ),
  large: (
    from: 1600,
    to: 1920
  ),
  x-large: (
    from: 1921
  )
);

:root {
  --body-font-size: 16px;
  --body-line-height: 1.3;
  --body-font-color: var(--primary-color);

  --font: "Onest", sans-serif;
  --h-font: "Onest", sans-serif;

  --t: transparent;

  --primary-color: #2C2C2F;
  --background-color: #EBECEE; // серая подлога
  --light-background-color: #F2F2F3;
  --dark-background-color: rgba(44, 44, 47, .8); // Темная подложка для модалок
  --alert-color: #FF6969;
  --primary-red: #D7262D;
  --primary-black: #2C2C2F;
  --light-grey: #C8CBD1;
  --grey-text: #7E7E7F;
  --grey-bg-lighter: #F2F2F3; // Серый светлее
  --dark-red: #9B171C;
  --primary-red-60: rgba(215, 38, 45, .6);
  --primary-red-10: rgba(215, 38, 45, .1);
  --border-color: #C8CBD1;
  --green-color: #5E9700;

  --default-transition: all 0.4s var(--default-transition-function);
  --default-transition-function: ease-in-out;
  --primary-color-opacity-40: rgba(0, 0, 0, .4);
  --grey-background-color-opacity-50: rgba(200, 203, 209, .5);
  --white-color-opacity60: rgba(255, 255, 255, .6);
  --white-color-opacity40: rgba(255, 255, 255, .4);
  --primary-color-opacity: rgba(0, 0, 0, .85);

  --default-timing: .4s;
  --long-timing: .8s;
  --teaser-transition-func: cubic-bezier(.25, .1, .25, 1);
}
