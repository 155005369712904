.enum-filter-field {
  &__values {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }

  &__value {
    display: flex;
    align-items: center;

    &:not(:first-child) {
      margin-left: 29px;
    }
  }

  &__label {
    cursor: pointer;
    display: flex;
    align-items: center;
  }

  input {
    display: none;

    &:disabled + label {
      cursor: not-allowed;
    }

    &:checked + label {
      .enum-filter-field {
        &__label-icon {
          svg {
            opacity: 1;
          }
        }
      }
    }
  }

  &__label-icon {
    display: flex;
    background-color: #fff;
    border: 1px solid var(--border-color);
    border-radius: 4px;
    align-items: center;
    justify-content: center;
    width: 19px;
    height: 19px;

    svg {
      transition: opacity .3s;
      opacity: 0;
    }
  }

  &__label-title {
    color: var(--black-color);
    margin-left: 8px;
  }

  &__icon {
    margin-left: 4px;
  }
}