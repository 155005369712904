.index-offices-list {
  display: flex;
  flex-direction: column;

  &__item-top {
    display: flex;
    align-items: center;
  }

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 15px;
    height: 15px;

    svg {
      width: 100%;
      height: 100%;
    }
  }

  &__address {
    font-weight: 600;
    line-height: 150%;
  }

  &__opening-list {
    display: flex;
    flex-wrap: wrap;
  }

  &__phone {
    display: flex;
  }
}

@include respond-up('s-large') {
  .index-offices-list {
    max-height: 374px;

    &__item {
      cursor: pointer;
      padding: 20px 108px 30px 30px;
    }

    &__schedule {
      width: calc(var(--grid-column2) + 11px);
    }
  }
}

@include respond-up('medium') {
  .index-offices-list {
    overflow: scroll;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    &::-webkit-scrollbar {
      display: none;
    }

    section::-webkit-scrollbar {
      width: 0 !important
    }

    overflow: -moz-scrollbars-none; /* Firefox */

    &__item {
      border-radius: 6px;
      transition: background var(--default-timing) var(--default-transition-function);

      &:hover {
        background: var(--grey-bg-lighter);

        .index-offices-list {
          &__icon {
            opacity: 1;
          }

          &__link-title {
            padding-left: 23px;
          }
        }
      }
    }

    &__item-top {
      position: relative;
    }

    &__icon {
      position: absolute;
      top: 5px;
      left: 0;
      opacity: 0;
      transition: opacity var(--default-timing) var(--default-transition-function);
    }

    &__link-title {
      transition: padding var(--default-timing) var(--default-transition-function);
    }

    &__address {
      font-size: 16px;
      margin-top: 10px;
    }

    &__schedule {
      margin-top: 4px;
    }

    &__phone {
      padding: 10px;
      margin: 0 -10px -10px;
    }
  }
}

@include respond('medium') {
  .index-offices-list {
    max-height: 346px;

    &__item {
      padding: 16px 70px 20px 20px;
    }

    &__schedule {
      width: var(--grid-column2);
    }
  }
}

@include respond-down('small') {
  .index-offices-list {
    &__item {
      padding: 16px 45px 20px 20px;

      &:not(:first-child) {
        border-top: 1px solid var(--border-color);
      }
    }

    &__link-title {
      margin-left: 6px;
    }

    &__address {
      font-size: 15px;
      margin-top: 10px;
    }

    &__schedule {
      margin-top: 2px;
    }

    &__phone {
      padding: 12px;
      margin: 0 -12px -12px;
    }
  }
}