:root {
  --default-input-height: 48px;
  --errors-color: var(--alert-color, #b50000)
}

form {
  .button, button {
  //  margin: 0;
  //  text-align: center;
  //  transition: var(--default-transition);
  //  display: inline-block;
  //  border: none;
  //  background: var(--primary-color);
  //  cursor: pointer;

    &.height {
      height: var(--default-input-height);
    }
  }
}

select {
  border: 1px solid var(--grey-stroke-color);
  border-radius: 0;
  box-shadow: none;
  margin: 0;
  padding: 11px 40px 11px 15px;

  font-family: var(--font);
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 135%;
  letter-spacing: -0.02em;

  width: 100%;
  display: block;
  appearance: none;
  background: {
    color: var(--white-color-opacity40);
    //image: url('../images/svg/arrow-down.svg');
    repeat: no-repeat;
    position: calc(100% - 15px) 50%;
  };
  color: var(--black-color);
}

.form-field {
  position: relative;

  $inputs: 'input[type="text"], input[type="search"], input[type="password"], input[type="email"], input[type="date"]';
  label {
    display: block;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 135%;
    letter-spacing: -0.02em;
    color: var(--grey-text);
    margin-bottom: 8px;
  }

  ul.errors {
    position: absolute;
    z-index: 15;
    bottom: -16px;
    left: 0;
    list-style: none;
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 120%;
    letter-spacing: -0.02em;
    color: #fff;
    background-color: var(--primary-color);

    li {
      padding: 2px 3px;
    }
  }

  #{$inputs}, textarea {
    border: 1px solid var(--light-grey);
    border-radius: 6px;
    box-shadow: none;
    margin: 0;
    padding: 14px 19px;

    font-family: var(--font);
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 135%;
    letter-spacing: -0.02em;

    width: 100%;
    display: block;
    appearance: none;
    background-color: transparent;
    color: var(--primary-color);

    &:-webkit-autofill {
      -webkit-box-shadow: 0 0 0 1000px var(--white-color-opacity40) inset;
      -webkit-text-fill-color: var(--primary-black);
      transition: color .4s, text-fill-color .4s, box-shadow .4s !important;
    }
  }

  #{$inputs} {
    height: var(--default-input-height);
  }

  textarea {
    padding: 5px 10px;
  }

  &_unlabeled {
    label {
      display: none;
    }
  }

  &_dark {
    #{$inputs}, textarea {
      background-color: var(--white-color-opacity40);
      color: #fff;
      border-radius: 6px;
      border: none;

      &::placeholder {
        color: #fff;
      }

      &:-webkit-autofill {
        -webkit-box-shadow: 0 0 0 1000px var(--white-color-opacity40) inset;
        -webkit-text-fill-color: black;
      }
    }
  }

  &._has-errors {
    #{$inputs}, textarea {
      border-color: var(--primary-color);

      &:-webkit-autofill {
        border: 1px solid var(--primary-color);
      }
    }
  }

  [type="radio"] {
    display: none;

    + label {
      position: relative;
      padding: 20px 20px 20px 50px;
      cursor: pointer;
      color: var(--primary-color);
      background: transparent;
      border-radius: 6px;
      transition: background-color .3s;

      &::before, &::after {
        content: '';
        position: absolute;
        border-radius: 50px;
      }

      &::before {
        width: 19px;
        height: 19px;
        border: 1px solid var(--light-grey);
        left: 20px;
        top: 22px;
      }

      &::after {
        height: 9px;
        width: 9px;
        background-color: var(--primary-red);
        left: 26px;
        top: 28px;
        opacity: 0;
        transition: {
          property: opacity;
          duration: var(--default-transition-timing);
        };
      }
    }

    &:checked + label {
      background: var(--background-color);
      &::after {
        opacity: 1;
      }
    }
  }
}

@include respond-down('small') {
  :root {
    --default-input-height: 45px;
  }

  .form-field {
    $inputs: 'input[type="text"], input[type="search"], input[type="password"]';

    label {
      font-size: 13px;
      margin-bottom: 6px;
    }

    #{$inputs}, textarea {
      padding: 13px 12px;
      font-size: 14px;
    }
  }

  select {
    padding: 12px;
    font-size: 14px;
  }
}