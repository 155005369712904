.chosen-fieldset {
  &__fields {
    display: flex;
  }

  &__field{
    padding: unset !important;
  }

  &__clear-all {
    align-self: center;
    margin-left: 4px;
  }
}

@include respond-up('large') {
  .chosen-fieldset {

  }
}

@include respond-up('medium') {
  .chosen-fieldset {
    &__fields {
      flex-wrap: wrap;
      margin: -4px;
    }

    .chosen-field {
      margin: 4px;
    }
  }
}

@include respond('medium') {
  .chosen-fieldset {

  }
}

@include respond-down('medium') {
  .chosen-fieldset {

  }
}

@include respond-down('small') {
  .chosen-fieldset {
    display: flex;
    overflow-x: scroll;
    margin: 0 calc(var(--grid-spacer) * -1);
    padding: 0 var(--grid-spacer);

    &::-webkit-scrollbar {
      display: none;
    }

    &__fields {
      //padding: 0 var(--grid-spacer);
      //overflow-x: scroll;
    }
  }
}