.partner-about {
  &__about,
  &__advantage-title {
    color: #fff;
  }

  &__description,
  &__advantage-description {
    color: var(--border-color);
  }

  &__advantages-list {
    display: grid;
  }

  &__advantage-item {
    grid-column: span 1;
  }

  &__advantage-title {
    font-weight: 500;
    line-height: 140%;
  }

  &__advantage-description {
    margin-top: 10px;
  }

  &__picture {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
    border-radius: 6px;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: 6px;
  }
}

@include respond-up('s-large') {
  .partner-about {
    grid-template-columns: var(--grid-column3) var(--grid-column7);
    grid-column-gap: calc(var(--grid-column) + var(--grid-gap) * 2);
    margin-top: 80px;

    &__description {
      margin-top: 20px;
    }

    &__advantages-list {
      grid-template-columns: repeat(2, calc(var(--grid-column2) + var(--grid-gap)));
      grid-column-gap: calc(var(--grid-column) + var(--grid-gap));
      grid-row-gap: 62px;
    }

    &__advantage-title {
      font-size: 46px;
    }

    &__picture {
      width: 329px;
      height: 180px;
    }
  }
}

@include respond-up('medium') {
  .partner-about {
    display: grid;

    &__image-wrap {
      grid-column: 1;
    }

    &__content-wrap {
      grid-column: 2;
    }

    &__advantages-list {
      margin-top: 60px;
    }
  }
}

@include respond('medium') {
  .partner-about {
    grid-template-columns: var(--grid-column2) 1fr;
    grid-column-gap: var(--grid-gap);
    margin-top: 60px;

    &__description {
      margin-top: 18px;
    }

    &__advantages-list {
      grid-row-gap: 40px;
    }

    &__advantage-title {
      font-size: 40px;
    }

    &__picture {
      width: 183px;
      height: 100px;
    }
  }
}

@include respond-down('medium') {
  .partner-about {
    &__advantages-list {
      grid-template-columns: repeat(2, var(--grid-column2));
      grid-column-gap: var(--grid-gap);
    }
  }
}

@include respond-down('small') {
  .partner-about {
    margin-top: 71px;

    &__about {
      margin-top: 30px;
    }

    &__description {
      margin-top: 16px;
    }

    &__advantages-list {
      grid-row-gap: 40px;
      margin-top: 50px;
    }

    &__advantage-title {
      font-size: 30px;
    }

    &__picture {
      width: 89px;
      height: 49px;
    }
  }
}