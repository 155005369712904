.certificates {
  &__top,
  &__text-wrap {
    display: flex;
  }

  &__text-wrap {
    flex-direction: column;
  }

  &__list {
    display: flex;
    flex-direction: column;
  }

  &__link {
    display: flex;
    justify-content: space-between;
    background: var(--grey-bg-lighter);
    border-radius: 6px;
  }

  &__link-title {
    font-weight: 600;
    line-height: 150%;
  }

  &__picture {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 6px;
  }
}

@include respond-up('s-large') {
  .certificates {
    &__top {
      justify-content: space-between;
      align-items: center;
    }

    &__description {
      margin-top: 30px;
    }

    &__text-wrap {
      flex: 0 0 var(--grid-column6);
    }

    &__image-wrap {
      flex: 0 0 var(--grid-column5);
    }

    &__picture {
      height: calc(var(--grid-column5) * 370/567);
    }

    &__title {
      width: var(--grid-column5);
    }

    &__list {
      row-gap: 18px;
      margin-top: 60px;
    }

    &__link {
      padding: 20px 67px 20px 30px;

      &:hover {
        .certificates {
          &__link-title {
            color: var(--primary-red);
          }

          &__link-icon {
            svg path {
              stroke: var(--primary-red);
            }
          }
        }
      }
    }

    &__link-title {
      transition: color var(--default-timing) var(--default-transition-function);
    }

    &__link-icon {
      svg path {
        transition: stroke var(--default-timing) var(--default-transition-function);
      }
    }
  }
}

@include respond-up('medium') {
  .certificates {
    &__link {
      align-items: center;
    }

    &__link-title {
      font-size: 16px;
    }
  }
}

@include respond('medium') {
  .certificates {
    &__text-wrap,
    &__list {
      margin-top: 40px;
    }

    &__list {
      row-gap: 12px;
    }

    &__link {
      padding: 20px 30px;
    }

    &__picture {
      height: calc(var(--grid-column6) * 455/696);
    }
  }
}

@include respond-down('medium') {
  .certificates {
    &__top {
      flex-direction: column-reverse;
    }

    &__description {
      margin-top: 20px;
    }

    &__link-icon {
      svg path {
        stroke: var(--primary-red);
      }
    }
  }
}

@include respond-down('small') {
  .certificates {
    &__text-wrap,
    &__list {
      margin-top: 30px;
    }

    &__list {
      row-gap: 10px;
    }

    &__link {
      padding: 20px;
    }

    &__link-title {
      font-size: 15px;
    }

    &__image {
      height: calc(var(--grid-column4) * 219/335);
    }
  }
}