.article-slider {
  &__slider {
    border-radius: 6px;
  }

  &__picture {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__text-slider-wrap {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }

  &__text-slider {
    display: grid;
    grid-auto-flow: row;
    &.article-slider__text-slider-wide{
      flex: 0 0 100%;
    }
  }

  &__text-slide {
    transition: opacity var(--default-timing) var(--default-transition-function);
    opacity: 0;
    pointer-events: none;
    grid-row: 1;
    grid-column: 1;
    z-index: 5;
    margin-top: 0 !important;
    padding-left: 0 !important;

    &::before {
      content: none;
    }

    &._active {
      opacity: 1;
      pointer-events: auto;
      z-index: 10;
    }
  }

  &__item {
    padding: 0 !important;
    margin: 0 !important;
    &::before {
      content: none;
    }
  }
}

@include respond-up('s-large') {
  .article-slider {
    &__text-slider {
      flex: 0 0 var(--grid-column6);

    }

    &__picture {
      height: calc((var(--grid-column8) - 40px) * 587/882);
    }
  }
}

@include respond-up('medium') {
  .article-slider {
    &__text-slider-wrap {
      margin-top: 14px;
    }

    &__text-slider {
      padding-top: 6px;
    }
  }
}

@include respond('medium') {
  .article-slider {
    &__text-slider {
      flex: 0 0 calc(var(--grid-column4) + var(--grid-gap) + 6px);

    }

    &__picture {
      height: calc((var(--grid-column6) - 60px) * 423/636);
    }
  }
}

@include respond-down('small') {
  .article-slider {
    &__text-slider-wrap {
      margin-top: 10px;
    }

    &__text-slider {
      flex: 0 0 calc(var(--grid-column2) + var(--grid-gap) + 9px);
    }

    &__picture {
      height: calc((var(--grid-column4) - 40px) * 197/295);
    }
  }
}