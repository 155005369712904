.to-favorite {
  display: flex;

  svg path {
    transition: fill, opacity;
    transition-duration: var(--default-transition-timing);

    &:last-child {
    }
  }

  &_selected {
    svg path {
      fill: var(--primary-red);
    }
  }
}