.catalog-base-selection {
  &__inner {
    border-radius: 6px;
    background: var(--primary-black);
    display: grid;
  }

  &__list-wrap {
    grid-area: list;
    display: flex;
  }

  &__list {
    display: flex;
    width: 100%;
  }

  &__image-wrap {
    display: flex;
    grid-area: image;
  }

  &__title {
    color: #fff;
    grid-area: title;
    display: flex;
  }

  &__picture {
    display: flex;
  }

  &__image {
    object-fit: contain;
  }
}

@include respond-up('s-large') {
  .catalog-base-selection {
    padding-bottom: 28px;
    &__inner {
      overflow: hidden;
      grid-template-areas: 'list image'
                           'list image';

      &:hover{
        .catalog-base-selection{
          &__picture{
            transform: scale(1.05);
          }
        }
      }
    }

    &__list{
      padding: 50px 0 70px 60px;
      height: 400px;
    }

    &__image-wrap {

    }

    &__picture {
      max-height: 400px;
      max-width: 685px;
      transition: transform 1s;
    }

    &__image{
      width: 100%;
      height: 100%;
    }
  }
}

@include respond-up('medium') {
  .catalog-base-selection {
    &__image-wrap {
      justify-content: flex-end;
    }
  }
}

@include respond('medium') {
  .catalog-base-selection {
    padding-bottom: 18px;
    &__inner {
      grid-template-areas: 'list image'
      'list image';
      height: 380px;
      position: relative;
    }

    &__list{
      padding: 30px 0 50px 40px;
    }

    &__picture {
      height: 400px;
      width: 452px;
      position: absolute;
      bottom: 0;
      right: 0;
    }

    &__image{
      position: absolute;
      bottom: 0;
      right: 0;
    }
  }
}

@include respond-down('medium') {
  .catalog-base-selection {
    &__item {
      width: 100%;
    }
  }
}

@include respond-down('small') {
  .catalog-base-selection {
    padding-bottom: 16px;
    &__list {
      //flex-wrap: wrap;
      //margin-top: 22px;
    }

    &__inner {
      padding: 16px 0 30px 20px;
      display: flex;
      position: relative;
      height: calc(325/375 * 100vw);
      max-height: 325px;
    }

    &__image-wrap {
      justify-content: center;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
    }

    &__picture {
      //height: calc(269/375 * 100vw);
      width: calc(335/375 * 100vw);
      //min-width: 240px;
      min-height: 250px;
      position: absolute;
      bottom: 0;
      right: 0;
    }

    &__image{
      position: absolute;
      bottom: 0;
      right: 0;
    }
  }
}