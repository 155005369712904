.model-page-tabs {

}

@include respond-up('s-large') {
  .model-page-tabs {
    &__groups {
      margin-top: 35px;
    }

    &__group {
      &:not(:first-child) {
        margin-top: 50px;
      }
    }

    &__type-size-tabs-wrap,
    &__group-title {
      padding: 0 50px;
    }

    &__item {
      padding: 50px 0;
    }
  }
}

@include respond-up('medium') {
  .model-page-tabs {
    &__group-items {
      margin-top: 20px;
    }
  }
}

@include respond('medium') {
  .model-page-tabs {
    &__groups {
      margin-top: 30px;
    }

    &__type-size-tabs-wrap,
    &__group-title {
      padding: 0 28px;
    }

    &__item {
      padding: 35px 0;
    }
  }
}

@include respond-down('medium') {
  .model-page-tabs {
    &__group {
      &:not(:first-child) {
        margin-top: 40px;
      }
    }
  }
}

@include respond-down('small') {
  .model-page-tabs {
    &__groups {
      margin-top: 22px;
    }

    &__group-items {
      margin-top: 10px;
    }

    &__type-size-tabs-wrap,
    &__group-title {
      padding: 0 18px;
    }

    &__item {
      padding: 15px 0 20px;
    }
  }
}