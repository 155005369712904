.part-card {
  &__heading {
    display: flex;
    align-items: center;
  }

  &__icon-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--primary-red-60);
    border-radius: 50%;
  }

  &__text {
    article {
      h1, h2, h3, h4, h5, h6 {
        font-style: normal;
        font-weight: 600;
        line-height: 150%;
        margin-top: 20px;
        text-transform: unset;
        color: var(--primary-black);
      }

      p {
        margin-top: 10px;
        strong {
          margin-top: 20px;
          display: flex;
        }
      }
    }
  }

  &__image-block {
    border-radius: 6px;
    background: var(--grey-bg-lighter);
    display: flex;
  }

  &__image-subtitle {
    position: relative;
    height: fit-content;
    color: var(--grey-text);

    &:before {
      position: absolute;
      content: '';
      width: 1px;
      left: 0;
      background: linear-gradient(180deg, rgba(126, 126, 127, 0) 0%, #7E7E7F 47.92%, rgba(126, 126, 127, 0) 100%);
    }
  }

  &__images-list {
    display: flex;
  }

  &__picture {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

@include respond-up('s-large') {
  .part-card {
    padding-top: 30px;
    padding-bottom: 50px;

    &__heading {
      padding: 0 40px;
    }

    &__content-wrap {
      padding-left: 100px;
      padding-right: 120px;
    }

    &__image-block {
      padding: 24px 40px;
      margin-top: 30px;
    }

    &__image-subtitle {
      width: calc(var(--grid-column3) - 25px);
    }

    &__big-image-wrap {
      flex: 0 0 calc(var(--grid-column2) + 3px);
      margin-right: calc(var(--grid-column) + var(--grid-gap));
    }

    &__small-image-wrap {
      flex: 0 0 calc(var(--grid-column) - 19px);

      &:not(:last-child) {
        margin-right: 30px;
      }
    }

    &__picture {
      &_big {
        height: calc((var(--grid-column2) + 3px) * 65/213);
      }

      &_small {
        height: calc((var(--grid-column) - 19px) * 40/72);
      }
    }

    &__images-list {
      margin-top: 30px;
      padding: 0 120px 0 100px;
    }
  }
}

@include respond-up('medium') {
  .part-card {
    &__icon-wrap {
      width: 40px;
      height: 40px;
      margin-right: 20px;

      svg {
        width: 24px;
        height: 24px;
      }
    }

    &__text {
      article {
        h1, h2, h3, h4, h5, h6 {
          font-size: 18px;
        }

        li {
          padding-left: 19px;
          margin-top: 14px;
        }
      }
    }

    &__image-block {
      align-items: center;
    }

    &__image-subtitle {
      padding-left: 15px;

      &:before {
        height: calc(100% + 11px);
        top: -6px;
      }
    }
  }
}

@include respond('medium') {
  .part-card {
    padding-top: 20px;
    padding-bottom: 40px;

    &__heading {
      padding: 0 30px;
    }

    &__content-wrap {
      padding-left: 90px;
      padding-right: 30px;
    }

    &__big-image-wrap {
      flex: 0 0 calc(var(--grid-column2) - 30px);
      margin-right: 30px;
    }

    &__small-image-wrap {
      flex: 0 0 calc(var(--grid-column) - 29px);

      &:not(:last-child) {
        margin-right: 30px;
      }
    }

    &__picture {
      &_big {
        height: calc((var(--grid-column2) - 30px) * 57/189);
      }

      &_small {
        height: calc((var(--grid-column) - 29px) * 40/72);
      }
    }

    &__images-list {
      margin-top: 24px;
      padding: 0 90px 0 40px;
    }

    &__image-block {
      padding: 28px 30px;
    }
  }
}

@include respond-down('medium') {
  .part-card {
    &__image-block {
      margin-top: 20px;
    }
  }
}

@include respond-down('small') {
  .part-card {
    padding-top: 16px;
    padding-bottom: 30px;

    &__heading,
    &__content-wrap {
      padding: 0 20px;
    }

    &__icon-wrap {
      width: 34px;
      height: 34px;
      margin-right: 10px;

      svg {
        width: 22px;
        height: 22px;
      }
    }

    &__text {
      article {
        h1, h2, h3, h4, h5, h6 {
          font-size: 17px;
        }

        li {
          margin-top: 12px;
        }
      }
    }

    &__image-block {
      flex-direction: column;
      padding: 16px 16px 30px;
    }

    &__big-image-wrap {
      width: calc(var(--grid-column2) - 32px);
    }

    &__small-image-wrap {
      width: calc(var(--grid-column) - 18px);

      &:not(:last-child) {
        margin-right: 16px;
      }
    }

    &__picture {
      &_big {
        height: calc((var(--grid-column2) - 32px) * 39/128);
      }

      &_small {
        height: calc((var(--grid-column) - 18px) * 30/54);
      }
    }

    &__images-list {
      margin-top: 20px;
      padding: 0 20px 0 20px;
    }

    &__image-subtitle {
      margin-top: 20px;
      padding-left: 12px;
      font-size: 13px;
      line-height: 150%;

      &:before {
        height: calc(100% + 20px);
        top: -10px;
      }
    }
  }
}