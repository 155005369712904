.product-base {
  &__main{
    background: #FFFFFF;
    border-radius: 6px;
    overflow: hidden;
  }

  &__side{
    border-radius: 6px;
    overflow: hidden;
  }

  &__top-slider,
  &__top-service,
  &__top-price,
  &__top-additional {
    background-color: #fff;
  }

  &__top-heading-title {
    color: var(--grey-text-color);
  }

  &__top-slider,
  &__top-actions {
    grid-column: span 1;
  }

  &__tabs {
    background-color: #fff;
    border-radius: 6px;
  }

  &__producer-logo{
    img{
      object-position: left;
    }
  }

  &__title{
    @extend .h1;
  }
}

@include respond-up('s-large') {
  .product-base {
    padding-bottom: 120px;
    padding-top: 40px;

    &__producer-logo{
      margin-bottom: 20px;
    }

    &__main{
      grid-column: 1 / 10;
    }

    &__tabs{
      grid-column: 1 / 10;
      margin-top: 20px;
    }

    &__top{
      display: flex;
    }

    &__top-info{
      margin: 40px 0 40px 0;
      padding: 0 calc(var(--grid-column) / 2);
    }

    &__title-wrap_desktop{
      margin-bottom: 40px;
    }

    &__title{
      font-size: calc(32 / 1600 * 100vw);
      max-font-size: 32px;
    }

    &__side{
      grid-column: 10 / 13;
      position: sticky;
      height: fit-content;
      top: 100px;
      background: #FFFFFF;
    }

    &__top-main {
      grid-template-rows: repeat(3, auto);
    }

    &__top-heading {
      grid-column: span 2;
    }

    &__top-slider {
      grid-row: 1/3;
    }

    &__top-actions {
      grid-row: 3/4;
      border-top: 1px solid var(--grey-stroke-color);
    }

    &__additional {
      grid-row: 2/4;
      margin-top: 14px;
    }

    &__technologies {
      margin-top: 70px;
    }
  }
}

@include respond-up('medium') {
  .product-base {
    &__top-main {
      grid-template-columns: repeat(2, 1fr);
      margin-top: 18px;
    }

    &__top-info{
      border-left: 1px solid var(--border-color);
    }

    &__title-wrap{
      &_mobile{
        display: none;
      }

      &_desktop{
        display: flex;
        flex-direction: column-reverse;
      }
    }
  }
}

@include respond('medium') {
  .product-base {
    padding-bottom: 90px;
    padding-top: 30px;

    &__main{
      grid-column: 1 / 7;
    }

    &__title-wrap{
      margin-bottom: 18px;
    }

    &__title{
      font-size: calc(22 / 768 * 100vw);
      max-font-size: 22px;
    }

    &__top{
      display: flex;
      //margin-top: 30px;
    }

    //&__title-wrap{
    //  display: flex;
    //  justify-content: space-between;
    //  padding: 30px 0;
    //  margin: 0 30px;
    //  border-bottom: 1px solid var(--light-grey);
    //}

    &__side{
      grid-column: 1 / 7;
      margin-top: -8px;
    }

    &__top-info{
      width: var(--grid-column3);
      margin: 30px 0;
      padding: 0 30px 0  var(--grid-gap);
    }

    &__top-actions {
      grid-column: span 2;
    }

    &__tabs {
      margin-top: 20px;
      grid-column: 1 / 7;
    }
  }
}

@include respond-down('medium') {
  .product-base {
    &__main{
      border-radius: 6px 6px 0 0;
    }

    &__producer-logo{
      margin-bottom: 10px;
    }

    &__side{
      //border-radius: 0 0 6px 6px;
      //border-top: 1px solid var(--border-color);
    }
  }
}

@include respond-down('small') {
  .product-base {
    padding-bottom: 50px;
    padding-top: 20px;

    &__main, &__side, &__tabs{
      grid-column: 1 / 5;
    }

    &__side{
      margin-top: -6px;
    }

    &__top-info{
      display: none;
    }

    &__title-wrap {
      &_mobile{
        display: flex;
        flex-direction: column-reverse;
        row-gap: 10px;
        padding: 20px 0;
        margin: 0 20px;
        border-bottom: 1px solid var(--light-grey);
      }
    }

    &__top-main {
      grid-template-columns: 1fr;
      grid-row-gap: 8px;
    }

    &__tabs {
      margin-top: 8px;
    }
  }
}