.catalog-base-info {
  background: #FFFFFF;
  border-radius: 6px;

  &__description-wrap {
  }

  &__description {
    color: var(--grey-text-color);
  }

  &__link {
    width: max-content;
  }

  &__link-icon {
    width: 10px;
    height: 13px;
    transition: transform .4s;

    svg {
      path {
        fill: var(--black-color)
      }
    }
  }

  &__inner {
    &._opened {
      .catalog-base-info {
        &__link-icon {
          transform: rotate(180deg);
        }
      }
    }
  }
}

@include respond-up('s-large') {
  .catalog-base-info {
    margin-top: 80px;
    padding: 60px 0 80px 0;

    &__title {
      grid-column: 2 / 11;
    }

    &__description-wrap {
      //height: 160px;
      grid-column: 2 / 11;
    }

    &__link-wrap {
      margin-top: 35px;
    }
  }
}

@include respond-up('medium') {
  .catalog-base-info {

  }
}

@include respond('medium') {
  .catalog-base-info {
    padding: 40px;
    margin-top: 60px;

    &__title {
      grid-column: 1 / 6;
    }

    &__description-wrap {
      grid-column: 1 / 6;
    }

    &__link-wrap {
      margin-top: 28px;
    }
  }
}

@include respond-down('medium') {
  .catalog-base-info {

  }
}

@include respond-down('small') {
  .catalog-base-info {
    padding: 20px;
    margin-top: 40px;

    &__link-wrap {
      margin-top: 22px;
    }

    &__title {
      grid-column: 1 / 4;
    }

    &__description-wrap {
      grid-column: 1 / 4;
    }
  }
}