article {
  //:first-child {
  //  margin-top: 0 !important;
  //}

  p {
    strong {
      @extend %text-main;
      color: var(--primary-black);
    }
  }

  li, p, a {
    color: var(--grey-text);
    font-style: normal;
    font-weight: 400;
    line-height: 160%;
  }

  a {
    text-decoration: none;
    color: var(--primary-red);
  }

  li {
    position: relative;

    &:before {
      position: absolute;
      content: '';
      left: 0;
      top: 11px;
      border-bottom: 2px solid var(--primary-red);
    }
  }

  em {
    font-style: italic;
  }

  blockquote > p {
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
    margin-top: 20px;
    text-transform: unset;
    color: var(--primary-black);
  }

  blockquote {
    position: relative;

    &:before {
      position: absolute;
      content: '';
      height: 100%;
      width: 1px;
      left: 0;
      top: 0;
      background: linear-gradient(180deg, rgba(215, 39, 46, 0) 0%, #D7262D 47.92%, rgba(215, 38, 45, 0) 100%);
    }

    p {
      margin: 0;
    }
  }

  br {
    content: "" !important;
    display: block !important;
  }
}

@include respond-up('s-large') {
  article {
    blockquote {
      margin-top: 40px;
    }
  }
}

@include respond-up('medium') {
  article {
    p, li, a {
      font-size: 16px;
    }

    li {
      padding-left: 20px;

      &:before {
        width: 10px;
      }

      &:not(:first-child) {
        margin-top: 20px;
      }
    }

    blockquote > p {
      font-size: 18px;
    }

    blockquote {
      padding-left: 20px;
    }

    h1, h2, h3, h4, h5, h6 {
      margin-top: 40px;
    }
  }
}

@include respond('medium') {
  article {
    blockquote {
      margin-top: 35px;
    }
  }
}

@include respond-down('small') {
  article {
    p, li, a {
      font-size: 15px;
    }

    li {
      padding-left: 14px;

      &:before {
        width: 8px;
      }

      &:not(:first-child) {
        margin-top: 16px;
      }
    }

    blockquote > p {
      font-size: 17px;
    }

    blockquote {
      padding-left: 16px;
      margin-top: 25px;
    }

    h1, h2, h3, h4, h5, h6 {
      margin-top: 30px;
    }
  }
}