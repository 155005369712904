.product-base-delivery-tab {
  &__heading {
    display: flex;
  }

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 44px;
    height: 44px;
    background-color: var(--primary-red-60);
    border-radius: 100%;

  }

  &__title {
    font-style: normal;
    letter-spacing: -0.02em;
    color: var(--black-color);
  }

  &__main-list {
    //border-top: 1px solid var(--border-color);
  }

  &__main-text {
    color: var(--grey-text);
  }

  &__main-item {
    display: flex;
    flex-direction: column;
    flex: 0 0 50%;

    &:nth-child(odd) {
      border-right: 1px solid var(--border-color);
    }
  }

  &__more {
    display: flex;
    align-items: center;
  }

  &__more-title {
    color: var(--primary-color);
    text-transform: uppercase;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    text-align: center;
  }

  &__more-icon {
    display: flex;
    margin-left: 6px;
  }

  &__delimiter{
    position: relative;
    display: inline-block;
    width: 10px;
    margin-right: 6px;
    &:before{
      content: '';
      position: absolute;
      left: 0;
      top: -6px;
      width: 10px;
      height: 2px;
      background: var(--primary-red);
    }
  }
}

@include respond-up('s-large') {
  .product-base-delivery-tab {
    &__item {
      &:nth-child(2) {
        margin-top: 60px;
      }

      &:nth-child(3) {
        padding-top: 40px;

        &::before {
          left: -40px;
          right: calc((var(--grid-column1) + var(--grid-gap)) * -1);
        }
      }
    }

    &__title {
      margin-left: 25px;
    }

    &__main-item {
      &:nth-child(odd) {
        padding: 20px 35px 0 0;
      }

      &:nth-child(even) {
        padding: 20px 0 0 35px;
      }
    }

    &__main-text {
      margin-left: 67px;
    }

    &__list-item{
      margin-bottom: 10px;
    }

    &__more {
      margin-top: 40px;
    }
  }
}

@include respond-up('medium') {
  .product-base-delivery-tab {
    &__item {
      &:nth-child(3) {
        position: relative;
        margin-top: 40px;

        &::before {
          content: '';
          position: absolute;
          top: 0;
          border-bottom: 1px solid var(--border-color);
        }
      }
    }

    &__heading {
      align-items: center;
    }

    &__title {
      //font-weight: 600;
      //font-size: 16px;
      //line-height: 125%;
    }

    &__main {
      margin-top: 20px;
    }

    &__main-list {
      margin-top: 14px;
    }

    &__main-text-strong {
      margin-top: auto;
    }

    &__more-title {
      font-size: 14px;
    }
    &__main-text{
      article{
        ul, ol{
          margin-top: 14px;
        }
      }
    }
  }
}

@include respond('medium') {
  .product-base-delivery-tab {
    &__item {
      &:nth-child(2) {
        margin-top: 50px;
      }

      &:nth-child(3) {
        padding-top: 40px;
        &::before {
          left: -28px;
          right: -28px;
        }
      }
    }

    &__title {
      margin-left: 20px;
    }

    &__main-text {
      margin-bottom: 30px;
    }

    &__main-item {
      &:nth-child(odd) {
        padding: 20px 25px 0 0;
      }

      &:nth-child(even) {
        padding: 20px 0 0 25px;
      }
    }

    &__more {
      margin-top: 32px;
    }
  }
}

@include respond-down('small') {
  .product-base-delivery-tab {
    &__item {
      padding: 15px 18px 25px;

      &:nth-child(1),
      &:nth-child(2) {
        border-bottom: 1px solid var(--border-color);
      }
    }

    &__heading {
      flex-direction: column;
    }

    &__title {
      font-weight: 500;
      font-size: 15px;
      line-height: 135%;
      margin-top: 12px;
    }

    &__main {
      margin-top: 12px;
    }

    &__main-list {
      margin-top: 22px;
    }

    &__main-text {
      margin-top: 22px;
      article{
        ul, ol{
          margin-top: 12px;
        }
      }
    }

    &__main-item {
      flex-direction: column-reverse;
      justify-content: flex-end;

      &:nth-child(odd) {
        padding: 12px 12px 0 0;
      }

      &:nth-child(even) {
        padding: 12px 0 0 12px;
      }
    }

    &__more {
      margin-top: 22px;
    }

    &__more-title {
      font-size: 13px;
    }
  }
}