.producers-slider {
  &__heading-wrap {
    display: flex;
  }

  &__tab-item {
    border: 1px solid var(--border-color);
    transition: {
      property: background, border-color;
      duration: var(--default-timing);
      timing-function: var(--default-transition-function);
    };

    &._active {
      border-color: var(--primary-black);
    }
  }

  &__button {
    display: flex;
    align-items: baseline;
    column-gap: 6px;
  }

  &__button-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 19px;
    height: 10px;

    svg {
      width: 100%;
      height: 100%;
      path {
        fill: var(--primary-red);
      }
    }
  }

  &__item {
    background: #fff;
    display: flex;
  }

  &__link {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    position: relative;

    &:before,
    &:after {
      position: absolute;
      content: '';
      top: 0;
      border-right: 1px solid var(--border-color);
      height: 100%;
      transition: border-color var(--default-timing) var(--default-transition-function);
    }

    &:before {
      left: 0;
    }

    &:after {
      right: -1px;
    }
  }

  &__title-wrap,
  &__mark-icon,
  &__picture {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__title-wrap {
    column-gap: 2px;
  }

  &__mark-icon {
    width: 18px;
    height: 18px;

    svg {
      width: 100%;
      height: 100%;

      path {
        transition: fill var(--default-timing) var(--default-transition-function);
      }
    }
  }

  &__name {
    color: var(--grey-text);
    transition: color var(--default-timing) var(--default-transition-function);
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

@include respond-up('s-large') {
  .producers-slider {
    padding: 80px 0 100px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-row-gap: 40px;

    &__heading-wrap {
      grid-column: 1/2;
      grid-row: 1;
      padding-left: var(--grid-spacer-and-indent);
      column-gap: 40px;
    }

    &__link-wrap {
      grid-column: 2/3;
      grid-row: 1;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding-right: var(--grid-spacer-and-indent);
    }

    &__body {
      grid-column: 1/3;
      grid-row: 2;
    }

    &__tab-item {
      &:hover {
        border-color: var(--grey-text);

        &.tabs__item_round {
          background: unset;

          .tabs__text {
            color: var(--grey-text);
          }
        }
      }

      &._active {
        &:hover {
          background: var(--primary-black);
          border-color: var(--primary-black);

          .tabs__text {
            color: #fff;
          }
        }
      }
    }

    &__link {
      padding: 55px 0;
      transition: box-shadow var(--default-timing) var(--default-transition-function);

      &:hover {
        box-shadow: 0 7px 40px 0 #2C2C2F1A;
        z-index: 5;

        &:before,
        &:after {
          border-color: #fff;
        }

        .producers-slider {
          &__name {
            color: var(--primary-black);
          }

          &__mark-icon {
            svg path {
              fill: var(--primary-red);
            }
          }
        }
      }
    }

    &__picture {
      width: 142px;
      height: 70px;
    }
  }
}

@include respond-up('medium') {
  .producers-slider {
    &__heading-wrap {
      align-items: center;
    }

    &__body {
      margin: 0 var(--grid-spacer-and-indent);
    }

    &__content {
      position: relative;
    }

    &__arrows {
      display: flex;
    }

    &__arrow {
      &.slider-arrow {
        z-index: 50;
        position: absolute;
        top: 50%;
        margin: 0;
        box-shadow: 0 2px 20px 0 #2C2C2F1A;

        &_left {
          left: 0;
          transform: translate3d(-50%, -50%, 0);
        }

        &_right {
          right: 0;
          transform: translate3d(50%, -50%, 0);
        }
      }
    }

    &__title-wrap {
      margin-top: 7px;
    }

    &__slider {
      &:before {
        position: absolute;
        content: '';
        height: 100%;
        top: 0;
        left: 0;
        border-left: 1px solid #fff;
        z-index: 10;
      }
    }
  }
}

@include respond('medium') {
  .producers-slider {
    padding: 40px 0;

    &__heading-wrap {
      justify-content: space-between;
    }

    &__body,
    &__link-wrap {
      margin-top: 30px;
    }

    &__link {
      padding: 27px 0 22px;
    }

    &__picture {
      width: 118px;
      height: 60px;
    }
  }
}

@include respond-down('medium') {
  .producers-slider {
    &__heading-wrap,
    &__link-wrap {
      padding: 0 var(--grid-spacer-and-indent);
    }
  }
}

@include respond-down('small') {
  .producers-slider {
    padding: 40px 0 60px;

    &__heading-wrap {
      flex-direction: column;
    }

    &__tabs-container {
      margin-top: 20px;
    }

    &__title-wrap {
      margin-top: 4px;
    }

    &__slider {
      &.swiper {
        padding: 0 var(--grid-spacer-and-indent);
      }
    }

    &__item {
      &.swiper-slide {
        width: var(--grid-column2);
      }

      &:first-child {
        .producers-slider {
          &__link {
            &:before {
              content: unset;
            }
          }
        }
      }
    }

    &__link {
      padding: 16px 0 26px;
    }

    &__picture {
      width: 94px;
      height: 44px;
    }

    &__arrows {
      display: none;
    }

    &__body {
      margin-top: 30px;
    }

    &__link-wrap {
      margin-top: 20px;
    }

    &__tab-item {
      width: 120px;
    }

    &__link-tab {
      text-align: center;
    }
  }
}