.product-page-tabs {
  &__params {
    display: grid;
  }

  &__param-name-title {
    color: var(--grey-text);
  }

  &__param-value {
    color: var(--primary-black);
  }

  &__param {
    display: flex;
    align-items: center;
  }

  &__param-name {
    display: flex;
    align-items: center;
  }

  &__param-help {
    position: relative;
    display: flex;
    margin-left: 8px;

    &::before {
      content: '';
      position: absolute;
      z-index: 50;
      bottom: calc(100% + 5px);
      left: 2px;
      background-color: #fff;
      width: 10px;
      height: 10px;
      transform: rotate(45deg);
      opacity: 0;
      pointer-events: none;
      transition: opacity .3s;
    }

    &:hover {
      &::before,
      .product-page-tabs__param-help-title {
        opacity: 1;
        pointer-events: auto;
      }
    }
  }

  &__param-help-icon {
    display: flex;
  }

  &__param-help-title {
    position: absolute;
    z-index: 25;
    bottom: 24px;
    left: 50%;
    transform: translate3d(-50%, 0, 0);
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 120%;
    letter-spacing: -0.02em;
    color: var(--grey-text-color);
    background-color: #fff;
    filter: drop-shadow(0px 0px 20px rgba(45, 41, 40, 0.1));
    padding: 14px;
    transition: opacity .3s;
    max-width: 214px;
    width: max-content;
    opacity: 0;
    pointer-events: none;
  }
}

@include respond-up('s-large') {
  .product-page-tabs {
    &__item {
      &#tab-params {
        padding: 50px calc(var(--grid-column1) + var(--grid-gap)) 50px 50px;
      }
    }

    &__params {
      grid-template-rows: repeat(5, 1fr);
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: 70px;
      grid-auto-flow: column;
    }

    &__param-name {
      flex: 0 0 auto;
    }

    &__param-value {
      flex: 0 0 calc(var(--grid-column1) + var(--grid-gap));
      text-align: left;
    }
  }
}

@include respond-up('medium') {
  .product-page-tabs {
    &__param {
      justify-content: space-between;
      padding: 8px 0;
      border-bottom: 1px solid var(--border-color);

      &:nth-child(6n) {
        border-top: 1px solid var(--border-color);
      }

      &:first-child {
        border-top: 1px solid var(--border-color);
      }
    }
  }
}

@include respond('medium') {
  .product-page-tabs {
    &__params {
      grid-template-rows: repeat(5, 1fr);
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: var(--grid-gap);
      grid-auto-flow: column;
    }

    &__param-name,
    &__param-value {
      flex: 0 0 50%;
    }

    &__item {
      &#tab-params {
        padding: 35px 28px;
      }
    }
  }
}

@include respond-down('small') {
  .product-page-tabs {
    &__params {
      margin: -5px 0;
    }

    &__param {
      padding: 5px 0;
    }

    &__param-name,
    &__param-value {
      flex: 0 0 50%;
    }

    &__param-value {
      margin-left: 15px;
    }

    &__param-help-title {
      left: 60px;
    }
  }
}