.checkbox-list-mobile {
  border-bottom: 1px solid var(--grey-stroke-color);
  padding: 15px var(--grid-spacer) 20px;

  &_unlabeled {
    padding: 20px var(--grid-spacer);
  }

  &__label{
    //styleName: Заголовок на карточке_mob;
    font-size: 15px;
    font-weight: 600;
    line-height: 23px;
    letter-spacing: 0em;
    text-align: left;
    color: var(--primary-color);
  }

  &__heading {
    position: relative;
    display: flex;
    align-items: center;
    padding-bottom: 15px;
  }

  &__help {
    display: flex;
    padding: 6px 6px 5px;
    margin: -6px 0 -5px;

    &:hover {
      svg circle {
        fill: var(--primary-color);
      }

      .checkbox-list-mobile {
        &__help-description,
        &__help-icon::before {
          opacity: 1;
        }
      }
    }
  }

  &__help-icon {
    position: relative;
    display: flex;

    svg circle {
      transition: fill .3s;
    }

    &::before {
      content: '';
      position: absolute;
      z-index: 50;
      bottom: calc(100% + 5px);
      left: 2px;
      background-color: #fff;
      width: 10px;
      height: 10px;
      transform: rotate(45deg);
      opacity: 0;
      pointer-events: none;
      transition: opacity .3s;
    }
  }

  &__help-description {
    position: absolute;
    z-index: 25;
    bottom: 43px;
    left: 0;
    right: 0;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 120%;
    letter-spacing: -0.02em;
    color: var(--grey-text-color);
    background-color: #fff;
    filter: drop-shadow(0px 0px 20px rgba(45, 41, 40, 0.1));
    padding: 14px;
    opacity: 0;
    pointer-events: none;
    transition: opacity .3s;
  }

  &__show-all {
    white-space: nowrap;
    align-items: center;
    padding: 15px;
    margin: -15px 0 -15px auto;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 135%;
    letter-spacing: -0.02em;
    color: var(--primary-color);
    display: none;

    &._visible {
      display: flex;
    }
  }

  &__show-all-icon {
    position: relative;
    width: 12px;
    height: 12px;

    &::before,
    &::after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate3d(-50%, -50%, 0);
      border-bottom: 2px solid var(--primary-color);
      width: 100%;
    }

    &::after {
      transform: translate3d(-50%, -50%, 0) rotate(90deg);
      transition: transform .4s;
    }
  }

  &__show-all-title {
    margin-left: 8px;
    padding-top: 2px;
  }

  &__values {
    display: flex;
    flex-wrap: wrap;
    margin: -4px;
    max-height: 70px;
    overflow: hidden;
  }

  &__value {
    margin: 4px;

    input {
      display: none;

      &:checked + label {
        background-color: var(--black-color);
        color: #fff;
      }
    }

    label {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 135%;
      letter-spacing: -0.02em;
      color: var(--black-color);
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid var(--grey-stroke-color);
      padding: 3px 10px;
      background-color: #fff;
    }
  }

  &__modal {
    position: absolute;
    z-index: 100;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--grey-light-color);
    display: none;
    flex-direction: column;

    &._visible {
      display: flex;
    }
  }

  &__modal-heading {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    padding: 18px var(--grid-spacer) 17px;
    background-color: #fff;
    border-bottom: 1px solid var(--grey-stroke-color);
    flex: 0 0 auto;
  }

  &__modal-values {
    flex: 0 1 auto;
    overflow-y: scroll;
    padding: 17px 0 94px;

    &::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 2px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 50px;
      background-color: var(--grey-stroke-color);
      box-shadow: 0 0 1px rgba(255, 255, 255, .5);
    }
  }

  &__close {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px;
    position: absolute;
    top: 50%;
    transform: translate3d(-15px, -50%, 0);
    left: var(--grid-spacer);
  }

  &__modal-heading-title {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 125%;
    letter-spacing: -0.02em;
    color: var(--black-color);
  }

  &__modal-value {
    input {
      display: none;

      &:checked + label {
        background-color: var(--color-background);

        .checkbox-list-mobile {
          &__choice-icon {
            border-color: var(--black-color);

            svg {
              opacity: 1;
            }
          }
        }
      }

      + label {
        cursor: pointer;
        display: flex;
        align-items: center;
        padding: 5px var(--grid-spacer);
        margin: 0;
      }
    }
  }

  &__choice-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 16px;
    height: 16px;
    border: 1px solid var(--grey-stroke-color);
    background-color: #fff;
    transition: background-color .2s, border-color .2s;
    transition-timing-function: var(--default-timing-function);

    svg {
      width: 100%;
      height: 100%;
      transition: opacity .2s var(--default-timing-function);
      opacity: 0;
    }
  }

  &__choice-name {
    //@extend .text__small;
    color: var(--primary-black);
    margin-left: 8px;
  }

  &__submit-wrap {
    position: absolute;
    bottom: 25px;
    left: 0;
    right: 0;
    padding: 0 var(--grid-spacer);
  }

  &__submit {
    width: 100%;
  }

  &__search {
    position: relative;
    margin-bottom: 15px;

    &_modal {
      padding: 0 var(--grid-spacer);
      margin: 22px 0 0;
    }
  }

  &__search-input {
    //@extend .text__small;
    background-color: #fff;
    border: 1px solid var(--grey-stroke-color);
    padding: 6px 33px 6px 10px;
    width: 100%;
  }

  &__search-icon {
    display: flex;
    position: absolute;
    pointer-events: none;
    top: 50%;
    right: 8px;
    transform: translate3d(0, -50%, 0);
  }
}