.catalog-base-categories {
  display: grid;
  grid-gap: var(--grid-gap);
}

@include respond-up('s-large') {
  .catalog-base-categories {
    grid-template-columns: repeat(2, 1fr);
  }
}

@include respond-up('medium') {
  .catalog-base-categories {

  }
}

@include respond('medium') {
  .catalog-base-categories {
  }
}

@include respond-down('medium') {
  .catalog-base-categories {

  }
}

@include respond-down('small') {
  .catalog-base-categories {
    grid-template-columns: 1fr;
  }
}